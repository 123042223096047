import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const PaysheetModule = () => import("./PaysheetModule.vue")
const PaysheetManagement = () => import("./pages/PaysheetManagement.vue")
const CreatePaysheet = () => import("./pages/CreatePaysheet.vue")
const UpdatePaysheet = () => import("./pages/UpdatePaysheet.vue")

const paysheetRoutes = [
	{
		path: "/paysheet",
		name: "PaysheetModule",
		component: PaysheetModule,
		meta: {
			label: i18n.t("PaysheetTitle.PaysheetManagement"),
		},
		redirect: "/paysheet/list",
		children: [
			{
				path: "list",
				name: "PaysheetManagement",
				component: PaysheetManagement,
				meta: {
					title: i18n.t("PaysheetTitle.PaysheetList"),
					label: i18n.t("PaysheetTitle.PaysheetList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: "create",
				name: "CreatePaysheet",
				component: CreatePaysheet,
				meta: {
					title: i18n.t("PaysheetTitle.CreatePaysheet"),
					label: i18n.t("PaysheetTitle.CreatePaysheet"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "UpdatePaysheet",
				component: UpdatePaysheet,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("PaysheetTitle.PaysheetDetail"),
					label: i18n.t("PaysheetTitle.PaysheetDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/paysheet/list",
			},
		],
	},
]

export default paysheetRoutes
