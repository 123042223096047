import {
	EXPORT_CUSTOMER_BILL,
	GET_CUSTOMER_BILL,
	GET_CUSTOMER_COMPLETED,
	HIGHLIGHT_CUSTOMER_BILL,
	SAVE_CUSTOMER_NOTE,
} from "./action-types"
import {
	GET_CUSTOMER_BILL_FAILED,
	GET_CUSTOMER_BILL_SUCCESS,
	EXPORT_CUSTOMER_BILL_SUCCESS,
	EXPORT_CUSTOMER_BILL_FAILED,
	GET_CUSTOMER_COMPLETED_SUCCESS,
	GET_CUSTOMER_COMPLETED_FAILED,
} from "./mutation-types"
import API from "../services"
import { showLoading, hideLoading } from "@/shared/utils/mixins"

export default {
	[GET_CUSTOMER_BILL]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getCustomerBills({ ...params })
			commit(GET_CUSTOMER_BILL_SUCCESS, { ...res, filters: params })
		} catch (error) {
			commit(GET_CUSTOMER_BILL_FAILED)
		}
	},
	[EXPORT_CUSTOMER_BILL]: async ({ commit }, params) => {
		try {
			const res = await API.exportCustomerBill({ ...params })
			commit(EXPORT_CUSTOMER_BILL_SUCCESS, { file: res })
		} catch (error) {
			commit(EXPORT_CUSTOMER_BILL_FAILED)
		}
	},
	[HIGHLIGHT_CUSTOMER_BILL]: async ({ dispatch, state }, params) => {
		try {
			await API.highlightCustomerBill(params)
			const payload = {
				...state.recentFilters,
			}
			dispatch(GET_CUSTOMER_BILL, payload)
		} catch (error) {}
	},
	[GET_CUSTOMER_COMPLETED]: async ({ commit }, params) => {
		try {
			const res = await API.getCustomerCompleted(params)
			commit(GET_CUSTOMER_COMPLETED_SUCCESS, res.result)
		} catch (error) {
			commit(GET_CUSTOMER_COMPLETED_FAILED)
		}
	},
	[SAVE_CUSTOMER_NOTE]: async ({ dispatch, state }, params) => {
		try {
			showLoading()
			await API.saveCustomerNote(params)
			const data = {
				...state.recentFilters,
				notLoading: true,
			}
			await dispatch(GET_CUSTOMER_BILL, data)
		} catch (error) {
		} finally {
			hideLoading()
		}
	},
}
