export default {
	customerBills: state => {
		let { customerBills } = state
		customerBills.forEach(el => {
			if (el.settings.color) {
				el.settings.checked = true
			} else {
				el.settings.checked = false
			}
		})
		return customerBills
	},
	customerBillSelectedStore: state => {
		let { customerBills } = state
		return customerBills.filter(el => el.settings.color)
	},
}
