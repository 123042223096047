import {
	CREATE_TEMP_EMAIL_SUCCESS,
	DELETE_TEMP_EMAIL_SUCCESS,
	GET_EMAIL_LAST_SUCCESS,
	GET_TEMP_EMAILS_FAILED,
	GET_TEMP_EMAILS_SUCCESS,
	GET_TEMP_EMAIL_SUCCESS,
	RESET_EMAIL_FILTERS,
	UPDATE_TEMP_EMAIL_SUCCESS,
	SET,
} from "./mutation-types"
import router from "@/router"
import { PARAMS } from "@/shared/plugins/constants"

export default {
	[GET_TEMP_EMAILS_SUCCESS]: (state, payload) => {
		if (payload.isLoadMore) {
			state.tempEmails = [...state.tempEmails, ...payload.result]
		} else {
			state.tempEmails = payload.result
		}
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},
	[GET_TEMP_EMAILS_FAILED]: state => {
		state.tempEmails = []
		state.totalItems = null
		state.recentFilters = {
			from: null,
			to: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
	},
	[RESET_EMAIL_FILTERS]: state => {
		state.tempEmails = []
		state.totalItems = null
		state.recentFilters = {
			from: null,
			to: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
		state.messageErr = null
		state.isErr = false
		state.idHighlightRow = null
	},
	[GET_EMAIL_LAST_SUCCESS]: (state, payload) => {
		state.tempLast = payload
	},
	[CREATE_TEMP_EMAIL_SUCCESS]: () => {
		router.push({ name: "EmailManagement" })
	},
	[UPDATE_TEMP_EMAIL_SUCCESS]: () => {
		router.push({ name: "EmailManagement" })
	},
	[GET_TEMP_EMAIL_SUCCESS]: (state, payload) => {
		state.tempEmailDetail = payload
	},
	[DELETE_TEMP_EMAIL_SUCCESS]: () => {},
	[SET]: (state, [variable, value]) => {
		state[variable] = value
	},
}
