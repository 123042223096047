import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	UsersAppAppTitle: {
		UsersAppManagement: "Users App Management",
		UserAppList: "List Users App",
		UserAppDetail: "User detail",
		update: "detail",
	},
	UsersAppFieldTable: {
		name: "Username",
		systemName: "System name",
		phone: "Phone number",
		email: "Email",
		revenue: "Revenue",
		createdDate: "Created date",
		actions: "Actions",
		status: "Status",
	},
	UsersAppFilter: {
		search: "Username, system name, phone, email",
		createdDate: "Created date",
		active: "Active",
		inactive: "Inactive",
		Admin: "Admin",
		Qa: "QA",
	},
	UsersAppFormFields: {
		createdDate: "Created date",
		name: "Name",
		phone: "Phone number",
		email: "Email",
		dateFormat: "dd-mm-yyyy",
		status: "Status",
		rule: "Rule",
		name: "Username",
		systemName: "System name",
		revenue: "Revenue",
	},
	fields: {
		...{
			user_name: "Name",
			user_created_date: "Created date",
			user_phone: "Phone number",
			user_email: "Email",
			user_status: "Status",
			user_role: "Role",
		},
	},
	UsersAppMessage: {
		Delete: "Are you sure want to delete this user?",
		Header: {
			Active: "Activate user?",
			Inactive: "Deactivate user?",
		},
		Body: {
			Active: "Are you sure want to activate this user?",
			Inactive: "Are you sure want to deactivate this user?",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			requiredExport: "Required information",
		},
	},
})
