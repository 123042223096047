import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"
import { PARAMS } from "@/shared/plugins/constants"

const initialState = {
	users: [],
	userDetail: null,
	totalItems: null,
	totalPages: null,
	recentFilters: {
		form: null,
		to: null,
		search: null,
		offset: PARAMS.OFFSET,
		limit: PARAMS.LIMIT,
		status: null,
	},
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
