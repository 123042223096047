import "core-js/stable"
import Vue from "vue"
import App from "./App"
import CoreuiVue from "@coreui/vue"
import store from "./store"
import { iconsSet as icons } from "@/assets/icons/coreui-icons.js"

import * as constants from "@/shared/plugins/constants"
import i18n from "@/shared/plugins/i18n"
import "./shared/plugins"
import "./shared/components"
import "./registerServiceWorker"
import "./shared/utils/mixins"

import "./shared/utils/filters"

import router from "./router"

Vue.config.performance = true
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$constants = constants

Vue.use(CoreuiVue)

new Vue({
	el: "#app",
	i18n,
	icons,
	router,
	store,
	components: {
		App,
	},
	template: "<App/>",
})
