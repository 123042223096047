import { callAPI } from "@/shared/services"

export default {
	getOrders: params => {
		return callAPI("GET", "orders", {
			params: params.data,
			notLoading: params.notLoading || false,
		}).then(res => res.data)
	},
	exportExcel: params => {
		return callAPI("GET", "orders/export/order", { params, responseType: "blob" }).then(
			res => res.data,
		)
	},
	getRelatedOrders: params => {
		return callAPI("GET", "orders/related/orders", { params: params }).then(res => res.data)
	},
	getOrder: params => {
		return callAPI("GET", `orders/detail`, {
			params: params.data,
			notLoading: params.notLoading,
		}).then(res => res.data)
	},
	createOrder: params => {
		return callAPI("POST", "orders", params, { notLoading: true, skipToast: true }).then(
			res => res.data,
		)
	},
	updateEditorNote: params => {
		return callAPI("PUT", "orders/note", params, { notLoading: true }).then(res => res.data)
	},
	deleteOrder: params => {
		return callAPI("DELETE", `orders`, { params }).then(res => res.data)
	},
	updateOrder: params => {
		return callAPI("PUT", "orders", params, { notLoading: true, skipToast: true }).then(
			res => res.data,
		)
	},
	highlightOrder: params => {
		return callAPI("PUT", "orders/settings", params).then(res => res.data)
	},
	lockUser: params => {
		return callAPI(
			"PUT",
			`orders/lock/${params.updateOrderId}`,
			{},
			{
				notLoading: true,
				skipToast: true,
			},
		).then(res => res.data)
	},
	unlockUser: params => {
		return callAPI(
			"DELETE",
			`orders/lock/${params.updateOrderId}`,
			{},
			{
				notLoading: true,
				skipToast: true,
			},
		).then(res => res.data)
	},
	translateText: params => {
		return callAPI("POST", "orders/translate", params).then(res => res.data)
	},
	checkValidLink: params => {
		return callAPI("POST", "orders-web/check-link-customer-share", params, {
			notLoading: true,
			skipToast: true,
		}).then(res => res.data)
	},
	createOrderWeb: params => {
		return callAPI("POST", "orders-web", params, { notLoading: true }).then(res => res.data)
	},
	updateOrderWeb: params => {
		return callAPI("PUT", "orders-web", params, { notLoading: true }).then(res => res.data)
	},
	cancelOrderWeb: params => {
		return callAPI("PUT", "orders-web/cancel", params, { notLoading: true }).then(res => res.data)
	},
}
