import {
	GET_TYPES_ORDER_FAILED,
	GET_TYPES_ORDER_SUCCESS,
	RESET_STATE,
	TYPE_ORDER_DETAIL_FAILED,
	TYPE_ORDER_DETAIL_SUCCESS,
	UPDATE_TYPE_ORDER_FAILED,
	UPDATE_TYPE_ORDER_SUCCESS,
	SET,
	SET_ORDER_TYPE_FILTERS,
	CREATE_ORDER_TYPE_FAILED,
	CREATE_ORDER_TYPE_SUCCESS,
	GET_TYPES_ORDER_PRICE_SUCCESS,
	GET_TYPE_ORDER_AMOUNT_SUCCESS,
	GET_TYPE_ORDER_AMOUNT_FAILED,
	GET_TYPE_OF_PAYSHEET_SUCCESS,
	GET_TYPE_OF_PAYSHEET_FAILED,
} from "./mutation-types"
import { PARAMS } from "@/shared/plugins/constants"

export default {
	[GET_TYPES_ORDER_SUCCESS]: (state, payload = {}) => {
		if (payload.isLoadMore) {
			state.typesOrder = [...state.typesOrder, ...payload.types]
		} else {
			state.typesOrder = payload.types
		}
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},
	[GET_TYPES_ORDER_PRICE_SUCCESS]: (state, payload = {}) => {
		state.typesOrder = payload.resultListOrderType
		state.totalItems = payload.totalOrderType
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.totalOrderType / state.recentFilters.limit)
	},
	[GET_TYPE_ORDER_AMOUNT_SUCCESS]: (state, payload = {}) => {
		state.typesOrderAmount = payload.types
	},
	[GET_TYPE_ORDER_AMOUNT_SUCCESS]: (state, payload = {}) => {
		state.typesOrderAmount = payload.types
	},
	[GET_TYPE_ORDER_AMOUNT_FAILED]: state => {
		state.typesOrderAmount = []
	},
	[GET_TYPES_ORDER_FAILED]: state => {
		state.typesOrder = []
		state.totalItems = null
		state.recentFilters = {
			search: null,
			from: null,
			to: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
	},
	[TYPE_ORDER_DETAIL_SUCCESS]: (state, payload = {}) => {
		state.typeOrderDetail = payload
	},
	[TYPE_ORDER_DETAIL_FAILED]: state => {
		state.typeOrderDetail = null
	},
	[UPDATE_TYPE_ORDER_SUCCESS]: state => {
		state.typeOrderDetail = null
	},
	[UPDATE_TYPE_ORDER_FAILED]: state => {
		state.typeOrderDetail = null
	},
	[SET]: (state, [variable, value]) => {
		state[variable] = value
	},
	[RESET_STATE]: state => {
		state.typesOrder = []
		state.typeOrderDetail = null
		state.recentFilters = {
			search: null,
			from: null,
			to: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
		state.totalItems = null
		state.modalDelete = false
		state.idHighlightRow = null
		state.typesOrderAmount = []
	},
	[SET_ORDER_TYPE_FILTERS]: (state, payload) => {
		state.recentFilters = {
			...state.recentFilters,
			offset: payload.offset,
		}
	},
	[CREATE_ORDER_TYPE_FAILED]: (state, payload) => {
		state.messageErr = payload
	},
	[CREATE_ORDER_TYPE_SUCCESS]: state => {
		state.messageErr = null
	},
	[GET_TYPE_OF_PAYSHEET_SUCCESS]: (state, payload = {}) => {
		state.typesOrder = payload.resultListOrderType.map(el => {
			return {
				...el,
				id: el.typeId,
			}
		})
		state.totalItems = payload.totalOrderType
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.totalOrderType / state.recentFilters.limit)
	},
	[GET_TYPE_OF_PAYSHEET_FAILED]: state => {
		state.typesOrder = []
		state.totalItems = null
		state.recentFilters = {
			search: null,
			from: null,
			to: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
	},
}
