import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"
import { PARAMS } from "@/shared/plugins/constants"

const initialState = {
	editors: [],
	editorDetail: null,
	totalItems: null,
	totalPages: null,
	recentFilters: {
		fromDate: null,
		toDate: null,
		keyword: null,
		offset: PARAMS.OFFSET,
		limit: PARAMS.LIMIT,
	},
	messageErr: null,
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
