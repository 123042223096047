import { CREATE_USER, DELETE_USER, GET_USER, GET_USERS, UPDATE_USER } from "./action-types"
import API from "../services"
import {
	CREATE_USER_FAILED,
	CREATE_USER_SUCCESS,
	GET_USERS_FAILED,
	GET_USERS_SUCCESS,
	GET_USER_FAILED,
	GET_USER_SUCCESS,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILED,
	DELETE_USER_SUCCESS,
} from "./mutation-types"

export default {
	[CREATE_USER]: async ({ commit }, params = {}) => {
		try {
			await API.createUser({ ...params })
			commit(CREATE_USER_SUCCESS)
		} catch (error) {
			commit(CREATE_USER_FAILED)
		}
	},
	[UPDATE_USER]: async ({ commit }, params = {}) => {
		try {
			await API.updateUser({ ...params })
			commit(UPDATE_USER_SUCCESS)
		} catch (error) {
			commit(UPDATE_USER_FAILED)
		}
	},
	[GET_USERS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getUsers({ ...params })
			commit(GET_USERS_SUCCESS, { ...res, filters: params })
		} catch (error) {
			commit(GET_USERS_FAILED)
		}
	},
	[GET_USER]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getUser({ ...params })
			commit(GET_USER_SUCCESS, res)
		} catch (error) {
			commit(GET_USER_FAILED)
		}
	},
	[DELETE_USER]: async ({ commit }, params = {}) => {
		try {
			await API.deleteUser({ ...params })
			commit(DELETE_USER_SUCCESS)
		} catch (error) {}
	},
}
