import { callAPI } from "@/shared/services"

export default {
	login: params => {
		return callAPI("POST", "auth/login", params, { notLoading: true }).then(res => res.data)
	},

	sendOTP: params => {
		return callAPI("POST", "auth/otp", params).then(res => res.data)
	},
	verifyOTP: params => {
		return callAPI("POST", "auth/verify-opt", params).then(res => res.data)
	},

	refreshToken: params => {
		return callAPI("POST", "auth/refresh", params, { notLoading: true }).then(res => res.data)
	},

	recoverPwd: params => {
		return callAPI("POST", "admin/password-recovery", params).then(res => res.data)
	},

	resetPwd: params => {
		return callAPI("POST", "auth/reset-password", params).then(res => res.data)
	},

	changePwd: params => {
		return callAPI("PUT", "users/change-password", params).then(res => res.data)
	},

	getUserProfile: params => {
		return callAPI("GET", "users/profile", params).then(res => res.data)
	},

	updateUserProfile: params => {
		return callAPI("PUT", "admin/users/me", params).then(res => res.data)
	},

	checkTokenValid: params => {
		return callAPI("POST", "admin/token", params, { notLoading: true }).then(res => res.data)
	},

	canSyncDrive: () => {
		return callAPI("GET", "auth/can-sync-drive", null, { notLoading: true, skipToast: true }).then(
			res => res.data,
		)
	},
	getUrlGoogle: () => {
		return callAPI("GET", "auth/OAuth-url", null, { notLoading: true, skipToast: true }).then(
			res => res.data,
		)
	},
	sendCode: params => {
		return callAPI(
			"GET",
			"auth/google/callback",
			{ params },
			{ notLoading: true, skipToast: true },
		).then(res => res.data)
	},
	sendTokenFirebase: params => {
		return callAPI("POST", "devices", params, { notLoading: true, skipToast: true }).then(
			res => res.data,
		)
	},
	deleteTokenFirebase: params => {
		return callAPI("DELETE", "devices", { data: params }, { notLoading: true }).then(
			res => res.data,
		)
	},
}
