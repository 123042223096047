import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"
import { PARAMS } from "@/shared/plugins/constants"

const initialState = {
	orders: [],
	totalItems: null,
	totalPages: null,
	orderDetail: null,
	relatedOrders: [],
	recentFilters: {
		from: null,
		to: null,
		types: null,
		status: null,
		search: null,
		sortBy: null,
		createdTo: null,
		createdFrom: null,
		offset: PARAMS.OFFSET,
		limit: PARAMS.LIMIT,
		originalOrderId: null,
		missActualPrice: null,
	},
	message: null,
	idHighlightRow: null,
	fileExport: null,
	roleExport: null,
	lockError: null,
	amounts: [],
	typeOrderSelected: [],
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
