import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	UsersTitle: {
		UsersManagement: "Users Management",
		UserList: "List Users",
		CreateUser: "Create User",
		NewUser: "New User",
		UpdateUser: "Update User",
		update: "detail",
	},
	UsersFieldTable: {
		name: "Name",
		phone: "Phone",
		email: "Email",
		role: "Role",
		createdDate: "Created date",
		actions: "Actions",
		status: "Status",
	},
	UsersFilter: {
		search: "Name, Phone, Email",
		createdDate: "Created date",
		active: "Active",
		inactive: "Inactive",
		Admin: "Admin",
		Qa: "QA",
	},
	UserFormFields: {
		createdDate: "Created date",
		name: "Name",
		phone: "Phone number",
		email: "Email",
		dateFormat: "dd/mm/yyyy",
		status: "Status",
		role: "Role",
	},
	fields: {
		...{
			user_name: "Name",
			user_created_date: "Created date",
			user_phone: "Phone number",
			user_email: "Email",
			user_status: "Status",
			user_role: "Role",
		},
	},
	UserButtons: {
		create: "New user",
	},
	UserMessage: {
		Delete: "Are you sure want to delete this user?",
		Header: {
			Active: "Activate user?",
			Inactive: "Deactivate user?",
		},
		Body: {
			Active: "Are you sure want to activate this user?",
			Inactive: "Are you sure want to deactivate this user?",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			isEmail: "Please enter a valid email",
			requiredExport: "Required information",
		},
	},
})
