import { GET_TRANSACTIONS } from "./action-types"
import API from "../services"
import { GET_TRANSACTIONS_FAILED, GET_TRANSACTIONS_SUCCESS } from "./mutation-types"

export default {
	[GET_TRANSACTIONS]: async ({ commit }, params) => {
		try {
			const res = await API.getTransactions(params)
			commit(GET_TRANSACTIONS_SUCCESS, { ...res, filters: params })
		} catch (error) {
			commit(GET_TRANSACTIONS_FAILED)
		}
	},
}
