import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	AuthenTitle: {
		Login: "Đăng nhập",
		OTP: "Nhập mã OTP",
		ForgotPassword: "Quên mật khẩu",
		ResetPassword: "Đổi mật khẩu",
		Profile: "Thông tin cá nhân",
	},
	Login: {
		Title: "Đăng nhập",
		Email: "Email",
		Password: "Mật khẩu",
		Submit: "Đăng nhập",
		ForgotPassword: "Quên mật khẩu?",
		Register: "Đăng ký ngay!",
		hidePwd: "Ẩn mật khẩu",
		showPwd: "Hiện mật khẩu",
	},
	OTP: {
		Title: "Nhập mã OTP",
		Field: "Mã OTP",
		Submit: "Xác nhận",
		Resend: "Nhận lại mã OTP",
	},
	ForgotPwd: {
		Title: "Quên mật khẩu",
		Description:
			"Hãy điền email của bạn dưới đây, chúng tôi sẽ gửi email hướng dẫn đến địa chỉ bạn cung cấp",
		Email: "Email",
		Send: "Gửi",
		Login: "Quay lại",
		messSuccess:
			"Đường link đặt lại mật khẩu đã được gửi đến email của bạn. Vui lòng kiểm tra email.",
	},
	ResetPwd: {
		Title_Reset: "Đặt mật khẩu",
		Title: "Đặt lại mật khẩu",
		Password: "Mật khẩu mới",
		ConfirmPassword: "Nhập lại mật khẩu",
		Submit: "Lưu",
		Cancel: "Hủy bỏ",
		hidePwd: "Ẩn mật khẩu",
		showPwd: "Hiện mật khẩu",
		ok: "OK",
		msgErr: "Link đã được sử dụng, không hợp lệ.",
		resetSuccess: "Đặt mật khẩu thành công. Vui lòng đăng nhập vào Citeco website để trải nghiệm.",
		resetAgainSuccess: "Mật khẩu đã được thay đổi thành công.",
	},
	Logout: {
		Title: "Đăng xuất",
		Message: "Bạn có muốn đăng xuất không ?",
		Submit: "Đăng xuất",
		Cancel: "Hủy bỏ",
	},
	ChangePwd: {
		Title: "Thay đổi mật khẩu",
		CurrentPassword: "Mật khẩu hiện tại",
		NewPassword: "Mật khẩu mới",
		ConfirmPassword: "Nhập lại mật khẩu mới",
		Submit: "Lưu",
		Cancel: "Huỷ bỏ",
		hidePwd: "Ẩn mật khẩu",
		showPwd: "Hiện mật khẩu",
		Success: "Thay đổi mật khẩu thành công",
	},
	Profile: {
		Title: "Thông tin cá nhân",
		Email: "Email",
		Name: "Họ và tên",
		PhoneNumber: "Số điện thoại",
		Role: "Vai trò",
		Edit: "Cập nhật",
		UpdateTitle: "Cập nhật thông tin",
		Submit: "Lưu",
		Cancel: "Huỷ bỏ",
	},
	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			auth_username: "Email",
			auth_email: "Email",
			auth_password: "Mật khẩu",
			auth_old_password: "Mật khẩu hiện tại",
			auth_new_password: "Mật khẩu mới",
			auth_confirm_password: "Nhập lại mật khẩu",
			auth_otp: "Mã OTP",
			auth_full_name: "Họ và tên",
			auth_phone: "Số điện thoại",
		},
	},
	validation: {
		...vi.messages,
		...{
			// custom message rules
			confirmedBy: "{_field_} không chính xác. Vui lòng nhập lại.",
			phone: "{_field_} không hợp lệ",
		},
	},
})
