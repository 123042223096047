import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const EditorPayrollModule = () => import("./EditorPayrollModule.vue")
const EditorPayrollManagement = () => import("./pages/EditorPayrollManagement.vue")

const typeOrderRoutes = [
	{
		path: "/editor-payroll",
		name: "EditorPayrollModule",
		component: EditorPayrollModule,
		meta: {
			label: i18n.t("EditorPayrollTitle.EditorPayrollManagement"),
		},
		redirect: "/editor-payroll/list",
		children: [
			{
				path: "list",
				name: "EditorPayrollManagement",
				component: EditorPayrollManagement,
				meta: {
					title: i18n.t("EditorPayrollTitle.EditorPayrollList"),
					label: i18n.t("EditorPayrollTitle.EditorPayrollList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/editor-payroll/list",
			},
		],
	},
]

export default typeOrderRoutes
