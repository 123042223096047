import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const EditorModule = () => import("./EditorModule.vue")
const EditorManagement = () => import("./pages/EditorManagement.vue")
const CreateEditor = () => import("./pages/CreateEditor.vue")
const UpdateEditor = () => import("./pages/UpdateEditor.vue")

const editorRoutes = [
	{
		path: "/editors",
		name: "EditorModule",
		component: EditorModule,
		meta: {
			label: i18n.t("EditorTitle.EditorManagement"),
		},
		redirect: "/editors/list",
		children: [
			{
				path: "list",
				name: "EditorManagement",
				component: EditorManagement,
				meta: {
					title: i18n.t("EditorTitle.EditorList"),
					label: i18n.t("EditorTitle.EditorList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: "create",
				name: "CreateEditor",
				component: CreateEditor,
				meta: {
					title: i18n.t("EditorTitle.CreateEditor"),
					label: i18n.t("EditorTitle.CreateEditor"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "UpdateEditor",
				component: UpdateEditor,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("EditorTitle.EditorDetail"),
					label: i18n.t("EditorTitle.EditorDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/editors/list",
			},
		],
	},
]

export default editorRoutes
