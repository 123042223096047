import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	EditorTitle: {
		EditorManagement: "Editors Management",
		EditorList: "List Editor",
		CreateEditor: "Create Editor",
		NewEditor: "New Editor",
		EditorDetail: "Editor Detail",
	},
	EditorFieldTable: {
		Name: "Name",
		Nickname: "Nickname",
		CreatedDate: "Created date",
		Actions: "Actions",
		Payroll: "Payroll",
	},
	EditorFormFields: {
		Name: "Name",
		Nickname: "Nickname",
		Paysheet: "Paysheet",
	},
	EditorFilter: {
		search: "Name, Nickname",
		createdDate: "Created date",
	},
	EditorButtons: {
		create: "New editor",
		delete: "Confirm",
	},
	fields: {
		...{
			editor_name: "Name",
			editor_nickname: "Nickname",
			editor_paysheet: "Paysheet",
		},
	},
	EditorMessages: {
		delete: {
			header: " Delete editor",
			body: "Are you sure want to delete this editor?",
		},
		duplicateNickname: "This nickname has been used before, please select another nickname",
	},

	validation: {
		...en.messages,
		...{
			// custom message rules
			requiredExport: "Required information",
		},
	},
})
