<template>
	<div :ref="`file-result-${index}`" class="file__item">
		<div
			class="thumbnail"
			:class="{ animated: isAnimatedDelay, 'border-blue': checkbox }"
			@click="handleViewImage"
		>
			<template v-if="isImage(file)">
				<b-img-lazy :src="file.resizedSmallUrl" alt="" blank-color="#f3f3f3" blank />
				<input
					v-if="showCheckbox"
					id="checkbox"
					v-model="checkbox"
					type="checkbox"
					name=""
					class="choose-thumbnail"
					@click.stop="handleClickCheckbox"
					@change="handleChooseThumbnail(file)"
				/>
			</template>
			<div v-else class="folder">
				<SvgIcon icon="folder" class="icon--folder" />
			</div>
		</div>
		<div class="content">
			<div
				v-c-tooltip="{
					content: file.name,
					placement: 'bottom-start',
				}"
				class="file__name"
				>{{ file.name }}</div
			>
			<div class="actions">
				<SvgIcon v-if="showDownload" icon="save" class="icon--save" @click="downloadFile(file)" />
				<SvgIcon v-if="showDelete" icon="delete" class="icon--delete" @click="deleteFile(index)" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "FileItem",
	props: {
		file: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
			default: null,
		},
		showDelete: {
			type: Boolean,
			default: false,
		},
		showDownload: {
			type: Boolean,
			default: false,
		},
		viewImage: {
			type: Boolean,
			default: false,
		},
		showCheckbox: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			imageType: ["image/jpeg", "image/png", "image/jpg"],
			isAnimatedDelay: false,
			checkbox: false,
		}
	},
	mounted() {
		setTimeout(() => {
			this.isAnimatedDelay = true
		}, 150)
	},
	methods: {
		isImage(file) {
			if (this.imageType.includes(file.mimeType)) {
				return true
			}
			return false
		},
		deleteFile(index) {
			this.$emit("handleDelete", index)
		},
		async downloadFile(file) {
			const blob = await fetch(file.url).then(r => r.blob())
			const link = document.createElement("a")
			link.href = URL.createObjectURL(blob)
			link.download = file.name
			link.click()
			URL.revokeObjectURL(link.href)
		},
		handleViewImage() {
			this.$emit("handleviewImage", this.file)
		},
		handleClickCheckbox() {},
		handleChooseThumbnail(item) {
			if (this.checkbox) {
				this.$emit("choose-thumbnail", item)
			} else {
				this.$emit("choose-thumbnail", null)
			}
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.file__item {
	width: 117px;
	height: 140px;
	margin-bottom: 10px;
	margin-right: 23px;

	.thumbnail {
		width: 100%;
		height: 123px;
		opacity: 0;
		transition: all 0.7s ease-out;
		position: relative;

		&.animated {
			opacity: 1;
		}

		> img {
			width: 100%;
			height: 100%;
			border-radius: 8px;
			object-fit: cover;
			cursor: pointer;
			border: 1px solid $border-input;
		}

		.folder {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $grey-5;
			border-radius: 10px;
			border: 1px solid $border-input;

			svg {
				width: 45px;
				height: 40px;
				fill: $grey-2;
				cursor: pointer;
			}
		}
	}

	.content {
		display: flex;
		justify-content: space-around;
		align-items: center;

		.file__name {
			font-size: 14px;
			line-height: 20px;
			color: $black-1;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.actions {
			display: flex;

			svg {
				width: 17px;
				height: 15px;
				cursor: pointer;
				fill: $grey-2;
			}
		}
	}
}
.custom__modal {
	.modal-footer {
		display: none;
	}
}
.border-blue {
	border: 1px solid $base-color;
	border-radius: 8px;
}
</style>

<style lang="scss">
.choose-thumbnail {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 18px;
	height: 18px;
}
</style>
