export const GET_FORMS = "GET_FORMS"
export const GET_FORM_DETAIL = "GET_FORM_DETAIL"
export const UPDATE_FORM = "UPDATE_FORM"
export const CANCEL_FORM = "CANCEL_FORM"
export const LOCK_FORM = "LOCK_FORM"
export const UNLOCK_FORM = "UNLOCK_FORM"
export const GET_REQUEST_LIST = "GET_REQUEST_LIST"
export const CANCEL_FORM_REQUEST = "CANCEL_FORM_REQUEST"
export const CREATE_FORM_REQUEST = "CREATE_FORM_REQUEST"
export const UPDATE_FORM_WEB = "UPDATE_FORM_WEB"
export const GET_FORM_WEB_DETAIL = "GET_FORM_WEB_DETAIL"
export const CANCEL_FORM_WEB = "CANCEL_FORM_WEB"
