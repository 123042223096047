export const CREATE_ORDER_TYPE = "CREATE_ORDER_TYPE"
export const GET_TYPES_ORDER = "GET_TYPES_ORDER"
export const DELETE_TYPE_ORDER = "DELETE_TYPE_ORDER"
export const TYPE_ORDER_DETAIL = "TYPE_ORDER_DETAIL"
export const UPDATE_TYPE_ORDER = "UPDATE_TYPE_ORDER"

export const GET_TYPES_ORDER_PRICE = "GET_TYPES_ORDER_PRICE"

export const GET_TYPE_ORDER_AMOUNT = "GET_TYPE_ORDER_AMOUNT"
export const GET_TYPE_OF_PAYSHEET = "GET_TYPE_OF_PAYSHEET"
