import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	validation: {
		...vi.messages,
		...{
			// custom message rules
		},
	},
})
