import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const UserAppModule = () => import("./UserAppModule.vue")
const UserAppManagement = () => import("./pages/UserAppManagement.vue")
const UserAppDetail = () => import("./pages/UserAppDetail.vue")

const userAppRoutes = [
	{
		path: "/user-app",
		name: "UsersAppModule",
		component: UserAppModule,
		meta: {
			label: i18n.t("UsersAppAppTitle.UsersAppManagement"),
		},
		redirect: "/user-app/list",
		children: [
			{
				path: "list",
				name: "UserAppManagement",
				component: UserAppManagement,
				meta: {
					title: i18n.t("UsersAppAppTitle.UserAppList"),
					label: i18n.t("UsersAppAppTitle.UserAppList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/detail",
				name: "UsersAppDetail",
				component: UserAppDetail,
				props: route => ({
					default: true,
					id: route.params.id,
				}),
				meta: {
					title: i18n.t("UsersAppAppTitle.UserAppDetail"),
					label: i18n.t("UsersAppAppTitle.UserAppDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/user-app/list",
			},
		],
	},
]

export default userAppRoutes
