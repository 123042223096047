import {
	faEye,
	faEyeSlash,
	faUser,
	faKey,
	faPencilAlt,
	faFileAlt,
	faTimes,
	faTrashAlt,
	faBell,
	faCheckCircle,
	faTimesCircle,
	faFileInvoice,
	faUserClock,
	faCheck,
	faSortUp,
	faSortDown,
	faArrowRight,
	faMinus,
	faCaretDown,
	faCaretUp,
	faFileDownload,
	faChevronRight,
	faChevronLeft,
	faPlus,
	faChevronDown,
	faChevronUp,
	faBookmark,
} from "@fortawesome/free-solid-svg-icons"

export default {
	faEye,
	faEyeSlash,
	faUser,
	faKey,
	faPencilAlt,
	faFileAlt,
	faTimes,
	faTrashAlt,
	faBell,
	faCheckCircle,
	faTimesCircle,
	faFileInvoice,
	faUserClock,
	faCheck,
	faSortUp,
	faSortDown,
	faArrowRight,
	faMinus,
	faCaretDown,
	faCaretUp,
	faFileDownload,
	faChevronRight,
	faChevronLeft,
	faPlus,
	faChevronDown,
	faChevronUp,
	faBookmark,
}
