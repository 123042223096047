import {
	CREATE_ORDER_FAILED,
	CREATE_ORDER_SUCCESS,
	EXPORT_ORDER_FAILED,
	EXPORT_ORDER_SUCCESS,
	GET_ORDERS_FAILED,
	GET_ORDERS_SUCCESS,
	GET_ORDER_FAILED,
	GET_ORDER_SUCCESS,
	GET_RELATED_ORDERS_SUCCESS,
	HIGH_LIGHT_ORDER_SUCCESS,
	LOCK_USER_FAILED,
	LOCK_USER_SUCCESS,
	RESET_ORDER_FILTERS,
	SET,
	SET_AMOUNTS,
	SET_ORDERS,
	SET_ORDER_FILTERS,
	UNLOCK_USER_SUCCESS,
	UPDATE_EDITOR_NOTE_SUCCESS,
	UPDATE_ORDER_SUCCESS,
	SET_ORDER_TYPES_SELECTED,
	TRANSLATE_TEXT_SUCCESS,
} from "./mutation-types"
import { PARAMS } from "@/shared/plugins/constants"
export default {
	[GET_ORDERS_SUCCESS]: function (state, payload) {
		if (payload.isLoadMore) {
			state.orders = [...state.orders, ...payload.orders]
		} else {
			state.orders = payload.orders
		}
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},
	[GET_ORDERS_FAILED]: state => {
		state.orders = null
		state.totalItems = null
		state.recentFilters = {
			from: null,
			to: null,
			types: null,
			status: null,
			search: null,
			sortBy: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
		state.message = null
	},
	[RESET_ORDER_FILTERS]: state => {
		state.orders = []
		state.relatedOrders = []
		state.totalItems = null
		state.recentFilters = {
			from: null,
			to: null,
			types: null,
			status: null,
			search: null,
			sortBy: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
			missActualPrice: null,
		}
		state.totalPages = null
		state.message = null
		state.fileExport = null
		state.lockError = null
		state.orderDetail = null
		state.amounts = []
		state.typeOrderSelected = []
	},
	[CREATE_ORDER_SUCCESS]: state => {
		state.message = null
		state.recentFilters = {
			from: null,
			to: null,
			types: null,
			status: null,
			search: null,
			sortBy: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
	},
	[CREATE_ORDER_FAILED]: (state, payload) => {
		state.message = payload
	},
	[UPDATE_EDITOR_NOTE_SUCCESS]: () => {},
	[SET_ORDER_FILTERS]: (state, payload) => {
		state.recentFilters = {
			...state.recentFilters,
			offset: payload.offset,
		}
	},
	[SET_ORDERS]: (state, payload) => {
		state.orders = payload
	},
	[GET_ORDER_SUCCESS]: (state, payload) => {
		state.orderDetail = payload
	},
	[GET_ORDER_FAILED]: state => {
		state.orderDetail = null
	},
	[SET]: (state, [variable, value]) => {
		state[variable] = value
	},
	[UPDATE_ORDER_SUCCESS]: () => {},
	[GET_RELATED_ORDERS_SUCCESS]: (state, payload) => {
		state.relatedOrders = [...state.orders, ...payload.orders]
	},
	[EXPORT_ORDER_SUCCESS]: (state, payload) => {
		state.fileExport = payload.file
		state.roleExport = payload.roleExport
	},
	[EXPORT_ORDER_FAILED]: state => {
		state.fileExport = null
		state.roleExport = null
	},
	[HIGH_LIGHT_ORDER_SUCCESS]: () => {},
	[LOCK_USER_SUCCESS]: state => {
		state.lockError = null
	},
	[LOCK_USER_FAILED]: (state, payload) => {
		state.lockError = payload
	},
	[UNLOCK_USER_SUCCESS]: state => {
		state.lockError = null
	},
	[SET_AMOUNTS]: (state, payload) => {
		state.amounts = [...payload]
	},
	[SET_ORDER_TYPES_SELECTED]: (state, payload) => {
		state.typeOrderSelected = payload
	},
	[TRANSLATE_TEXT_SUCCESS]: () => {},
}
