import { callAPI } from "@/shared/services"

const getFileName = string => {
	if (!string) return

	return string.split("; ")[1].split("filename=")[1]
}

export default {
	getCustomerBills: params => {
		return callAPI("GET", "orders/bills", { params, notLoading: params.notLoading || false }).then(
			res => res.data,
		)
	},
	exportCustomerBill: params => {
		return callAPI("GET", "orders/bills/export", { params, responseType: "blob" }).then(res => {
			const url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", `${getFileName(res.headers["content-disposition"])}`)
			document.body.appendChild(link)
			link.click()
			return res.data
		})
	},
	highlightCustomerBill: params => {
		return callAPI("PUT", "orders/bills/settings", params).then(res => res.data)
	},
	getCustomerCompleted: params => {
		return callAPI("GET", "orders/customer-with-complete-date", { params, notLoading: true }).then(
			res => res.data,
		)
	},
	saveCustomerNote: params => {
		return callAPI("PUT", "orders/bill/note", params, { notLoading: true }).then(res => res.data)
	},
}
