export const CREATE_ORDER_TYPE_SUCCESS = "CREATE_ORDER_TYPE_SUCCESS"
export const CREATE_ORDER_TYPE_FAILED = "CREATE_ORDER_TYPE_FAILED"

export const GET_TYPES_ORDER_SUCCESS = "GET_TYPES_ORDER_SUCCESS"
export const GET_TYPES_ORDER_FAILED = "GET_TYPES_ORDER_FAILED"

export const DELETE_TYPE_ORDER_SUCCESS = "DELETE_TYPE_ORDER_SUCCESS"
export const DELETE_TYPE_ORDER_FAILED = "DELETE_TYPE_ORDER_FAILED"

export const TYPE_ORDER_DETAIL_SUCCESS = "TYPE_ORDER_DETAIL_SUCCESS"
export const TYPE_ORDER_DETAIL_FAILED = "TYPE_ORDER_DETAIL_FAILED"

export const UPDATE_TYPE_ORDER_SUCCESS = "UPDATE_TYPE_ORDER_SUCCESS"
export const UPDATE_TYPE_ORDER_FAILED = "UPDATE_TYPE_ORDER_FAILED"

export const GET_TYPES_ORDER_PRICE_SUCCESS = "GET_TYPES_ORDER_PRICE_SUCCESS"
export const GET_TYPES_ORDER_PRICE_FAILED = "GET_TYPES_ORDER_PRICE_FAILED"

export const GET_TYPE_ORDER_AMOUNT_SUCCESS = "GET_TYPE_ORDER_AMOUNT_SUCCESS"
export const GET_TYPE_ORDER_AMOUNT_FAILED = "GET_TYPE_ORDER_AMOUNT_FAILED"

export const GET_TYPE_OF_PAYSHEET_SUCCESS = "GET_TYPE_OF_PAYSHEET_SUCCESS"
export const GET_TYPE_OF_PAYSHEET_FAILED = "GET_TYPE_OF_PAYSHEET_FAILED"

export const RESET_STATE = "RESET_STATE"
export const SET = "SET"
export const SET_ORDER_TYPE_FILTERS = "SET_ORDER_TYPE_FILTERS"
