export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAILED = "GET_CUSTOMERS_FAILED"

export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS"
export const GET_CUSTOMER_DETAIL_FAILED = "GET_CUSTOMER_DETAIL_FAILED"

export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS"
export const CREATE_CUSTOMER_FAILED = "CREATE_CUSTOMER_FAILED"

export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAILED = "UPDATE_CUSTOMER_FAILED"

export const SEARCH_EMAIL_SUCCESS = "SEARCH_EMAIL_SUCCESS"
export const SEARCH_EMAIL_FAILED = "SEARCH_EMAIL_FAILED"

export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"

export const RESET_CUSTOMER_FILTERS = "RESET_CUSTOMER_FILTERS"
export const SET_VARIABLE = "SET_VARIABLE"

export const GET_TYPES_SUCCESS = "GET_TYPES_SUCCESS"
export const GET_TYPES_FAILED = "GET_TYPES_FAILED"

export const GET_CUSTOMER_PRICES_SUCCESS = "GET_CUSTOMER_PRICES_SUCCESS"
export const GET_CUSTOMER_PRICES_FAILED = "GET_CUSTOMER_PRICES_FAILED"
