import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const TransactionModule = () => import("./TransactionModule.vue")
const TransactionManagement = () => import("./pages/TransactionManagement.vue")

const transactionRoutes = [
	{
		path: "/transaction",
		name: "TransactionModule",
		component: TransactionModule,
		meta: {
			label: i18n.t("TransactionTitle.TransactionManagement"),
		},
		redirect: "/transaction/list",
		children: [
			{
				path: "list",
				name: "TransactionManagement",
				component: TransactionManagement,
				meta: {
					title: i18n.t("TransactionTitle.TransactionList"),
					label: i18n.t("TransactionTitle.TransactionList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/transaction/list",
			},
		],
	},
]

export default transactionRoutes
