import { PARAMS } from "@/shared/plugins/constants"
import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	forms: null,
	formDetail: null,
	totalItems: null,
	totalPages: null,
	isUpdateSuccess: false,
	isCancelFormModal: false,
	originalId: null,
	recentFilters: {
		from: null,
		to: null,
		source: null,
		status: null,
		search: null,
		offset: PARAMS.OFFSET,
		limit: PARAMS.LIMIT,
	},

	idHighlightRow: null,
	lockError: null,
	requests: [],
	canCreate: null,
	canCancel: null,
	totalRequest: null,
	requestOpen: null,
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
