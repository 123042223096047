import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	FormsTitle: {
		FormDetail: "Form Detail",
		CreateForm: "Create Form",
		UpdateForm: "Update Form",
		FormManagement: "Forms Management",
		UpdateFormWeb: "Update Form Web",
		FormList: "List Forms",
		FormInformation: "Form Information",
		FormUpdate: "Form",
	},
	FormsFieldTable: {
		ID: "ID",
		CustomerID: "Customer ID",
		Email: "Email",
		Source: "Source",
		Order: "Order",
		DateFormat: "dd/mm/yyyy",
		Status: "Status",
		Update: "Update",
		Actions: "Actions",
		Title: "Title",
		ReceivedTime: "Received time",
	},

	FormsField: {
		receiving: "Received time",
		relatedOrder: "Related order",
		searchOrder: "Search order",
		status: "Status",
		type: "Type",
		email: "Email",
		customerName: "Customer name",
		customerID: "Customer ID",
		title: "Title",
		source: "Source",
		description: "Description",
		uploadFile: "Upload file",
		orderID: "Order ID",
		reason: "Cancel reason",
		orderStatus: "Order status",
		phone: "Phone number",
		adminLink: "Admin link (dropbox)",
		package: "Package",
		price: "Order price",
		amount: "Amount",
		time: "Time of delivery",
		extra: "Extra fast 1 day delivery",
		discount: "Discounted",
		finalPrice: "Final price",
		deliveryTime: "Delivery time (days)",
		offerPrice: "Offer price ($)",
		orderType: "Order type",
		note: "Note to customer",
		requestPrice: "Request price",
		comment: "Comment",
		originalPrice: "Original price",
	},

	FormsPlacehoder: {
		searchOrder: "Search for existed order....",
		date_format: "dd/mm/yyyy hh:mm",
	},

	FormsFilter: {
		searchByAdmin: "Title, customer ID, email, order ID",
		searchByQA: "Order ID, title, customer ID",
		receivedTime: "Received time",
	},
	FormsFilterName: {
		Unreviewed: "Unreviewed",
		Reviewed: "Reviewed",
		Cancelled: "Cancelled",
		Mail: "Email",
		Form: "Form",
		Web: "Web",
		Responded: "Responded",
		Requested: "Requested",
	},

	FormMessages: {
		save: {
			header: "Notice!",
			body: "Save changes?",
		},
		cancel: {
			header: "Cancel form",
			body: "Are you sure want to cancel this form?",
		},
		isNewJob: "Please select create new order",
		noNewJob: "Please find the right old order with same title to add an update",
		cancelRequest: {
			header: "Cancel request",
			body: "Are you sure you want to cancel the request?",
			error: "There are some updates related to this order, please reload the page.",
		},
	},

	TechnicianHistory: {
		Title: "Histories",
	},

	TechnicianManagement: {
		Active: "Active",
		Inactive: "Inactive",
		No: "No.",
		Actions: "Actions",
	},
	fields: {
		...{
			form_search_order: "search order",
			form_related_order: "related order",
			form_type: "type",
			form_customer_name: "customer name",
			form_customer_ID: "customer ID",
			form_receiving: "receiving time",
			form_description: "description",
			form_status: "status",
			form_source: "source",
			form_title: "title",
			form_email: "email",
			form_reason: "reason",
			form_order_id: "order id",
			form_upload: "upload",
			form_order_status: "order status",
			form_phone: "phone number",
			form_request_time: "delivery time",
			form_request_price: "offer price",
			form_request_order_type: "order type",
			form_request_amount: "amount",
			form_request_note: "note",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			requiredExport: "Required information",
			isValidFormRequest: "Enter at least 1 in 4",
		},
	},
})
