import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	TechnicianTitle: {
		TechnicianDetail: "Chi tiết thông tin",
		CreateTechnician: "Tạo mới KTV",
		UpdateTechnician: "Chỉnh sửa thông tin",
		TechnicianManagement: "Quản lý KTV",
		TechnicianList: "Danh sách KTV",
		TechnicianInformation: "Thông tin Kỹ thuật viên",
	},
	TechnicianForm: {
		ID: "Mã Kỹ thuật viên",
		Name: "Tên Kỹ thuật viên",
		Province: "Tỉnh/ Thành phố",
		District: "Quận/ Huyện",
		Address: "Địa chỉ",
		Gender: "Giới tính",
		Birthday: "Ngày sinh",
		Phone: "Số điện thoại",
		Email: "Email",
		Male: "Nam",
		Female: "Nữ",
		DateFormat: "dd/mm/yyyy",
		Status: "Trạng thái",
		Update: "Chỉnh sửa",
		Title: "Thông tin KTV",
		MaxTickets: "Số yêu cầu tối đa",
	},

	TechnicianHistory: {
		Title: "Lịch sử bảo hành",
	},

	TechnicianManagement: {
		Active: "Active",
		Inactive: "Inactive",
		No: "No.",
		Actions: "Actions",
	},
	fields: {
		...{
			technician_full_name: "Tên KTV",
			technician_province: "Tỉnh/ Thành phố",
			technician_district: "Quận/ Huyện",
			technician_address: "Địa chỉ",
			technician_gender: "Giới tính",
			technician_birth_day: "Ngày sinh",
			technician_phone: "Số điện thoại",
			technician_email: "Email",
			technician_status: "Trạng thái",
			technician_max_process_ticket: "Số yêu cầu tối đa",
		},
	},
	validation: {
		...vi.messages,
		...{
			// custom message rules
		},
	},
})
