import {
	CREATE_EDITOR_FAILED,
	CREATE_EDITOR_SUCCESS,
	GET_EDITORS_FAILED,
	GET_EDITORS_SUCCESS,
	GET_EDITOR_FAILED,
	GET_EDITOR_SUCCESS,
	RESET_STATE_EDITOR,
	SET_MESSAGE,
	SET_EDITORS,
	UPDATE_EDITOR_FAILED,
	UPDATE_EDITOR_SUCCESS,
	SET_STATE,
} from "./mutation-types"
import { PARAMS } from "@/shared/plugins/constants"
// import { sortByAlpahbetically } from "@/shared/utils/mixins"
import { orderBy } from "lodash-es"

const sortByAlpahbetically = arr => {
	if (!arr?.length) return []
	const newArr = orderBy(arr, [el => el.nickname.toLowerCase()], ["asc"])
	return newArr
}

export default {
	[CREATE_EDITOR_SUCCESS]: state => {
		state.messageErr = null
	},
	[CREATE_EDITOR_FAILED]: (state, payload) => {
		state.messageErr = payload
	},
	[UPDATE_EDITOR_SUCCESS]: state => {
		state.messageErr = null
	},
	[UPDATE_EDITOR_FAILED]: (state, payload) => {
		state.messageErr = payload
	},
	[GET_EDITORS_SUCCESS]: (state, payload) => {
		if (payload.isSort) {
			state.editors = sortByAlpahbetically(payload.result)
		} else {
			state.editors = payload.result
		}
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[GET_EDITORS_FAILED]: state => {
		state.editors = []
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			fromDate: null,
			toDate: null,
			keyword: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
	},
	[RESET_STATE_EDITOR]: state => {
		state.editors = []
		state.editorDetail = null
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			fromDate: null,
			toDate: null,
			keyword: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.messageErr = null
	},
	[SET_MESSAGE]: (state, payload) => {
		state.messageErr = payload
	},
	[GET_EDITOR_SUCCESS]: (state, payload) => {
		state.editorDetail = payload
	},
	[GET_EDITOR_FAILED]: state => {
		state.editorDetail = null
	},
	[SET_EDITORS]: (state, payload) => {
		state.editors = sortByAlpahbetically(payload.data)

		if (payload.removed) {
			state.totalItems = state.totalItems - payload.totalRemove
		} else {
			state.totalItems = state.totalItems + payload.totalAdd
		}
		state.totalPages = Math.ceil(state.totalItems / state.recentFilters.limit)
	},
	[SET_STATE]: (state, [variable, value]) => {
		state[variable] = value
	},
}
