import {
	CREATE_PAYSHEET,
	DELETE_PAYSHEET,
	GET_EDITORS_IN_PAYSHEET,
	GET_PAYSHEETS,
	UPDATE_PAYSHEET,
} from "./action-types"
import {
	CREATE_PAYSHEET_FAILED,
	CREATE_PAYSHEET_SUCCESS,
	GET_PAYSHEETS_SUCCESS,
	GET_PAYSHEETS_FAILED,
	GET_EDITORS_IN_PAYSHEET_SUCCESS,
	GET_EDITORS_IN_PAYSHEET_FAILED,
	UPDATE_PAYSHEET_SUCCESS,
	UPDATE_PAYSHEET_FAILED,
} from "./mutation-types"
import API from "../services"
import router from "@/router"

export default {
	[CREATE_PAYSHEET]: async ({ commit }, params = {}) => {
		try {
			await API.createPaysheet(params)
			commit(CREATE_PAYSHEET_SUCCESS)
			router.push({ name: "PaysheetManagement" })
		} catch (error) {
			commit(CREATE_PAYSHEET_FAILED, error.message)
		}
	},
	[GET_PAYSHEETS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getPaysheets({ ...params })
			if (params.isLoadMore) {
				commit(GET_PAYSHEETS_SUCCESS, {
					...res,
					filters: params.data,
					isLoadMore: params.isLoadMore,
				})
			} else {
				commit(GET_PAYSHEETS_SUCCESS, { ...res, filters: params.data })
			}
			return res
		} catch (error) {
			commit(GET_PAYSHEETS_FAILED)
		}
	},
	[DELETE_PAYSHEET]: async ({ dispatch, state }, params = {}) => {
		try {
			await API.deletePaysheet(params)
			dispatch(GET_PAYSHEETS, state.recentFilters)
		} catch (error) {}
	},
	[GET_EDITORS_IN_PAYSHEET]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getEditorsInPaysheet({ ...params })

			commit(GET_EDITORS_IN_PAYSHEET_SUCCESS, res)
		} catch (error) {
			commit(GET_EDITORS_IN_PAYSHEET_FAILED)
		}
	},
	[UPDATE_PAYSHEET]: async ({ commit }, params = {}) => {
		try {
			await API.updatePaysheet(params)
			commit(UPDATE_PAYSHEET_SUCCESS)
			router.push({ name: "PaysheetManagement" })
		} catch (error) {
			commit(UPDATE_PAYSHEET_FAILED, error.message)
		}
	},
}
