import { PARAMS } from "../../../shared/plugins/constants"
import {
	ADD_EDITOR_OF_PAYROLL_FAILED,
	ADD_EDITOR_OF_PAYROLL_SUCCESS,
	EXPORT_EXCEL_PAYROLL_SUCCESS,
	GET_EDITOR_OF_PAYROLL_DETAIL_SUCCESS,
	GET_EDITOR_PAYROLL_FAILED,
	GET_EDITOR_PAYROLL_SUCCESS,
	RESET_STATE_EDITOR_PAYROLL,
	UPDATE_EDITOR_OF_PAYROLL_SUCCESS,
} from "./mutation-types"

export default {
	[GET_EDITOR_PAYROLL_SUCCESS]: (state, payload) => {
		state.editorPayroll = payload.result
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
		state.totalEditorWage = payload.totalEditorWage
	},
	[GET_EDITOR_PAYROLL_FAILED]: state => {
		state.editorPayroll = []
		state.totalPages = null
		state.totalItems = null
		state.totalEditorWage = null
		state.recentFilters = {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			search: null,
			customerIds: null,
			editorIds: null,
			from: null,
			to: null,
			missingAmount: null,
		}
	},
	[RESET_STATE_EDITOR_PAYROLL]: state => {
		state.editorPayroll = []
		state.totalPages = null
		state.totalItems = null
		state.totalEditorWage = null
		state.recentFilters = {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			search: null,
			customerIds: null,
			editorIds: null,
			from: null,
			to: null,
			missingAmount: null,
		}
	},
	[ADD_EDITOR_OF_PAYROLL_SUCCESS]: () => {},
	[ADD_EDITOR_OF_PAYROLL_FAILED]: () => {},
	[GET_EDITOR_OF_PAYROLL_DETAIL_SUCCESS]: () => {},
	[UPDATE_EDITOR_OF_PAYROLL_SUCCESS]: () => {},
	[EXPORT_EXCEL_PAYROLL_SUCCESS]: () => {},
}
