import { callAPI } from "@/shared/services"
export default {
	createPaysheet: params => {
		return callAPI("POST", "pay-sheet", params, { skipToast: true }).then(res => res.data)
	},
	getPaysheets: params => {
		return callAPI("GET", "pay-sheet", { params: params.data, notLoading: params.notLoading }).then(
			res => res.data,
		)
	},
	getEditorsInPaysheet: params => {
		return callAPI("GET", "pay-sheet/editors", { params }).then(res => res.data)
	},
	deletePaysheet: params => {
		return callAPI("DELETE", `pay-sheet/${params.id}`).then(res => res.data)
	},
	updatePaysheet: params => {
		return callAPI("PUT", "pay-sheet", params, { skipToast: true }).then(res => res.data)
	},
}
