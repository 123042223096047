import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	CustomersTitle: {
		CustomerManagement: "Customers Management",
		CustomerList: "List Customer",
		CreateCustomer: "New Customer",
		UpdateCustomer: "detail",
	},
	CustomerFieldTable: {
		ID: "ID",
		Customer: "Customer",
		Email: "Email",
		DateFormat: "dd/mm/yyyy",
		Actions: "Actions",
		Created: "Created date",
		Rule: "Rule",
		Type: "Type",
		Price: "Price ($)",
	},
	CustomerFilters: {
		search: "Customer, email, ID",
		created: "Created date",
	},
	CustomerButtons: {
		create: "New customer",
		add: "Add",
	},
	CustomerFormFields: {
		ID: "ID",
		Customer_name: "Customer name",
		Rule: "Rule",
		Email: "Email",
		PriceTable: "Price table",
	},
	fields: {
		...{
			customer_name: "Customer name",
			customer_rule: "Rule",
			customer_ID: "ID",
			customer_email: "Email",
			customer_price: "Price",
		},
	},
	CustomerMessage: {
		Email: "You need to add at least one email in email list",
		Email_already: "Email is already exists",
		Delete: "Are you sure want to delete this customer?",
		Required: "Required information",
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
		},
	},
})
