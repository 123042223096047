export const CREATE_EDITOR_SUCCESS = "CREATE_EDITOR_SUCCESS"
export const CREATE_EDITOR_FAILED = "CREATE_EDITOR_FAILED"

export const UPDATE_EDITOR_SUCCESS = "UPDATE_EDITOR_SUCCESS"
export const UPDATE_EDITOR_FAILED = "UPDATE_EDITOR_FAILED"

export const GET_EDITORS_SUCCESS = "GET_EDITORS_SUCCESS"
export const GET_EDITORS_FAILED = "GET_EDITORS_FAILED"

export const GET_EDITOR_SUCCESS = "GET_EDITOR_SUCCESS"
export const GET_EDITOR_FAILED = "GET_EDITOR_FAILED"

export const SET_MESSAGE = "SET_MESSAGE"

export const RESET_STATE_EDITOR = "RESET_STATE_EDITOR"

export const SET_EDITORS = "SET_EDITORS"

export const SET_STATE = "SET_STATE"
