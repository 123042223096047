import { callAPI } from "@/shared/services"
import store from "./index"
import { max } from "lodash-es"

let totalUpload = 0
let percent = 0

export default {
	uploadFile: params => {
		return callAPI("POST", "uploader/upload", params, {
			"Content-Type": "multipart/form-data",
			notLoading: true,
			onUploadProgress: function (res) {
				let totalFiles = store.state.totalFiles
				let uploadedSize = 0
				if (res.loaded === res.total) {
					totalUpload += parseFloat(res.loaded)
					uploadedSize = totalUpload
				} else {
					uploadedSize = max([uploadedSize, totalUpload + parseFloat(res.loaded)])
				}
				percent = Math.ceil((uploadedSize / totalFiles) * 100)
				if (percent <= 100) {
					store.commit("set", ["loaded", percent])
				}
			},
		}).then(res => res.data)
	},
	submitFormRequest: params => {
		return callAPI("POST", "form", params, { notLoading: true }).then(res => res.data)
	},
	getNotifications: params => {
		return callAPI("GET", "notification", { params, notLoading: true }).then(res => res.data)
	},
	readNotifications: () => {
		return callAPI("PUT", "notification/see-all", {}, { notLoading: true }).then(res => res.data)
	},
	readNotiSync: params => {
		return callAPI("PUT", `notification/${params.id}/read-sync`, {}).then(res => res.data)
	},
	unreadNotiSync: params => {
		return callAPI("PUT", `notification/${params.id}/unread-sync`, {}).then(res => res.data)
	},
	getCountUnread: () => {
		return callAPI("GET", "notification/count-unread", { notLoading: true }).then(res => res.data)
	},
	getOneNotification: params => {
		return callAPI("GET", `notification/${params.id}`, { notLoading: true }).then(res => res.data)
	},
	getFormStatus: params => {
		return callAPI("GET", `notification/redirect-info`, { params, notLoading: true }).then(
			res => res.data,
		)
	},
}
