import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"
import { PARAMS } from "@/shared/plugins/constants"

const initialState = {
	typesOrder: [],
	typeOrderDetail: null,
	recentFilters: {
		search: null,
		from: null,
		to: null,
		offset: PARAMS.OFFSET,
		limit: PARAMS.LIMIT,
	},
	totalPages: null,
	totalItems: null,
	modalDelete: false,
	idHighlightRow: null,
	messageErr: null,
	typesOrderAmount: [],
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
