import {
	EXPORT_CUSTOMER_BILL_FAILED,
	EXPORT_CUSTOMER_BILL_SUCCESS,
	GET_CUSTOMER_BILL_FAILED,
	GET_CUSTOMER_BILL_SUCCESS,
	GET_CUSTOMER_COMPLETED_FAILED,
	GET_CUSTOMER_COMPLETED_SUCCESS,
	RESET_FILTERS_CUSTOMER_BILL,
} from "./mutation-types"
import { PARAMS } from "@/shared/plugins/constants"

export default {
	[GET_CUSTOMER_BILL_SUCCESS]: (state, payload) => {
		state.customerBills = payload.bills
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
		state.totalRevenue = payload.totalRevenue
	},
	[GET_CUSTOMER_BILL_FAILED]: state => {
		state.customerBills = []
		state.totalPages = null
		state.totalItems = null
		state.totalRevenue = null
		state.recentFilters = {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			search: null,
			customerIds: null,
			completedFrom: null,
			completedTo: null,
		}
	},
	[EXPORT_CUSTOMER_BILL_SUCCESS]: (state, payload) => {
		state.fileExport = payload.file
	},
	[EXPORT_CUSTOMER_BILL_FAILED]: state => {
		state.fileExport = null
	},
	[RESET_FILTERS_CUSTOMER_BILL]: state => {
		state.customerBills = []
		state.totalPages = null
		state.totalItems = null
		state.totalRevenue = null
		state.recentFilters = {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			search: null,
			customerIds: null,
			completedFrom: null,
			completedTo: null,
		}
		state.fileExport = null
		state.customers = []
	},
	[GET_CUSTOMER_COMPLETED_SUCCESS]: (state, payload) => {
		state.customers = payload
	},
	[GET_CUSTOMER_COMPLETED_FAILED]: state => {
		state.customers = []
	},
}
