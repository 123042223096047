import {
	ADD_EDITOR_OF_PAYROLL,
	GET_EDITOR_PAYROLL,
	GET_EDITOR_OF_PAYROLL_DETAIL,
	UPDATE_EDITOR_OF_PAYROLL,
	EXPORT_EXCEL_PAYROLL,
	SAVE_NOTE,
} from "./action-types"
import {
	GET_EDITOR_PAYROLL_FAILED,
	GET_EDITOR_PAYROLL_SUCCESS,
	ADD_EDITOR_OF_PAYROLL_SUCCESS,
	ADD_EDITOR_OF_PAYROLL_FAILED,
	GET_EDITOR_OF_PAYROLL_DETAIL_SUCCESS,
	UPDATE_EDITOR_OF_PAYROLL_SUCCESS,
	EXPORT_EXCEL_PAYROLL_SUCCESS,
} from "./mutation-types"
import API from "../services"
import { showLoading, hideLoading } from "@/shared/utils/mixins"

export default {
	[GET_EDITOR_PAYROLL]: async ({ commit }, params) => {
		try {
			const res = await API.getEditorPayroll({ ...params })
			commit(GET_EDITOR_PAYROLL_SUCCESS, { ...res, filters: params })
		} catch (error) {
			commit(GET_EDITOR_PAYROLL_FAILED)
		}
	},
	[ADD_EDITOR_OF_PAYROLL]: async ({ commit, dispatch, state }, params = {}) => {
		try {
			await API.addEditorOfPayroll(params)
			commit(ADD_EDITOR_OF_PAYROLL_SUCCESS)
			dispatch(GET_EDITOR_PAYROLL, state.recentFilters)
			return true
		} catch (error) {
			commit(ADD_EDITOR_OF_PAYROLL_FAILED)
			return false
		}
	},
	[GET_EDITOR_OF_PAYROLL_DETAIL]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getEditorOfPayrollDetail({ ...params })
			commit(GET_EDITOR_OF_PAYROLL_DETAIL_SUCCESS)
			return res
		} catch (error) {
			return false
		}
	},
	[UPDATE_EDITOR_OF_PAYROLL]: async ({ commit, dispatch, state }, params = {}) => {
		try {
			await API.updateEditorOfPayroll(params)
			commit(UPDATE_EDITOR_OF_PAYROLL_SUCCESS)
			dispatch(GET_EDITOR_PAYROLL, state.recentFilters)
			return true
		} catch (error) {
			return false
		}
	},
	[EXPORT_EXCEL_PAYROLL]: async ({ commit }, params) => {
		try {
			await API.exportEditorPayroll({ ...params })
			commit(EXPORT_EXCEL_PAYROLL_SUCCESS)
		} catch (error) {}
	},
	[SAVE_NOTE]: async ({ dispatch, state }, params) => {
		try {
			showLoading()
			await API.saveNote(params)

			const data = {
				...state.recentFilters,
				notLoading: true,
			}
			await dispatch(GET_EDITOR_PAYROLL, data)
		} catch (error) {
		} finally {
			hideLoading()
		}
	},
}
