import {
	CREATE_USER_FAILED,
	CREATE_USER_SUCCESS,
	DELETE_USER_SUCCESS,
	GET_USERS_FAILED,
	GET_USERS_SUCCESS,
	GET_USER_FAILED,
	GET_USER_SUCCESS,
	RESET_FILTERS_USER_LIST,
	UPDATE_USER_FAILED,
	UPDATE_USER_SUCCESS,
} from "./mutation-types"
import { PARAMS } from "@/shared/plugins/constants"
import router from "@/router"

export default {
	[GET_USERS_SUCCESS]: (state, payload) => {
		state.users = payload.result
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},
	[GET_USERS_FAILED]: state => {
		state.users = []
		state.totalItems = null
		state.recentFilters = {
			form: null,
			to: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
	},
	[CREATE_USER_SUCCESS]: () => {
		router.push({
			path: "/users",
		})
	},
	[CREATE_USER_FAILED]: () => {},
	[UPDATE_USER_SUCCESS]: () => {
		router.push({
			path: "/users",
		})
	},
	[UPDATE_USER_FAILED]: () => {},
	[RESET_FILTERS_USER_LIST]: state => {
		state.users = []
		state.totalItems = null
		state.recentFilters = {
			form: null,
			to: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
		state.messageErr = null
		state.isErr = false
		state.idHighlightRow = null
	},
	[GET_USER_SUCCESS]: (state, payload) => {
		state.userDetail = payload
	},
	[GET_USER_FAILED]: state => {
		state.userDetail = null
	},
	[DELETE_USER_SUCCESS]: () => {},
}
