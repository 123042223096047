import {
	CREATE_EDITOR,
	DELETE_EDITOR,
	GET_EDITOR,
	GET_EDITORS,
	UPDATE_EDITOR,
	GET_EDITORS_NOT_PAYSHEET,
} from "./action-types"
import API from "../services"
import {
	CREATE_EDITOR_FAILED,
	CREATE_EDITOR_SUCCESS,
	GET_EDITORS_FAILED,
	GET_EDITORS_SUCCESS,
	GET_EDITOR_FAILED,
	GET_EDITOR_SUCCESS,
	UPDATE_EDITOR_SUCCESS,
	UPDATE_EDITOR_FAILED,
} from "./mutation-types"
import router from "@/router"

export default {
	[CREATE_EDITOR]: async ({ commit }, params = {}) => {
		try {
			await API.createEditor(params)
			commit(CREATE_EDITOR_SUCCESS)
			router.push({ name: "EditorManagement" })
		} catch (error) {
			commit(CREATE_EDITOR_FAILED, error.message)
		}
	},
	[UPDATE_EDITOR]: async ({ commit }, params = {}) => {
		try {
			await API.updateEditor(params)
			commit(UPDATE_EDITOR_SUCCESS)
			router.push({ name: "EditorManagement" })
		} catch (error) {
			commit(UPDATE_EDITOR_FAILED, error.message)
		}
	},
	[GET_EDITORS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getEditors({ ...params })
			let isSort = false

			if (params.isSort) {
				isSort = params.isSort
				delete params.isSort
			}
			commit(GET_EDITORS_SUCCESS, { ...res, filters: params.data, isSort })
		} catch (error) {
			commit(GET_EDITORS_FAILED)
		}
	},
	[GET_EDITORS_NOT_PAYSHEET]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getEditorsNotInPaysheet({ ...params })

			let isSort = false

			if (params.isSort) {
				isSort = params.isSort
				delete params.isSort
			}

			commit(GET_EDITORS_SUCCESS, { ...res, filters: params, isSort })
		} catch (error) {
			commit(GET_EDITORS_FAILED)
		}
	},

	[DELETE_EDITOR]: async ({ dispatch, state }, params = {}) => {
		try {
			await API.deleteEditor(params)
			dispatch(GET_EDITORS, state.recentFilters)
		} catch (error) {}
	},
	[GET_EDITOR]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getEditor({ ...params })
			commit(GET_EDITOR_SUCCESS, res)
		} catch (error) {
			commit(GET_EDITOR_FAILED)
		}
	},
}
