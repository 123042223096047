import {
	cilArrowRight,
	cilBan,
	cilBasket,
	cilBell,
	cilCalculator,
	cilCalendar,
	cilCloudDownload,
	cilChartPie,
	cilCheck,
	cilChevronBottom,
	cilChevronTop,
	cilCheckCircle,
	cilCommentSquare,
	cilCursor,
	cilDrop,
	cilDollar,
	cilEnvelopeClosed,
	cilEnvelopeOpen,
	cilEuro,
	cilGlobeAlt,
	cilGrid,
	cilFile,
	cilJustifyCenter,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilLocationPin,
	cilLockLocked,
	cilMagnifyingGlass,
	cilMoon,
	cilOptions,
	cilPencil,
	cilPeople,
	cilPuzzle,
	cilSettings,
	cilShieldAlt,
	cilSpeech,
	cilSpeedometer,
	cilStar,
	cilTask,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilXCircle,
	cibReadTheDocs,
	cilCenterFocus,
	cilGroup,
	cilLibrary,
	cilCart,
	cilColorBorder,
	cilSpreadsheet,
	cilMoney,
	cilAddressBook,
	cilContact,
	cilBookmark,
	cilX,
	cibCcPaypal,
} from "@coreui/icons"

export const iconsSet = Object.assign(
	{},
	{
		cilArrowRight,
		cilBan,
		cilBasket,
		cilBell,
		cilCalculator,
		cilCalendar,
		cilCloudDownload,
		cilChartPie,
		cilCheck,
		cilChevronBottom,
		cilChevronTop,
		cilCheckCircle,
		cilCommentSquare,
		cilCursor,
		cilDrop,
		cilDollar,
		cilEnvelopeClosed,
		cilEnvelopeOpen,
		cilEuro,
		cilGlobeAlt,
		cilGrid,
		cilFile,
		cilJustifyCenter,
		cilLaptop,
		cilLayers,
		cilLightbulb,
		cilList,
		cilLocationPin,
		cilLockLocked,
		cilMagnifyingGlass,
		cilMoon,
		cilOptions,
		cilPencil,
		cilPeople,
		cilPuzzle,
		cilSettings,
		cilShieldAlt,
		cilSpeech,
		cilSpeedometer,
		cilStar,
		cilTask,
		cilUser,
		cilUserFemale,
		cilUserFollow,
		cilXCircle,
		cibReadTheDocs,
		cilCenterFocus,
		cilGroup,
		cilLibrary,
		cilCart,
		cilColorBorder,
		cilSpreadsheet,
		cilMoney,
		cilAddressBook,
		cilContact,
		cilBookmark,
		cilX,
		cibCcPaypal,
	},
)
