import firebase from "firebase/app"
import "firebase/messaging"

const firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_API_KEY || "",
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || "",
	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || "",
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || "",
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || "",
	appId: process.env.VUE_APP_FIREBASE_APP_ID || "",
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

const onNotiClickHandler = async event => {
	const payload = event.notification?.data?.FCM_MSG.data

	event.stopImmediatePropagation()
	event.notification.close()

	const clients = await self.clients.matchAll({ includeUncontrolled: true, type: "window" })

	if (clients.length === 0) {
		await self.clients.openWindow(`/notifications/${payload.notificationId}`)
	} else {
		const client = await clients[0].focus()
		await client.navigate(`/notifications/${payload.notificationId}`)
	}
}

self.onnotificationclick = function (event) {
	event.waitUntil(onNotiClickHandler(event))
}

export const isSupported = firebase.messaging.isSupported()
export const messaging = isSupported && firebaseApp.messaging()
