import {
	CREATE_CUSTOMER_FAILED,
	CREATE_CUSTOMER_SUCCESS,
	GET_CUSTOMERS_FAILED,
	GET_CUSTOMERS_SUCCESS,
	RESET_CUSTOMER_FILTERS,
	SEARCH_EMAIL_FAILED,
	SEARCH_EMAIL_SUCCESS,
	DELETE_CUSTOMER_SUCCESS,
	GET_CUSTOMER_DETAIL_SUCCESS,
	GET_CUSTOMER_DETAIL_FAILED,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_FAILED,
	SET_VARIABLE,
	GET_CUSTOMER_PRICES_SUCCESS,
	GET_CUSTOMER_PRICES_FAILED,
} from "./mutation-types"
import { PARAMS } from "@/shared/plugins/constants"
import router from "@/router"
export default {
	[GET_CUSTOMERS_SUCCESS]: function (state, payload) {
		if (payload.isLoadMore) {
			state.customers = [...state.customers, ...payload.result]
		} else {
			state.customers = payload.result
		}
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},
	[GET_CUSTOMERS_FAILED]: state => {
		state.customers = null
		state.totalItems = null
		state.recentFilters = {
			fform: null,
			to: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
	},
	[CREATE_CUSTOMER_SUCCESS]: function (state) {
		state.messageErr = null
	},
	[CREATE_CUSTOMER_FAILED]: (state, payload) => {
		state.messageErr = payload
	},
	[SEARCH_EMAIL_SUCCESS]: function (state) {
		state.isErr = false
	},
	[SEARCH_EMAIL_FAILED]: (state, payload) => {
		state.isErr = payload
	},
	[RESET_CUSTOMER_FILTERS]: state => {
		state.customers = []
		state.totalItems = null
		state.customerDetail = null
		state.recentFilters = {
			form: null,
			to: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
		state.messageErr = null
		state.isErr = false
		state.idHighlightRow = null
		state.customerPrices = []
	},
	[DELETE_CUSTOMER_SUCCESS]: () => {},
	[UPDATE_CUSTOMER_SUCCESS]: () => {
		router.push({ name: "CustomerManagement" })
	},
	[UPDATE_CUSTOMER_FAILED]: () => {},
	[GET_CUSTOMER_DETAIL_SUCCESS]: (state, payload) => {
		state.customerDetail = payload
	},
	[GET_CUSTOMER_DETAIL_FAILED]: state => {
		state.customerDetail = null
	},
	[SET_VARIABLE]: (state, [variable, value]) => {
		state[variable] = value
	},
	[GET_CUSTOMER_PRICES_SUCCESS]: (state, payload) => {
		state.customerPrices = payload.resultListOrderType
	},
	[GET_CUSTOMER_PRICES_FAILED]: state => {
		state.customerPrices = []
	},
}
