import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	EmailsTitle: {
		EmailManagement: "Emails Management",
		EmailList: "List Email Template",
		CreateEmail: "New Template",
		UpdateEmail: "detail",
	},
	EmailFieldTable: {
		ID: "ID",
		Title: "Title",
		Description: "Description",
		DateFormat: "dd/mm/yyyy",
		Actions: "Actions",
		Created: "Created date",
	},
	EmailFilters: {
		search: "Title, ID",
		created: "Created date",
	},
	EmailButtons: {
		create: "New template",
	},
	EmailFormFields: {
		ID: "ID",
		Title: "Title",
		Description: "Description",
	},
	EmailMessage: {
		Delete: "Are you sure want to delete this email template?",
	},
	fields: {
		...{
			email_title: "Title",
			email_ID: "ID",
			email_desciption: "Desciption",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
		},
	},
})
