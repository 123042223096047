export const GET_EDITOR_PAYROLL_SUCCESS = "GET_EDITOR_PAYROLL_SUCCESS"
export const GET_EDITOR_PAYROLL_FAILED = "GET_EDITOR_PAYROLL_FAILED"

export const GET_EDITOR_OF_PAYROLL_DETAIL_SUCCESS = "GET_EDITOR_OF_PAYROLL_DETAIL_SUCCESS"
export const UPDATE_EDITOR_OF_PAYROLL_SUCCESS = "UPDATE_EDITOR_OF_PAYROLL_SUCCESS"
export const EXPORT_EXCEL_PAYROLL_SUCCESS = "EXPORT_EXCEL_PAYROLL_SUCCESS"

export const ADD_EDITOR_OF_PAYROLL_SUCCESS = "ADD_EDITOR_OF_PAYROLL_SUCCESS"
export const ADD_EDITOR_OF_PAYROLL_FAILED = "ADD_EDITOR_OF_PAYROLL_FAILED"

export const RESET_STATE_EDITOR_PAYROLL = "RESET_STATE_EDITOR_PAYROLL"
