import {
	CREATE_ORDER_TYPE,
	DELETE_TYPE_ORDER,
	GET_TYPES_ORDER,
	GET_TYPES_ORDER_PRICE,
	GET_TYPE_ORDER_AMOUNT,
	TYPE_ORDER_DETAIL,
	UPDATE_TYPE_ORDER,
	GET_TYPE_OF_PAYSHEET,
} from "./action-types"
import router from "@/router"
import API from "../services"
import {
	CREATE_ORDER_TYPE_FAILED,
	CREATE_ORDER_TYPE_SUCCESS,
	GET_TYPES_ORDER_FAILED,
	GET_TYPES_ORDER_SUCCESS,
	TYPE_ORDER_DETAIL_FAILED,
	TYPE_ORDER_DETAIL_SUCCESS,
	UPDATE_TYPE_ORDER_SUCCESS,
	UPDATE_TYPE_ORDER_FAILED,
	GET_TYPES_ORDER_PRICE_SUCCESS,
	GET_TYPES_ORDER_PRICE_FAILED,
	GET_TYPE_ORDER_AMOUNT_SUCCESS,
	GET_TYPE_ORDER_AMOUNT_FAILED,
	GET_TYPE_OF_PAYSHEET_SUCCESS,
	GET_TYPE_OF_PAYSHEET_FAILED,
} from "./mutation-types"
import { SET_STATE_PAYSHEET } from "../../paysheet/store/mutation-types"

export default {
	[CREATE_ORDER_TYPE]: async ({ commit }, params = {}) => {
		try {
			await API.createOrderType({ ...params })
			commit(CREATE_ORDER_TYPE_SUCCESS)
			router.push({
				path: "/type-order",
				query: router.currentRoute.query || {},
			})
		} catch (error) {
			commit(CREATE_ORDER_TYPE_FAILED, error.message)
		}
	},
	[UPDATE_TYPE_ORDER]: async ({ commit }, params = {}) => {
		try {
			await API.updateOrderType({ ...params })
			commit(UPDATE_TYPE_ORDER_SUCCESS)
			router.push({
				path: "/type-order",
				query: router.currentRoute.query || {},
			})
		} catch (error) {
			commit(UPDATE_TYPE_ORDER_FAILED)
		}
	},
	[GET_TYPES_ORDER]: async ({ commit }, params) => {
		try {
			const res = await API.getOrderTypes({ ...params })
			if (params.isLoadMore) {
				commit(GET_TYPES_ORDER_SUCCESS, {
					...res,
					filters: params.data,
					isLoadMore: params.isLoadMore,
				})
			} else {
				commit(GET_TYPES_ORDER_SUCCESS, { ...res, filters: params.data })
			}
			return res.types
		} catch (error) {
			commit(GET_TYPES_ORDER_FAILED)
		}
	},
	[GET_TYPES_ORDER_PRICE]: async ({ commit }, params) => {
		try {
			const res = await API.getOrderTypesPrice({ ...params })
			commit(GET_TYPES_ORDER_PRICE_SUCCESS, { ...res, filters: params.data })
		} catch (error) {
			commit(GET_TYPES_ORDER_PRICE_FAILED)
		}
	},
	[GET_TYPE_ORDER_AMOUNT]: async ({ commit }, params) => {
		try {
			const res = await API.getOrderTypesAmount({ ...params })
			commit(GET_TYPE_ORDER_AMOUNT_SUCCESS, res)
		} catch (error) {
			commit(GET_TYPE_ORDER_AMOUNT_FAILED)
		}
	},
	[TYPE_ORDER_DETAIL]: async ({ commit }, params) => {
		try {
			const res = await API.getOrderTypeDetail({ ...params })
			commit(TYPE_ORDER_DETAIL_SUCCESS, res)
		} catch (error) {
			commit(TYPE_ORDER_DETAIL_FAILED)
		}
	},
	[DELETE_TYPE_ORDER]: async ({ dispatch, state }, params) => {
		try {
			await API.deleteOrderType({ ...params })
			dispatch(GET_TYPES_ORDER, state.recentFilters)
			return {
				ok: true,
			}
		} catch (error) {
			return {
				ok: false,
			}
		}
	},
	[GET_TYPE_OF_PAYSHEET]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getTypesOfPaysheet({ ...params })
			commit(GET_TYPE_OF_PAYSHEET_SUCCESS, { ...res, filters: params.data })
			const newTypeOrder = res.resultListOrderType.map(el => {
				return {
					...el,
					id: el.typeId,
				}
			})
			commit(`paysheet/${SET_STATE_PAYSHEET}`, ["typeOrder", newTypeOrder], {
				root: true,
			})
		} catch (error) {
			commit(GET_TYPE_OF_PAYSHEET_FAILED)
		}
	},
}
