import {
	CREATE_ORDER,
	DELETE_ORDER,
	EXPORT_ORDER,
	GET_ORDER,
	GET_ORDERS,
	GET_RELATED_ORDERS,
	HIGH_LIGHT_ORDER,
	LOCK_USER,
	UPDATE_EDITOR_NOTE,
	UPDATE_ORDER,
	UNLOCK_USER,
	TRANSLATE_TEXT,
	CHECK_VALID_LINK,
	CREATE_ORDER_WEB,
	UPDATE_OEDER_WEB,
	CANCEL_ORDER_WEB,
} from "./action-types"
import API from "../services"
import {
	GET_ORDERS_FAILED,
	GET_ORDERS_SUCCESS,
	CREATE_ORDER_SUCCESS,
	CREATE_ORDER_FAILED,
	UPDATE_EDITOR_NOTE_SUCCESS,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILED,
	UPDATE_ORDER_SUCCESS,
	GET_RELATED_ORDERS_SUCCESS,
	GET_RELATED_ORDERS_FAILED,
	EXPORT_ORDER_SUCCESS,
	EXPORT_ORDER_FAILED,
	HIGH_LIGHT_ORDER_SUCCESS,
	LOCK_USER_SUCCESS,
	LOCK_USER_FAILED,
	UNLOCK_USER_SUCCESS,
	TRANSLATE_TEXT_SUCCESS,
} from "./mutation-types"
import router from "@/router"
import { showLoading, hideLoading } from "@/shared/utils/mixins"

export default {
	[GET_ORDERS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getOrders({ ...params })
			if (params.isLoadMore) {
				commit(GET_ORDERS_SUCCESS, { ...res, filters: params.data, isLoadMore: params.isLoadMore })
			} else {
				commit(GET_ORDERS_SUCCESS, { ...res, filters: params.data })
			}
			return {
				ok: true,
				data: res.orders,
				total: res.total,
			}
		} catch (error) {
			commit(GET_ORDERS_FAILED)
		}
	},
	[GET_RELATED_ORDERS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getRelatedOrders({ ...params })
			commit(GET_RELATED_ORDERS_SUCCESS, res)
			return {
				ok: true,
				data: res.orders,
				total: res.total,
			}
		} catch (error) {
			commit(GET_RELATED_ORDERS_FAILED)
		}
	},
	[GET_ORDER]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getOrder({ ...params })
			commit(GET_ORDER_SUCCESS, res)
			return res
		} catch (error) {
			commit(GET_ORDER_FAILED)
		}
	},
	[CREATE_ORDER]: async ({ commit }, params = {}) => {
		try {
			await API.createOrder({ ...params })
			commit(CREATE_ORDER_SUCCESS)
			return true
		} catch (error) {
			commit(CREATE_ORDER_FAILED, error.message)
			return false
		}
	},
	[UPDATE_EDITOR_NOTE]: async ({ commit, state, dispatch }, params = {}) => {
		try {
			showLoading()
			await API.updateEditorNote({ ...params })
			commit(UPDATE_EDITOR_NOTE_SUCCESS)
			const payload = {
				data: state.recentFilters,
				notLoading: false,
			}
			await dispatch(GET_ORDERS, payload)
		} catch (error) {
		} finally {
			hideLoading()
		}
	},
	[DELETE_ORDER]: async ({ dispatch, state }, params = {}) => {
		try {
			await API.deleteOrder(params)
			const data = {
				data: {
					...state.recentFilters,
				},
			}
			const res = await dispatch(GET_ORDERS, data)
			if (!res.data.length) {
				router.push("/orders")
			}
		} catch (error) {}
	},
	[UPDATE_ORDER]: async ({ commit }, params = {}) => {
		try {
			await API.updateOrder(params)
			commit(UPDATE_ORDER_SUCCESS)
			return true
		} catch (error) {}
	},
	[EXPORT_ORDER]: async ({ commit }, params = {}) => {
		try {
			const res = await API.exportExcel(params)
			commit(EXPORT_ORDER_SUCCESS, { file: res, roleExport: params.roleExport })
		} catch (error) {
			commit(EXPORT_ORDER_FAILED)
		}
	},
	[HIGH_LIGHT_ORDER]: async ({ commit, dispatch, state }, params = {}) => {
		try {
			await API.highlightOrder(params)
			commit(HIGH_LIGHT_ORDER_SUCCESS)
			const data = {
				data: {
					...state.recentFilters,
				},
			}
			dispatch(GET_ORDERS, data)
		} catch (error) {}
	},
	[LOCK_USER]: async ({ commit }, params) => {
		try {
			await API.lockUser(params)
			commit(LOCK_USER_SUCCESS)
		} catch (error) {
			commit(LOCK_USER_FAILED, error.message)
		}
	},
	[UNLOCK_USER]: async ({ commit }, params) => {
		try {
			await API.unlockUser(params)
			commit(UNLOCK_USER_SUCCESS)
		} catch (error) {}
	},
	[TRANSLATE_TEXT]: async ({ commit }, params) => {
		try {
			const res = await API.translateText(params)
			commit(TRANSLATE_TEXT_SUCCESS)
			return res
		} catch (error) {
			return false
		}
	},
	[CHECK_VALID_LINK]: async ({}, params) => {
		try {
			const res = await API.checkValidLink(params)
			return {
				ok: true,
				data: res,
			}
		} catch (error) {
			return {
				ok: false,
				error: error,
			}
		}
	},
	[CREATE_ORDER_WEB]: async ({}, params) => {
		try {
			await API.createOrderWeb(params)
			router.push("/orders/list")
		} catch (error) {
		} finally {
			hideLoading()
		}
	},
	[UPDATE_OEDER_WEB]: async ({}, params) => {
		try {
			await API.updateOrderWeb(params)
			router.go(-1)
			return true
		} catch (error) {
			return false
		}
	},
	[CANCEL_ORDER_WEB]: async ({}, params) => {
		try {
			await API.cancelOrderWeb(params)
			return true
		} catch (error) {
			return false
		}
	},
}
