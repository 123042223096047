<template>
	<div class="h-100">
		<router-view />
		<CustomModal
			v-if="isShowMessage"
			:is-show-cancel="false"
			:submit-text="$t('Button.OK')"
			@submit-modal="confirm"
			@cancel-modal="cancel"
		>
			<template #content>
				{{ message }}
			</template>
		</CustomModal>
		<CustomModal
			v-if="isUploadProgress"
			:title="$t('messages.upload.progress')"
			:is-show-cancel="false"
			:is-show-submit="false"
			:is-show-close="false"
		>
			<template #content>
				<b-progress :max="100" height="15px" show-progress>
					<b-progress-bar :value="loaded" :label="`${loaded}%`"></b-progress-bar>
				</b-progress>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { mapState, createNamespacedHelpers } from "vuex"
import { SEND_FIREBASE_TOKEN } from "./modules/authentication/store/action-types"
const { mapState: authState, mapActions } = createNamespacedHelpers("authentication")
export default {
	name: "App",
	components: {},
	computed: {
		...mapState(["isShowMessage", "message", "isUploadProgress", "loaded", "sidebarMinimize"]),
		...authState(["currentUser"]),
	},
	async mounted() {
		this.setToggleSidebar()
		if (this.currentUser && !window.safari && Notification.permission === "granted") {
			await this.SEND_FIREBASE_TOKEN()
		}
	},
	methods: {
		...mapActions({ SEND_FIREBASE_TOKEN }),
		confirm() {
			this.$store.commit("set", ["isShowMessage", false])
			this.$store.commit("set", ["modalShow", false])
		},
		cancel() {
			this.$store.commit("set", ["isShowMessage", false])
		},
		setToggleSidebar() {
			const widthContainer = window.innerWidth
			if (widthContainer <= 1366) {
				this.$store.commit("set", ["sidebarMinimize", !this.sidebarMinimize])
			}
		},
	},
}
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
