import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const OrdersModule = () => import("./OrdersModule.vue")
const OrderManagement = () => import("./pages/OrderManagement.vue")
const CreateOrder = () => import("./pages/CreateOrder.vue")
const OrderDetail = () => import("./pages/OrderDetail.vue")
const UpdateOrder = () => import("./pages/UpdateOrder.vue")
const CreateOrderWeb = () => import("./pages/CreateOrderWeb.vue")
const OrderWebDetail = () => import("./pages/OrderWebDetail")
const UpdateOrderWeb = () => import("./pages/UpdateOrderWeb")

const orderRoutes = [
	{
		path: "/orders",
		name: "OrdersModule",
		component: OrdersModule,
		meta: {
			label: i18n.t("OrdersTitle.OrderManagement"),
		},
		redirect: "/orders/list",
		children: [
			{
				path: "list",
				name: "OrderManagement",
				component: OrderManagement,
				meta: {
					title: i18n.t("OrdersTitle.OrderList"),
					label: i18n.t("OrdersTitle.OrderList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.QA],
				},
			},
			{
				path: "create",
				name: "CreateOrder",
				component: CreateOrder,
				props: route => ({
					updateOrder: Boolean(route.query.update_order),
				}),
				meta: {
					title: i18n.t("OrdersTitle.CreateOrder"),
					label: i18n.t("OrdersTitle.CreateOrder"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.QA],
				},
			},
			{
				path: "web/create",
				name: "CreateOrderWeb",
				component: CreateOrderWeb,
				props: route => ({
					updateOrder: Boolean(route.query.update_order),
					formID: Number(route.query.formID),
				}),
				meta: {
					title: i18n.t("OrdersTitle.CreateOrder"),
					label: i18n.t("OrdersTitle.CreateOrder"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/detail",
				name: "OrderDetail",
				component: OrderDetail,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("OrdersTitle.OrderDetail"),
					label: i18n.t("OrdersTitle.OrderDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.QA],
				},
			},
			{
				path: "web/:id/detail",
				name: "OrderWebDetail",
				component: OrderWebDetail,
				props: route => ({
					isView: true,
					id: route.params.id,
				}),
				meta: {
					title: i18n.t("OrdersTitle.OrderDetail"),
					label: i18n.t("OrdersTitle.OrderDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "UpdateOrder",
				component: UpdateOrder,
				props: route => ({
					default: true,
					id: route.params.id,
					originalId: route.query.original_ID,
					isUpdate: true,
					isUpdateOrder: true,
				}),
				meta: {
					title: i18n.t("OrdersTitle.UpdateOrder"),
					label: i18n.t("OrdersTitle.UpdateOrder"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.QA],
				},
			},
			{
				path: "web/:id/update",
				name: "UpdateOrderWeb",
				component: UpdateOrderWeb,
				props: route => ({
					id: route.params.id,
					isUpdate: true,
					originalId: route.query.original_ID,
				}),
				meta: {
					title: i18n.t("OrdersTitle.UpdateOrder"),
					label: i18n.t("OrdersTitle.UpdateOrder"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/orders/list",
			},
		],
	},
]

export default orderRoutes
