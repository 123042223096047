import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	EditorPayrollTitle: {
		EditorPayrollManagement: "Editor payroll",
		EditorPayrollList: "List editor payroll",
		SelectEditor: "Select editor",
	},
	EditorPayrollFieldTable: {
		UpdateID: "Update ID",
		OrderID: "Order ID",
		CustomerName: "Customer name",
		Title: "Title",
		Deadline: "Deadline",
		ReceivedTime: "Received time",
		CompleteDate: "Complete Date",
		Amount: "Amount",
		MissingAmount: "Missing amount",
		Editor: "Editor",
		EditorNote: "Editor note",
	},
	EditorPayrollFormFields: {
		type: "Order type",
		code: "Display code",
		search: "Search editor nickname",
	},
	EditorPayrollFilter: {
		search: "Update ID, order ID, title",
		completeDate: "Complete date",
		Yes: "Yes",
		No: "No",
	},

	validation: {
		...en.messages,
		...{
			// custom message rules
			requiredExport: "Required information",
		},
	},
})
