import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"
import { PARAMS } from "@/shared/plugins/constants"

const initialState = {
	editorPayroll: [],
	recentFilters: {
		limit: PARAMS.LIMIT,
		offset: PARAMS.OFFSET,
		search: null,
		customerIds: null,
		editorIds: null,
		from: null,
		to: null,
		missingAmount: null,
	},
	totalPages: null,
	totalItems: null,
	totalEditorWage: null,
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
