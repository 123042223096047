import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const CustomerBillModule = () => import("./CustomerBillModule.vue")
const CustomerBillManagement = () => import("./pages/CustomerBillManagement.vue")

const customerBillRoutes = [
	{
		path: "/customer-bill",
		name: "CustomerBillModule",
		component: CustomerBillModule,
		meta: {
			label: i18n.t("CustomerBillTitle.CustomerBillManagement"),
		},
		redirect: "/customer-bill/list",
		children: [
			{
				path: "list",
				name: "CustomerBillManagement",
				component: CustomerBillManagement,
				meta: {
					title: i18n.t("CustomerBillTitle.CustomerBillList"),
					label: i18n.t("CustomerBillTitle.CustomerBillList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/customer-bill/list",
			},
		],
	},
]

export default customerBillRoutes
