import {
	CREATE_CUSTOMER,
	GET_CUSTOMERS,
	SEARCH_EMAIL,
	DELETE_CUSTOMER,
	GET_CUSTOMER_DETAIL,
	UPDATE_CUSTOMER,
	GET_CUSTOMER_PRICES,
} from "./action-types"
import API from "../services"
import {
	GET_CUSTOMERS_FAILED,
	GET_CUSTOMERS_SUCCESS,
	CREATE_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_FAILED,
	SEARCH_EMAIL_SUCCESS,
	SEARCH_EMAIL_FAILED,
	DELETE_CUSTOMER_SUCCESS,
	GET_CUSTOMER_DETAIL_SUCCESS,
	GET_CUSTOMER_DETAIL_FAILED,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_FAILED,
	GET_CUSTOMER_PRICES_SUCCESS,
	GET_CUSTOMER_PRICES_FAILED,
} from "./mutation-types"
import router from "@/router"

export default {
	[GET_CUSTOMERS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getCustomers({ ...params })

			if (params.isLoadMore) {
				commit(GET_CUSTOMERS_SUCCESS, {
					...res,
					filters: params.data,
					isLoadMore: params.isLoadMore,
				})
			} else {
				commit(GET_CUSTOMERS_SUCCESS, { ...res, filters: params.data })
			}
			return res.result
		} catch (error) {
			commit(GET_CUSTOMERS_FAILED)
		}
	},
	[GET_CUSTOMER_DETAIL]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getCustomer({ ...params })
			commit(GET_CUSTOMER_DETAIL_SUCCESS, res)
		} catch (error) {
			commit(GET_CUSTOMER_DETAIL_FAILED)
		}
	},
	[CREATE_CUSTOMER]: async ({ commit }, params = {}) => {
		try {
			await API.createCustomer({ ...params })
			commit(CREATE_CUSTOMER_SUCCESS)
			router.push("/customers")
		} catch (error) {
			commit(CREATE_CUSTOMER_FAILED, error.message)
		}
	},
	[UPDATE_CUSTOMER]: async ({ commit }, params = {}) => {
		try {
			await API.updateCustomer({ ...params })
			commit(UPDATE_CUSTOMER_SUCCESS)
		} catch (error) {
			commit(UPDATE_CUSTOMER_FAILED)
		}
	},
	[SEARCH_EMAIL]: async ({ commit }, params = {}) => {
		try {
			const res = await API.searchEmail({ ...params })
			if (res.result.length) {
				commit(SEARCH_EMAIL_FAILED, true)
			} else {
				commit(SEARCH_EMAIL_SUCCESS)
			}
			return res.result
		} catch (error) {}
	},
	[DELETE_CUSTOMER]: async ({ commit }, params = {}) => {
		try {
			await API.deleteCustomer({ ...params })
			commit(DELETE_CUSTOMER_SUCCESS)
		} catch (error) {}
	},
	[GET_CUSTOMER_PRICES]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getCustomerPrices({ ...params })
			commit(GET_CUSTOMER_PRICES_SUCCESS, res)
		} catch (error) {
			commit(GET_CUSTOMER_PRICES_FAILED)
		}
	},
}
