import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const CustomersModule = () => import("./CustomersModule.vue")
const CustomerManagement = () => import("./pages/CustomerManagement.vue")
const CreateCustomer = () => import("./pages/CreateCustomer.vue")
const UpdateCustomer = () => import("./pages/UpdateCustomer.vue")

const customerRoutes = [
	{
		path: "/customers",
		name: "CustomersModule",
		component: CustomersModule,
		meta: {
			label: i18n.t("CustomersTitle.CustomerManagement"),
		},
		redirect: "/customers/list",
		children: [
			{
				path: "list",
				name: "CustomerManagement",
				component: CustomerManagement,
				meta: {
					title: i18n.t("CustomersTitle.CustomerList"),
					label: i18n.t("CustomersTitle.CustomerList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: "create",
				name: "CreateCustomer",
				component: CreateCustomer,
				meta: {
					title: i18n.t("CustomersTitle.CreateCustomer"),
					label: i18n.t("CustomersTitle.CreateCustomer"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "UpdateCustomer",
				component: UpdateCustomer,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("CustomersTitle.UpdateCustomer"),
					label: i18n.t("CustomersTitle.UpdateCustomer"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/customers/list",
			},
		],
	},
]

export default customerRoutes
