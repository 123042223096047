import API from "../services"
import {
	CREATE_TEMP_EMAIL,
	DELETE_TEMP_EMAIL,
	GET_EMAIL_LAST,
	GET_TEMP_EMAIL,
	GET_TEMP_EMAILS,
	UPDATE_TEMP_EMAIL,
} from "./action-types"
import {
	GET_TEMP_EMAILS_FAILED,
	GET_TEMP_EMAILS_SUCCESS,
	GET_EMAIL_LAST_SUCCESS,
	CREATE_TEMP_EMAIL_SUCCESS,
	GET_TEMP_EMAIL_SUCCESS,
	UPDATE_TEMP_EMAIL_SUCCESS,
	DELETE_TEMP_EMAIL_SUCCESS,
} from "./mutation-types"

export default {
	[GET_TEMP_EMAILS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getTempEmails({ ...params })
			if (params.isLoadMore) {
				commit(GET_TEMP_EMAILS_SUCCESS, { ...res, filters: params, isLoadMore: params.isLoadMore })
			} else {
				commit(GET_TEMP_EMAILS_SUCCESS, { ...res, filters: params })
			}
			return res
		} catch (error) {
			commit(GET_TEMP_EMAILS_FAILED)
		}
	},
	[GET_EMAIL_LAST]: async ({ commit }) => {
		try {
			const res = await API.getLastTemplate()
			commit(GET_EMAIL_LAST_SUCCESS, res)
		} catch (error) {}
	},
	[GET_TEMP_EMAIL]: async ({ commit }, params) => {
		try {
			const res = await API.getTemplateDetail({ ...params })
			commit(GET_TEMP_EMAIL_SUCCESS, res)
		} catch (error) {}
	},
	[CREATE_TEMP_EMAIL]: async ({ commit }, params = {}) => {
		try {
			await API.createTemplate({ ...params })
			commit(CREATE_TEMP_EMAIL_SUCCESS)
		} catch (error) {}
	},
	[UPDATE_TEMP_EMAIL]: async ({ commit }, params = {}) => {
		try {
			await API.updateTemplate({ ...params })
			commit(UPDATE_TEMP_EMAIL_SUCCESS)
		} catch (error) {}
	},
	[DELETE_TEMP_EMAIL]: async ({ commit }, params = {}) => {
		try {
			await API.deleteTemplate({ ...params })
			commit(DELETE_TEMP_EMAIL_SUCCESS)
		} catch (error) {}
	},
}
