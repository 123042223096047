import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const FormsModule = () => import("./FormsModule.vue")
const FormManagement = () => import("./pages/FormManagement.vue")
const FormDetail = () => import("./pages/FormDetail.vue")
const UpdateForm = () => import("./pages/UpdateForm.vue")
const UpdateFormWeb = () => import("./pages/UpdateFormWeb.vue")
const FormWebDetail = () => import("./pages/FormWebDetail.vue")

const formRoutes = [
	{
		path: "/forms",
		name: "FormsModule",
		component: FormsModule,
		meta: {
			label: i18n.t("FormsTitle.FormManagement"),
		},
		redirect: "/forms/list",
		children: [
			{
				path: "list",
				name: "FormManagement",
				component: FormManagement,
				meta: {
					title: i18n.t("FormsTitle.FormList"),
					label: i18n.t("FormsTitle.FormList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.QA],
				},
			},
			{
				path: ":id",
				name: "FormDetail",
				component: FormDetail,
				props: route => ({
					default: true,
					id: route.params.id,
				}),
				meta: {
					title: i18n.t("FormsTitle.FormDetail"),
					label: i18n.t("FormsTitle.FormDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.QA],
				},
			},
			{
				path: "web/:id",
				name: "FormWebDetail",
				component: FormWebDetail,
				props: route => ({
					default: true,
					id: route.params.id,
				}),
				meta: {
					title: i18n.t("FormsTitle.FormDetail"),
					label: i18n.t("FormsTitle.FormDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "UpdateForm",
				component: UpdateForm,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("FormsTitle.UpdateForm"),
					label: i18n.t("FormsTitle.UpdateForm"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.QA],
				},
			},
			{
				path: "web/:id/update",
				name: "UpdateFormWeb",
				component: UpdateFormWeb,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("FormsTitle.UpdateForm"),
					label: i18n.t("FormsTitle.UpdateForm"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/forms/list",
			},
		],
	},
]

export default formRoutes
