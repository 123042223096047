import {
	CREATE_PAYSHEET_FAILED,
	CREATE_PAYSHEET_SUCCESS,
	GET_PAYSHEETS_FAILED,
	GET_PAYSHEETS_SUCCESS,
	RESET_STATE_PAYSHEET,
	SET_EDITOR_SELECTED,
	SET_STATE_PAYSHEET,
	SET_UNIT_WAGE,
	GET_EDITORS_IN_PAYSHEET_SUCCESS,
	GET_EDITORS_IN_PAYSHEET_FAILED,
	UPDATE_PAYSHEET_SUCCESS,
	UPDATE_PAYSHEET_FAILED,
} from "./mutation-types"
// import { sortByAlpahbetically } from "@/shared/utils/mixins"
import { PARAMS } from "@/shared/plugins/constants"
import Vue from "vue"
import { orderBy } from "lodash-es"

const sortByAlpahbetically = arr => {
	if (!arr?.length) return []
	const newArr = orderBy(arr, [el => el.nickname.toLowerCase()], ["asc"])
	return newArr
}

export default {
	[SET_EDITOR_SELECTED]: (state, payload) => {
		state.selectedEditor = sortByAlpahbetically(payload.data)

		if (payload.totalAdd || payload.totalRemove) {
			if (payload.removed) {
				state.totalItemsSelected = state.totalItemsSelected - payload.totalRemove
			} else {
				state.totalItemsSelected = state.totalItemsSelected + payload.totalAdd
			}
		}
		state.totalPagesSelected = Math.ceil(
			state.totalItemsSelected / state.recentFiltersSelected.limit,
		)
	},
	[RESET_STATE_PAYSHEET]: state => {
		state.selectedEditor = []
		state.totalItemsSelected = null
		state.totalPagesSelected = null
		state.recentFiltersSelected = {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
		}
		state.isChangePageEditorsSelected = false
		state.listEditorSelected = []
		state.messageErr = null
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			search: null,
			from: null,
			to: null,
		}
		state.paysheets = []
		state.typeOrder = []
		state.paysheetName = null
		state.paysheetId = null
	},
	[SET_UNIT_WAGE]: (state, payload) => {
		const index = state.typeOrder.findIndex(el => el.id === payload.id)

		if (index < 0) {
			state.typeOrder.push(payload)
		} else {
			Vue.set(state.typeOrder, index, payload)
		}
	},
	[CREATE_PAYSHEET_FAILED]: (state, payload) => {
		state.messageErr = payload
	},
	[CREATE_PAYSHEET_SUCCESS]: state => {
		state.messageErr = null
	},
	[UPDATE_PAYSHEET_FAILED]: (state, payload) => {
		state.messageErr = payload
	},
	[UPDATE_PAYSHEET_SUCCESS]: state => {
		state.messageErr = null
	},
	[GET_PAYSHEETS_SUCCESS]: (state, payload) => {
		if (payload.isLoadMore) {
			state.paysheets = [...state.paysheets, ...payload.result]
		} else {
			state.paysheets = payload.result
		}
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},
	[GET_PAYSHEETS_FAILED]: state => {
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			search: null,
			from: null,
			to: null,
		}
		state.paysheets = []
	},
	[SET_STATE_PAYSHEET]: (state, [variable, value]) => {
		state[variable] = value
	},
	[GET_EDITORS_IN_PAYSHEET_SUCCESS]: (state, payload) => {
		state.selectedEditor = sortByAlpahbetically(payload.resultListEditor)
		state.totalItemsSelected = payload.totalEditor
		state.totalPagesSelected = Math.ceil(
			payload.totalItemsSelected / state.recentFiltersSelected.limit,
		)
		state.paysheetName = payload.paySheetName
		state.paysheetId = payload.paySheetId
	},
	[GET_EDITORS_IN_PAYSHEET_FAILED]: state => {
		state.selectedEditor = []
		state.totalItemsSelected = null
		state.recentFiltersSelected = {
			limit: 1000,
			offset: PARAMS.OFFSET,
		}
		state.totalPagesSelected = null
		state.paysheetName = null
		state.paysheetId = null
	},
}
