export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED"

export const GET_RELATED_ORDERS_SUCCESS = "GET_RELATED_ORDERS_SUCCESS"
export const GET_RELATED_ORDERS_FAILED = "GET_RELATED_ORDERS_FAILED"

export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS"
export const GET_ORDER_FAILED = "GET_ORDER_FAILED"

export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAILED = "CREATE_ORDER_FAILED"

export const EXPORT_ORDER_SUCCESS = "EXPORT_ORDER_SUCCESS"
export const EXPORT_ORDER_FAILED = "EXPORT_ORDER_FAILED"

export const UPDATE_EDITOR_NOTE_SUCCESS = "UPDATE_EDITOR_NOTE_SUCCESS"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const HIGH_LIGHT_ORDER_SUCCESS = "HIGH_LIGHT_ORDER_SUCCESS"

export const RESET_ORDER_FILTERS = "RESET_ORDER_FILTERS"
export const SET_ORDER_FILTERS = "SET_ORDER_FILTERS"
export const SET_ORDERS = "SET_ORDERS"
export const SET = "SET"

export const TRANSLATE_TEXT_SUCCESS = "TRANSLATE_TEXT_SUCCESS"

export const LOCK_USER_SUCCESS = "LOCK_USER_SUCCESS"
export const LOCK_USER_FAILED = "LOCK_USER_FAILED"

export const UNLOCK_USER_SUCCESS = "UNLOCK_USER_SUCCESS"
export const SET_AMOUNTS = "SET_AMOUNTS"
export const INIT_AMOUNTS = "INIT_AMOUNTS"
export const SET_ORDER_TYPES_SELECTED = "SET_ORDER_TYPES_SELECTED"

export const LINK_VALID = "LINK_VALID"
export const LINK_INVALID = "LINK_INVALID"
