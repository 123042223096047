import Vue from "vue"
import { camelCase, snakeCase, round, uniqBy, filter, orderBy, isInteger } from "lodash-es"
import moment from "moment"
import store from "@/store"
import momentTimezone from "moment-timezone"

let loader = null

const showLoading = () => {
	loader = Vue.$loading.show()
}

const hideLoading = () => {
	if (loader) {
		loader.hide()
		loader = null
	}
}

const showProgressBar = () => {
	store.commit("set", ["isUploadProgress", true])
	store.commit("set", ["modalShow", true])
}
const hideProgressBar = () => {
	store.commit("set", ["isUploadProgress", false])
	store.commit("set", ["modalShow", false])
}

const checkValidPhone = event => {
	const specialKeyCodes = [8, 9, 13, 46, 37, 39] // backspace, enter, tab, delete, arrow left. arrow right
	const ctrlCombines = [65, 67, 86, 88] // ctrl A, ctrl C, ctrl V, ctrl X
	const countryKeyCodes = [231] // country keyboards on window: vietnamese
	if (
		!event.shiftKey &&
		((event.keyCode >= 48 && event.keyCode <= 57) ||
			(event.keyCode >= 96 && event.keyCode <= 105) ||
			(event.ctrlKey && ctrlCombines.includes(event.keyCode)) ||
			specialKeyCodes.includes(event.keyCode) ||
			(countryKeyCodes.includes(event.keyCode) &&
				[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(+event.key)))
	) {
		return true
	}
	return event.preventDefault()
}
const checkValidNumber = event => {
	const specialKeyCodes = [8, 9, 13, 46, 37, 39, 190] // backspace, enter, tab, delete, arrow left. arrow right
	const ctrlCombines = [65, 67, 86, 88] // ctrl A, ctrl C, ctrl V, ctrl X
	const countryKeyCodes = [231] // country keyboards on window: vietnamese
	if (
		!event.shiftKey &&
		((event.keyCode >= 48 && event.keyCode <= 57) ||
			(event.keyCode >= 96 && event.keyCode <= 105) ||
			(event.ctrlKey && ctrlCombines.includes(event.keyCode)) ||
			specialKeyCodes.includes(event.keyCode) ||
			(countryKeyCodes.includes(event.keyCode) &&
				[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(+event.key)))
	) {
		return true
	}
	return event.preventDefault()
}

const handleOnPaste = event => {
	const value = parseFloat(event.clipboardData.getData("text"))
	if (value >= 0) return
	if (value < 0 || isNaN(value)) return event.preventDefault()
}

// use for check error field response api.
// formFields: all field in form submit. Each. field should be camelCase. Converted to snakeCase.
// add prefix to support check custom vee-validate fields in multi language. ex: auth_full_name
// error: {field: snakeCase , msg: string}
const checkErrorFieldResponse = (prefix = "", formFields = [], error = { field: "", msg: "" }) => {
	const validateFields = formFields.map(el => {
		el = snakeCase(el)
		return el.replace(prefix, "")
	})
	const message = {}
	if (validateFields.includes(error.field)) {
		message[`${prefix}${error.field}`] = error.msg
	}
	return message
}

const camelizeKeys = data => {
	if (Array.isArray(data)) {
		return data.map(v => camelizeKeys(v))
	} else if (data !== null && data.constructor === Object) {
		return Object.keys(data).reduce(
			(result, key) => ({
				...result,
				// eslint-disable-next-line no-unused-vars
				[camelCase(key)]: camelizeKeys(data[key]),
			}),
			{},
		)
	}
	return data
}

const mappingResponseConvention = data => {
	try {
		return camelizeKeys(data)
	} catch (error) {}
}

const mappingRequestConvention = resquest => {
	try {
		// handle request data
		if (resquest.data && resquest.data instanceof FormData) {
			let mapping = new FormData()
			resquest.data.forEach(function (value, key) {
				mapping.append(snakeCase(key), removeExtraSpaces(value))
			})
			resquest.data = mapping
			return resquest
		}
		if (resquest.data && resquest.data instanceof Object) {
			let mapping = {}
			Object.keys(resquest.data).map(field => {
				mapping[snakeCase(field)] = removeExtraSpaces(resquest.data[field])
			})
			resquest.data = { ...mapping }
		}

		// handle request params
		if (resquest.params && resquest.params instanceof FormData) {
			let mapping = new FormData()
			resquest.params.forEach(function (value, key) {
				mapping.append(snakeCase(key), removeExtraSpaces(value))
			})
			resquest.params = mapping
			return resquest
		}
		if (resquest.params && resquest.params instanceof Object) {
			let mapping = {}
			Object.keys(resquest.params).map(field => {
				mapping[snakeCase(field)] = removeExtraSpaces(resquest.params[field])
			})
			resquest.params = { ...mapping }
		}

		return resquest
	} catch (error) {}
}

const convertToDateVietNam = (date, currentFormat = "DD-MM-YYYY") => {
	if (!date) return null
	const dateMoment = moment(date, currentFormat)
	if (dateMoment.isValid()) return dateMoment.format("DD-MM-YYYY HH:mm")
}

const convertUTCtoDateVN = date => {
	if (!date) return null
	const dateMoment = moment(date)
	if (dateMoment.isValid()) return dateMoment.format("DD-MM-YYYY HH:mm")
}

const convertDateToDateVN = date => {
	if (!date) return null
	const dateMoment = moment(date)

	if (dateMoment.isValid()) return dateMoment.format("DD-MM-YYYY")
}

const convertToISODate = date => {
	const dateMoment = moment(date, "DD-MM-YYYY")

	if (dateMoment.isValid()) return dateMoment.format("YYYY-MM-DD")

	return null
}

const convertDateWithTimezone = date => {
	if (!date) return

	const tz = momentTimezone.tz.guess()

	return momentTimezone(date).tz(tz).format("DD-MM-YYYY")
}

const comvertToISO = (date, startDate = true, currentFormat = "DD-MM-YYYY") => {
	if (!date) return
	if (startDate) {
		return moment(date, currentFormat).startOf("date").utc().format()
	} else {
		return moment(date, currentFormat).endOf("date").utc().format()
	}
}

const convertDateToUtc = (date, type, startDate = true) => {
	if (!date) return

	if (startDate) {
		return moment(date).startOf(type).utc().format()
	}
	return moment(date).endOf(type).utc().format()
}

const convertDateVnToISO = (date, currentFormat = "DD-MM-YYYY HH:mm") => {
	if (!date) return
	const dateMoment = moment(date, currentFormat)
	if (dateMoment.isValid()) return moment(date, currentFormat).utc().format()
}

const disabledAfterToday = date => {
	const today = new Date()
	today.setHours(0, 0, 0, 0)

	return date > today
}
const disabledAfterTimeToday = date => {
	const today = new Date()
	if (date < today) {
		return date > today
	}

	return date.getHours() > today.getHours() || date.getMinutes() > today.getMinutes()
}

const getKeyByValue = (object, value) => {
	return Object.keys(object).find(key => object[key] === value)
}

const removeExtraSpaces = value => {
	// if (isString(value)) {
	//   return value.replace(/  +/g, " ");
	// }
	return value
}

const getTimeLocale = (time, format = "DD/MM/YYYY HH:mm:ss") => {
	if (!moment(time)._isValid) return null
	return moment.utc(time).local().format(format)
}

const getTimeUTC = (time, currentFormat = "YYYY/MM/DD", format = "YYYY-MM-DDTHH:mm:ss") => {
	if (!moment(time, currentFormat)._isValid) return null
	return moment(time, currentFormat).utc().format(format)
}

const getTimeFromNow = (startTime, format = "DD/MM/YYYY HH:mm:ss") => {
	const nowTime = moment(new Date())
	const fromTime = moment(getTimeLocale(startTime), format)

	// different year
	if (!moment(nowTime).isSame(fromTime, "year")) {
		return moment(fromTime).format("DD/MM/YYYY HH:mm")
	}

	// date > 3 days ago
	if (nowTime.diff(fromTime, "days") > 3) {
		return moment(fromTime).format("DD/MM HH:mm")
	}

	return fromTime.from(fromTime > nowTime ? fromTime : nowTime)
}
const roundNumber = (number, precision = 1) => {
	if (!number) return
	return round(number, precision)
}

const getFileNameDuplicate = arr => {
	const result = filter(arr, v => filter(arr, v1 => v1.name === v.name).length > 1)
	return uniqBy(result, "name")
}

const sortByAlpahbetically = arr => {
	if (!arr?.length) return []
	const newArr = orderBy(arr, [el => el.nickname.toLowerCase()], ["asc"])
	return newArr
}

const isURL = str => {
	if (str.startsWith("http://") || str.startsWith("https://")) {
		return true
	}
	return false
}

const formatPricePackage = price => {
	if (!price) return null

	let priceFormat = null

	if (isInteger(price)) {
		priceFormat = formatNumber(round(price, 2).toString())
	} else {
		let decimal_pos = price.toString().split(".")

		let left_side = decimal_pos[0]
		let right_side = decimal_pos[1]

		left_side = formatNumber(left_side)
		right_side = right_side?.substring(0, 2)
		priceFormat = left_side + "." + right_side
	}
	return priceFormat
}
const formatNumber = n => {
	if (!n) return null
	// format number 1000000 to 1,234,567
	return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const mixin = {
	methods: {
		checkErrorFieldResponse,
		checkValidPhone,
		convertToDateVietNam,
		convertToISODate,
		disabledAfterToday,
		getTimeLocale,
		getTimeUTC,
		getTimeFromNow,
		formatNumber,
		comvertToISO,
		showLoading,
		hideLoading,
		roundNumber,
		convertDateToDateVN,
		convertUTCtoDateVN,
		convertDateVnToISO,
		showProgressBar,
		hideProgressBar,
		convertDateToUtc,
		getFileNameDuplicate,
		sortByAlpahbetically,
		handleOnPaste,
		isURL,
		checkValidNumber,
		disabledAfterTimeToday,
		formatPricePackage,
		convertDateWithTimezone,
	},
}

Vue.mixin(mixin)

export {
	checkErrorFieldResponse,
	checkValidPhone,
	mappingResponseConvention,
	mappingRequestConvention,
	convertToDateVietNam,
	convertToISODate,
	disabledAfterToday,
	getKeyByValue,
	formatNumber,
	comvertToISO,
	showLoading,
	hideLoading,
	roundNumber,
	convertDateToDateVN,
	convertUTCtoDateVN,
	convertDateVnToISO,
	showProgressBar,
	hideProgressBar,
	convertDateToUtc,
	getFileNameDuplicate,
	sortByAlpahbetically,
	handleOnPaste,
	isURL,
	checkValidNumber,
	disabledAfterTimeToday,
	formatPricePackage,
	convertDateWithTimezone,
}
