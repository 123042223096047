export const GET_FORMS_SUCCESS = "GET_FORMS_SUCCESS"
export const GET_FORMS_FAILED = "GET_FORMS_FAILED"

export const GET_FORM_DETAIL_SUCCESS = "GET_FORM_DETAIL_SUCCESS"
export const GET_FORM_DETAIL_FAILED = "GET_FORM_DETAIL_FAILED"

export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS"
export const UPDATE_FORM_FAILED = "UPDATE_FORM_FAILED"

export const CANCEL_FORM_SUCCESS = "CANCEL_FORM_SUCCESS"
export const CANCEL_FORM_FAILED = "CANCEL_FORM_FAILED"

export const UNLOCK_FORM_SUCCESS = "UNLOCK_FORM_SUCCESS"

export const LOCK_FORM_SUCCESS = "LOCK_FORM_SUCCESS"
export const LOCK_FORM_FAILED = "LOCK_FORM_FAILED"

export const GET_REQUEST_LIST_SUCCESS = "GET_REQUEST_LIST_SUCCESS"
export const GET_REQUEST_LIST_FAILED = "GET_REQUEST_LIST_FAILED"

export const GET_FORM_WEB_DETAIL_SUCCESS = "GET_FORM_WEB_DETAIL_SUCCESS"
export const GET_FORM_WEB_DETAIL_FAILED = "GET_FORM_WEB_DETAIL_FAILED"

export const RESET_FILTERS_FORM_LIST = "RESET_FILTERS_FORM_LIST"
export const RESET_FORM_DETAIL = "RESET_FORM_DETAIL"

export const SET = "SET"
