<template>
	<div>
		<CRow v-if="showAdd" class="text-center">
			<CCol>
				<div class="text-right mb-3">
					<button type="button" class="btn btn-outline-custom" @click="$refs.fileInput.click()">
						Add file
					</button>
				</div>

				<input
					ref="fileInput"
					class="__upload-image has_file"
					type="file"
					style="display: none"
					:accept="getTypeImage"
					multiple
					@change="onFileChanged"
				/>
			</CCol>
		</CRow>
		<div class="form-group position-relative">
			<ValidationProvider
				:id="name"
				v-slot="{ classes, errors }"
				ref="files"
				:name="name"
				:rules="{ requiredExport: isRequired }"
			>
				<div class="form-group row">
					<label for="upload" class="col-md-2 col-12 col-form-label">{{ label }}</label>
					<div :class="['col-12 col-md-10 overflow-hidden', classes]">
						<div v-if="files.length" id="upload" class="upload__files" :style="getBg()">
							<template v-for="(item, index) in files">
								<FileItem
									:ref="`file-${item.name}`"
									:key="index"
									:file="item"
									:index="index"
									:show-delete="showDelete"
									:view-image="viewImage"
									:show-download="showDownload"
									:show-checkbox="showCheckbox"
									@handleDelete="handleDelete"
									@handleviewImage="handleviewImage"
									@choose-thumbnail="handleChooseThumbnail"
								/>
							</template>
						</div>
						<textarea
							v-else
							v-model.trim="file"
							rows="6"
							type="text"
							placeholder="Upload file"
							disabled
							class="form-control remove-resize custom__form__control"
							:class="{ disable: !isUpdate }"
							autocomplete="upload"
						/>
						<div class="error-mess">
							{{ errors[0] }}
						</div>
					</div>
				</div>
			</ValidationProvider>
		</div>

		<b-modal
			v-model="viewImage"
			centered
			modal-class="b_modal_custom"
			body-class="p-0"
			size="xl"
			hide-footer
			hide-header
			no-close-on-backdrop
		>
			<b-carousel
				id="carousel-1"
				v-model="slide"
				:interval="0"
				controls
				no-wrap
				no-animation
				class="custom__carousel"
				img-height="500"
			>
				<b-carousel-slide v-for="(image, index) in listImage" :key="index">
					<template #img>
						<b-img :src="image.url" fluid-grow center class="custom__b-img" />
					</template>
				</b-carousel-slide>
			</b-carousel>
			<SvgIcon v-if="isShowClose" icon="close" class="icon--close" @click="handleClose" />
		</b-modal>

		<CustomModal
			v-if="isInvalidFiles"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirm"
			@cancel-modal="confirm"
		>
			<template #content>
				{{ $t("messages.result") }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import {
	FILE_TYPES,
	FILE_TYPES_DIGITAL,
	FILE_RESULT_TYPES,
	TYPE_UPLOAD,
} from "@/shared/plugins/constants"
import { SOURCES } from "../plugins/constants"
export default {
	name: "UploadFiles",
	components: {
		FileItem: () => import("./FileItem"),
	},
	props: {
		isUploadSuccess: {
			type: Boolean,
			default: false,
		},
		showDelete: {
			type: Boolean,
			default: false,
		},
		showDownload: {
			type: Boolean,
			default: false,
		},
		showAdd: {
			type: Boolean,
			default: false,
		},
		files: {
			type: Array,
			default: () => [],
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
			default: "Upload file",
		},
		name: {
			type: String,
			default: null,
		},
		showCheckbox: {
			type: Boolean,
			default: false,
		},
		source: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isUpload: false,
			viewImage: false,
			isShowClose: false,
			file: null,
			fileView: null,
			imageType: ["image/jpeg", "image/png", "image/jpg"],
			listImage: [],
			slide: null,
			uploadFiles: [],
			isInvalidFiles: false,
		}
	},
	computed: {
		getTypeImage() {
			if (this.name === TYPE_UPLOAD.RESULT && this.source === SOURCES.WEB) {
				return ".jpg, .png, .jpeg"
			}
			return ".jpg, .png, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .zip, .max, .psd, .rar"
		},
	},
	watch: {
		files(val) {
			if (val && val.length) {
				this.$refs.files.reset()
			}
		},
		isUploadSuccess(val) {
			if (val) {
				this.$emit("handleUploadFiles", [])
			}
		},
		async slide(val) {
			await this.delay(100)
			const prev = document.querySelector(".carousel-control-prev")
			const next = document.querySelector(".carousel-control-next")

			if (this.listImage?.length === 1) {
				prev.style.display = "none"
				next.style.display = "none"
				return
			}

			if (val === 0) {
				prev.style.display = "none"
				next.style.display = "flex"
			} else if (val === this.listImage.length - 1) {
				next.style.display = "none"
				prev.style.display = "flex"
			} else {
				prev.style.display = "flex"
				next.style.display = "flex"
			}
		},
		showCheckbox(val) {
			if (!val) {
				this.files.forEach(el => {
					const file = this.$refs[`file-${el.name}`][0]
					file.checkbox = false
				})
				this.$emit("choose-thumbnail", null)
			}
		},
	},
	methods: {
		onFileChanged(event) {
			const files = event.target.files
			if (!files.length) return
			this.isUpload = true
			let uploadFiles = []
			files.forEach(element => {
				if (!element.type) {
					this.checkFileTypeDigital(element)
				} else {
					const fileTypes =
						this.name === TYPE_UPLOAD.RESULT && this.source === SOURCES.WEB
							? FILE_RESULT_TYPES
							: FILE_TYPES
					if (!fileTypes.includes(element.type)) {
						//show noti errors
						if (this.source === SOURCES.WEB) {
							this.isInvalidFiles = true
							return this.$store.commit("set", ["modalShow", true])
						}
						return
					}
				}
				uploadFiles.push(element)
			})
			this.$emit("total-file", uploadFiles.length + this.files.length)
			this.processLargeArray(uploadFiles)
		},

		checkFileTypeDigital(file) {
			const fileReader = new FileReader()
			fileReader.onloadend = function (e) {
				const arr = new Uint8Array(e.target.result).subarray(0, 4)
				let header = ""
				for (var i = 0; i < arr.length; i++) {
					header += arr[i].toString(16)
				}
				if (!FILE_TYPES_DIGITAL.includes(header)) {
					return this.$store.commit("set", ["modalShow", true])
				}
			}
			fileReader.readAsArrayBuffer(file)
		},
		async handleDelete(index) {
			this.$refs.fileInput.value = null
			this.$emit("handleDeleteFile", index)
		},
		getBg() {
			if (!this.isUpdate) {
				return {
					background: "#f3f3f3",
				}
			}
			return {
				background: "#fff",
			}
		},
		async handleviewImage(file) {
			this.listImage = this.files.filter(el => this.imageType.includes(el.mimeType))
			this.fileView = file
			this.slide = await this.getIndex()
			this.viewImage = true
			await this.delay(400)
			this.isShowClose = true
		},
		getIndex() {
			return new Promise(resolver => {
				resolver(this.listImage.findIndex(el => el.name === this.fileView.name))
			})
		},
		async handleClose() {
			this.isShowClose = false
			await this.delay(100)
			this.viewImage = false
		},
		delay(ms) {
			return new Promise(resolve => setTimeout(resolve, ms))
		},
		processLargeArray(array) {
			// set this to whatever number of items you can process at once
			var chunk = 5
			var index = 0
			const doChunk = () => {
				let uploadFiles = []
				var cnt = chunk
				while (cnt-- && index < array.length) {
					// process array[index] here
					// generate object urls, push an item to a temp array
					let file = array[index]
					file = {
						mimeType: file.type,
						name: file.name,
						url: URL.createObjectURL(file),
						resizedSmallUrl: URL.createObjectURL(file),
						fileId: index,
						file: file,
					}
					uploadFiles.push(file)
					++index
				}
				if (index < array.length) {
					// set Timeout for async iteration
					setTimeout(doChunk, 800)
				}
				// push vue's state (images)
				this.$emit("handleUploadFiles", uploadFiles)
				this.$refs.fileInput.value = null
			}
			doChunk()
		},
		handleChooseThumbnail(item) {
			if (item) {
				this.files.forEach(el => {
					if (el.name !== item.name) {
						const file = this.$refs[`file-${el.name}`][0]
						file.checkbox = false
					}
				})
				this.$emit("choose-thumbnail", item)
			} else {
				this.$emit("choose-thumbnail", null)
			}
		},
		confirm() {
			this.isInvalidFiles = false
			return this.$store.commit("set", ["modalShow", false])
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.remove-resize {
	resize: none;
}
._btn {
	width: 92px;
	height: 28px;
	background: $green-1;
	border-radius: 0.25rem;
	font-weight: bold;
	font-size: 14px;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	cursor: pointer;
	margin-bottom: 34px;
}

.upload__files {
	min-height: 190px;
	width: 100%;
	border: 1px solid $grey-3;
	box-sizing: border-box;
	border-radius: 0.25rem;
	padding: 20px 22px;
	display: flex;
	flex-wrap: wrap;
	max-height: 400px;
	overflow-y: auto;
}
.custom__form__control {
	&:disabled {
		background: none !important;
		cursor: auto;
	}
}
.disable:disabled {
	background: $grey-5 !important;
}

.icon--close {
	position: fixed;
	top: 20px;
	right: 20px;
	fill: $white;
	width: 2em;
	height: 2em;
	cursor: pointer;
}
</style>
