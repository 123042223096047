import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	TypesOrderTitle: {
		TypesOrderManagement: "Order Types",
		TypeOrderList: "List Order Type",
		CreateType: "Create Order Type",
		NewOrderType: "New Order Type",
		TypeOrderDetail: "Order Type Detail",
		Detail: "Detail",
		PricingTable: "Pricing table",
	},
	TypeFieldTable: {
		type: "Type",
		code: "Display code",
		createdDate: "Created date",
		actions: "Actions",
		basic: "Basic",
		standard: "Standard",
		premium: "Premium",
		custom: "Custom",
		amount: "Amount",
		timeDelivery: "Time of delivery (day)",
		price: "Price($)",
		customDesciption: "Enter an example number",
		pricePerImage: "Per image",
	},
	TypeFormFields: {
		type: "Order type",
		code: "Display code",
		needLibrary: "Need library",
		description: "Description",
		displayPrice: "Display price",
		displayPhoto: "Display photo",
		pricingTable: "Pricing table",
		descriptionPhoto: "You should photo in 370px x 280px",
		unitAmount: "Unit amount",
	},
	TypesFilter: {
		search: "Type, Display code",
		createdDate: "Created date",
	},
	TypeButtons: {
		create: "New order type",
		delete: "Yes",
	},
	fields: {
		...{
			order_type: "Order Type",
			ID: "ID",
			description: "description",
			price: "price",
			photo: "photo",
			order_unit_amount: "unit amount",
		},
	},
	messages: {
		delete: {
			header: " Delete order type",
			body: "Are you sure you want to delete this order type?",
		},
		invalidSize: "You should upload photo in 370px x 280px",
		invalidFileType: "You are only allowed to select file in PNG, JPG, JPEG.",
	},

	validation: {
		...en.messages,
		...{
			// custom message rules
			requiredExport: "Required information",
			fileUpload: "You should upload photo in 370px x 280px",
			fileType: "You are only allowed to select file in PNG, JPG, JPEG.",
			valueEqualZero: "Entered value can not be 0",
		},
	},
})
