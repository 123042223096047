import { CHANGE_STATUS, GET_USERS_APP, GET_USER_APP, UPDATE_USER_APP } from "./action-types"
import {
	GET_USERS_APP_FAILED,
	GET_USERS_APP_SUCCESS,
	CHANGE_STATUS_SUCCESS,
	GET_USER_APP_SUCCESS,
	GET_USER_APP_FAILED,
	UPDATE_USER_APP_SUCCESS,
} from "./mutation-types"
import API from "../services"
import router from "@/router"
export default {
	[GET_USERS_APP]: async ({ commit }, params) => {
		try {
			const res = await API.getUsers(params)
			commit(GET_USERS_APP_SUCCESS, { ...res, filters: params })
		} catch (error) {
			commit(GET_USERS_APP_FAILED)
		}
	},
	[CHANGE_STATUS]: async ({ commit }, params) => {
		try {
			await API.changeStatus(params)
			commit(CHANGE_STATUS_SUCCESS)
		} catch (error) {}
	},
	[GET_USER_APP]: async ({ commit }, params) => {
		try {
			const res = await API.getUser({ ...params })
			commit(GET_USER_APP_SUCCESS, res)
		} catch (error) {
			commit(GET_USER_APP_FAILED)
		}
	},
	[UPDATE_USER_APP]: async ({ commit }, params) => {
		try {
			await API.updateUser(params)
			commit(UPDATE_USER_APP_SUCCESS)
			router.push("/user-app")
		} catch (error) {}
	},
}
