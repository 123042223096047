import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	AppTitle: {
		Dashboard: "Dashboard",
		Page404: "Page404",
		Page500: "Page500",
		FormRequest: "Form Request",
		Notice: "Notice",
	},
	Header: {
		Account: "Account",
		Setting: "Setting",
		Info: "My info",
		ChangePwd: "Change Password",
		Logout: "Logout",
		Notification: "Notification",
	},
	Button: {
		Update: "Update",
		Delete: "Delete",
		Cancel: "Cancel",
		Submit: "Submit",
		Noti: "Notification",
		OK: "OK",
		Save: "Save",
		Abort: "Abort",
		Confirm: "Confirm",
		Yes: "Yes",
		No: "No",
		CreateOrder: "Create order",
		Sure: "Sure",
		Close: "Close",
		Create: "Create",
		OrderId: "Order ID",
		OrderTitle: "Order title",
		CancelForm: "Cancel form",
		Back: "Back",
		Sync: "Sync to google drive",
		Edit: "Edit",
		Translate: "Translate",
		Upload: "Upload",
		Send: "Send",
		AddUpdate: "Add an update",
	},
	DropDown: {
		Province: "Province",
		District: "District",
		Status: "Status",
		Role: "Vai trò",
		CSIN: "C-SIN",
		Search: "Search",
		Create: "Create new",
		Export: "Export excel",
		Import: "Import file",
		NoResult: "No data to show.",
		NoOptions: "List is empty",
		Type: "Type",
		Sort: "Sort By",
		Hight_light: "Highlight",
		Loading: "Loading...",
		White: "White",
		Yellow: "Yellow",
		Green: "Green",
		Red: "Red",
		CreatedDate: "Created date",
		Customer: "Customer name",
		Editor: "Editor",
	},
	Tabs: {
		RequestList: "Request List",
		AccountWaiting: "Account waiting",
	},
	Roles: {
		SuperAdmin: "Super Admin",
		Admin: "Admin",
	},
	Status: {
		Active: "Active",
		Inactive: "Inactive",
		Expired: "Expired",
		Pending: "Pending",
	},
	Table: {
		NoData: "No data to show",
		Total: "Total {totals} record",
		Totals: "Total {totals} records",
		TotalEditorWage: "Total editor wage",
		TotalRevenue: "Total revenue",
		TotalAmount: "Total",
	},
	Action: {
		Save: "Save",
		Cancel: "Cancel",
		Update: "Update",
		Create: "Create",
		ListShop: "List Shop",
		Pending: "Pending",
		Edit: "Edit",
		Clear: "Clear",
		Delete: "Delete",
		Detail: "Detail",
		Accept: "Accept",
		Deny: "Deny",
		Ok: "Accept",
		No: "Cancel",
		Active: "Active",
		Inactive: "Inactive",
		Add: "Add",
	},

	Sidebar: {
		FormManagement: "Forms",
		OrderManagement: "Orders",
		UserManagement: "Users",
		UserAppManagement: "Web app users",
		CustomerManagement: "Customers",
		TypeOrderManagement: "Order types",
		EditorManagement: "Editors",
		EmailManagement: "Email templates",
		PaysheetManagement: "Paysheets",
		CustomerBillManagement: "Customer bill",
		EditorPayrollManagement: "Editor payroll",
		TransactionManagement: "Transaction",
	},
	ReceivingForm: {
		Header: "Submit a request form",
		Email: "Email",
		Type: "Type",
		Description: "Description",
		Upload: "Upload file",
		linkDropbox: "Link dropbox",
		linkDrive: "Link drive",
		Title: "Title",
		TitleLink: "Please make sure all your links are in public status or downloadable",
	},

	WarrantiesHistory: {
		Title: "Warranties History",
		No: "No.",
		ProductId: "Product Code",
		Model: "Model",
		CSin: "C-SIN",
		Ticket: "Ticket",
		TicketID: "Ticket ID",
		Technical: "Technical",
		CreateAt: "Create At",
		Status: "Status",
		Actions: "Actions",
		Creator: "Creator",
	},

	WarrantiesStatus: {
		New: "New",
		Assign: "Assign",
		Confirm: "Confirm",
		InProgress: "In Progress",
		Done: "Done",
		Feedback: "Feedback",
		Canceled: "Canceled",
	},
	Label: {
		status: "Status",
		source: "Source",
		from: "From",
		to: "To",
		DateFormat: "dd-mm-yyyy",
		search: "Search",
		sort: "Sort By",
		role: "Role",
		created: "Created date",
		paysheet: "Paysheet",
		actualPrice: "Missing actual price",
		amount: "Full amount",
		customer: "Customer",
		price: "Price",
	},
	fields: {
		title: "title",
		email: "email",
		type: "type",
		description: "description",
		files: "upload files",
		linkDropbox: "link dropbox",
		linkDrive: "link drive",
	},
	messages: {
		upload: {
			header: "Notice",
			body:
				"Uploading file must be in RAR, ZIP, JPG, PDF, JPEG, DOC, DOCX, XLSX, PNG, PSD, MAX forrmat. Please try again.",
			progress: "Uploading...",
		},
		result: "You are only allowed to select file in PNG, JPG, JPEG.",
		formRequest:
			"Your form has been submitted successfully, we will process your request as soon as possible!",
		duplicate: {
			left: "The file named",
			right: "has already existed in this list. Please select another file.",
		},
		url: "Please enter a valid link.",
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			isUrl: "Please enter a valid link.",
			isNumber: "Invalid information. Please input a number.",
			isCurrency: "Invalid information. Please input a number.",
		},
	},
})
