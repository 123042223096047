export default {
	editors: state => {
		const newEditor = [
			{
				type: "Select all",
				options: [...state.editors],
			},
		]
		return newEditor
	},
}
