export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAILED = "CREATE_USER_FAILED"

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED"

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAILED = "GET_USERS_FAILED"

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"

export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILED = "GET_USER_FAILED"

export const RESET_FILTERS_USER_LIST = "RESET_FILTERS_USER_LIST"
