import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
import API from "./services"
import { isArray } from "lodash-es"
import { PARAMS, STATUS_FORM } from "@/shared/plugins/constants"
import router from "../router"

const ls = new SecureLS({ isCompression: false })

// import the auto exporter
import modules from "./modules"

Vue.use(Vuex)

const state = {
	sidebarShow: "responsive",
	sidebarMinimize: false,
	modalShow: false,
	isSuccess: false,
	message: null,
	isShowMessage: false,
	isUploadProgress: false,
	loaded: 0,
	pageChanged: null,
	totalFiles: null,
	notifications: [],
	countUnread: 0,
	notifyShow: false,
}

const mutations = {
	toggleSidebarDesktop(state) {
		const sidebarOpened = [true, "responsive"].includes(state.sidebarShow)
		state.sidebarShow = sidebarOpened ? false : "responsive"
	},
	toggleSidebarMobile(state) {
		const sidebarClosed = [false, "responsive"].includes(state.sidebarShow)
		state.sidebarShow = sidebarClosed ? true : "responsive"
	},
	set(state, [variable, value]) {
		state[variable] = value
	},
	showMessageSuccess(state, payload) {
		state.message = payload.message
		state.isShowMessage = payload.show
	},
	uploadFileSuccess() {},
	getNotificationsSuccess(state, payload) {
		state.notifications = [...state.notifications, ...payload]
	},
	getNotificationsFailed(state) {
		state.notifications = []
	},
	getCountUnreadSuccess(state, payload) {
		state.countUnread = payload
	},
	getCountUnreadFailed(state) {
		state.countUnread = 0
	},
	readNotiSuccess() {},
	readNotiFailed() {},
	handleNotify(state, payload) {
		state.notifyShow = payload
	},
	unreadNotiSuccess(state, payload) {
		state.notifications = payload
	},
}
const actions = {
	async uploadFile({ commit }, params) {
		try {
			let res = null
			if (isArray(params)) {
				const totalFiles = await getTotalFileSize(params)
				commit("set", ["totalFiles", totalFiles])
				res = await handleUploadFiles(params)
			} else {
				const formData = new FormData()
				formData.append("files", params)
				res = await API.uploadFile(formData)
			}
			commit("uploadFileSuccess")
			return {
				ok: true,
				tokens: res,
			}
		} catch (error) {
			return {
				ok: false,
			}
		}
	},
	async submitFormRequest({ commit }, params) {
		try {
			await API.submitFormRequest(params)
		} catch (error) {
			commit("set", ["isSuccess", false])
			commit("set", ["modalShow", false])
		}
	},
	showMessage({ commit }, params) {
		commit("showMessageSuccess", params)
		commit("set", ["modalShow", true])
	},
	async getNotifications({ commit }, params) {
		try {
			const res = await API.getNotifications(params)
			commit("getNotificationsSuccess", res)
			return res
		} catch (error) {
			commit("getNotificationsFailed")
			return false
		}
	},
	readNotifications: async () => {
		try {
			await API.readNotifications()
		} catch (error) {}
	},
	getCountUnread: async ({ commit }) => {
		try {
			const res = await API.getCountUnread()
			commit("getCountUnreadSuccess", res)
		} catch (error) {
			commit("getCountUnreadFailed")
		}
	},
	readNotiSync: async ({ commit, dispatch }, params) => {
		try {
			await API.readNotiSync(params)
			commit("readNotiSuccess")
			await dispatch("getCountUnread")
			return true
		} catch (error) {
			commit("readNotiFailed")
			return false
		}
	},
	unreadNotify: async ({ commit, state, dispatch }, params) => {
		try {
			await API.unreadNotiSync(params)
			await dispatch("getCountUnread")
			const newNotifications = [...state.notifications]
			const notiUnread = newNotifications.find(el => el.id === params.id)
			notiUnread.isRead = false
			commit("unreadNotiSuccess", newNotifications)
			return newNotifications
		} catch (error) {}
	},
	redirectNoti: async ({ dispatch }, noti) => {
		try {
			if (!noti.data) {
				noti = await API.getOneNotification(noti)
			}

			if (!noti.isRead) {
				await dispatch("readNotiSync", { id: noti.id })
			}

			if (noti.data.originalOrderId) {
				return router
					.push(`/orders/web/${noti.data.originalOrderId}/detail`)
					.catch(() => router.go())
			}

			const formStatus = await API.getFormStatus({
				type: "ADMIN",
				formId: noti.data.formId,
			})

			const statusFormUpdate = [STATUS_FORM.UNREVIEW, STATUS_FORM.REQUESTED, STATUS_FORM.RESPONDED]

			if (statusFormUpdate.includes(formStatus)) {
				return router
					.push({
						path: `/forms/web/${noti.data.formId}/update`,
						query: {
							limit: PARAMS.LIMIT,
							offset: PARAMS.OFFSET,
						},
					})
					.catch(() => router.go())
			}

			return router.push(`/forms/web/${noti.data.formId}`).catch(() => router.go())
		} catch (error) {}
	},
}

const handleUploadFiles = files => {
	return new Promise(resolve => {
		var chunk = 1
		var index = 0
		let tokens = []
		const doChunk = async () => {
			var cnt = chunk
			while (cnt-- && index < files.length) {
				let formData = new FormData()
				// process array[index] here
				// generate object urls, push an item to a temp array
				let file = files[index]
				formData.append("files", file)
				const res = await API.uploadFile(formData)
				tokens.push(res[0])
				++index
			}
			if (index < files.length) {
				// set Timeout for async iteration
				setTimeout(doChunk, 800)
			}
			// push vue's state (images)
			if (tokens.length == files.length) {
				resolve(tokens)
			}
		}
		doChunk()
	})
}

const getTotalFileSize = array => {
	let total = null
	array.forEach(el => {
		total = total + el.size
	})
	return total
}

const store = new Vuex.Store({
	state,
	mutations,
	actions,
	modules,
	plugins: [
		createPersistedState({
			paths: ["authentication"],
			storage: {
				getItem: key => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: key => ls.remove(key),
			},
		}),
	],
})

export default store
