import i18n from "@/shared/plugins/i18n"

i18n.mergeLocaleMessage("vi", {
	AppTitle: {
		Dashboard: "Dashboard",
		Page404: "Page404",
		Page500: "Page500",
	},
	Header: {
		Account: "Tài khoản",
		Setting: "Cài đặt",
		Info: "Thông tin cá nhân",
		ChangePwd: "Thay đổi mật khẩu",
		Logout: "Đăng xuất",
		Notification: "Thông báo",
	},
	Button: {
		Update: "Chỉnh sửa",
		Delete: "Xóa",
		Cancel: "Hủy bỏ",
		Submit: "Đồng ý",
		Noti: "Thông báo",
		OK: "OK",
		Save: "Lưu",
		Abort: "Hủy",
		Confirm: "Xác nhận",
		Yes: "Có",
		No: "Không",
	},
	DropDown: {
		Province: "Tỉnh/ Thành phố",
		District: "Quận/ Huyện",
		Status: "Trạng thái",
		Role: "Vai trò",
		CSIN: "C-SIN",
		Search: "Tìm kiếm",
		Create: "Tạo mới",
		Export: "Export link",
		Import: "Import file",
		NoResult: "Không có dữ liệu",
		NoOptions: "Danh sách trống",
	},
	Tabs: {
		RequestList: "Danh sách yêu cầu",
		AccountWaiting: "Tài khoản chờ",
	},
	Roles: {
		SuperAdmin: "Super Admin",
		Admin: "Admin",
	},
	Status: {
		Active: "Active",
		Inactive: "Inactive",
		Expired: "Expired",
		Pending: "Pending",
	},
	Table: {
		NoData: "Không có dữ liệu",
		Total: "Tổng {totals} bản ghi",
	},
	Action: {
		Save: "Lưu",
		Cancel: "Hủy bỏ",
		Update: "Cập nhật",
		Create: "Tạo mới",
		ListShop: "Danh sách Shop",
		Pending: "Danh sách Pending",
		Edit: "Chỉnh sửa",
		Clear: "Xóa",
		Delete: "Xóa",
		Detail: "Chi tiết",
		Accept: "Đồng ý",
		Deny: "Từ chối",
		Ok: "Đồng ý",
		No: "Hủy bỏ",
	},

	Sidebar: {
		AdminManagement: "Quản lý Admin",
		ShopManagement: "Quản lý Shop",
		ProductManagement: "Quản lý Sản phẩm",
		UserManagement: "Quản lý Người dùng",
		Ticketmanagement: "Quản lý yêu cầu",
	},

	WarrantiesHistory: {
		Title: "Lịch sử bảo hành",
		No: "No.",
		ProductId: "Mã sản phẩm",
		Model: "Model",
		CSin: "C-SIN",
		Ticket: "Ticket",
		TicketID: "Ticket ID",
		Technical: "Kỹ thuật viên",
		CreateAt: "Ngày tạo",
		Status: "Trạng thái",
		Actions: "Actions",
		Creator: "Người tạo",
	},

	WarrantiesStatus: {
		New: "Mới",
		Assign: "Đã giao",
		Confirm: "Đã xác nhận",
		InProgress: "Đang xử lý",
		Done: "Hoàn thành",
		Feedback: "Đã từ chối",
		Canceled: "Hủy",
	},
})
