import { callAPI } from "@/shared/services"

const getFileName = string => {
	if (!string) return

	return string.split("; ")[1].split("filename=")[1]
}

export default {
	getEditorPayroll: params => {
		return callAPI("GET", "editor/payroll", {
			params,
			notLoading: params.notLoading || false,
		}).then(res => res.data)
	},
	exportEditorPayroll: params => {
		return callAPI("GET", "editor/payroll/export", { params, responseType: "blob" }).then(res => {
			const url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", `${getFileName(res.headers["content-disposition"])}`)
			document.body.appendChild(link)
			link.click()
			return res.data
		})
	},
	addEditorOfPayroll: params => {
		return callAPI("POST", "editor/editor-of-payroll", params).then(res => res.data)
	},
	updateEditorOfPayroll: params => {
		return callAPI("PUT", "editor/editor-of-payroll", params).then(res => res.data)
	},
	getEditorOfPayrollDetail: params => {
		return callAPI("GET", "editor/detail-editor-of-payroll", { params }).then(res => res.data)
	},
	saveNote: params => {
		return callAPI("PUT", "editor/payroll/note", params, { notLoading: true }).then(res => res.data)
	},
}
