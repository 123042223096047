import { PARAMS } from "@/shared/plugins/constants"
import {
	CANCEL_FORM_FAILED,
	CANCEL_FORM_SUCCESS,
	GET_FORMS_FAILED,
	GET_FORMS_SUCCESS,
	GET_FORM_DETAIL_FAILED,
	GET_FORM_DETAIL_SUCCESS,
	GET_FORM_WEB_DETAIL_FAILED,
	GET_FORM_WEB_DETAIL_SUCCESS,
	GET_REQUEST_LIST_FAILED,
	GET_REQUEST_LIST_SUCCESS,
	LOCK_FORM_FAILED,
	LOCK_FORM_SUCCESS,
	RESET_FILTERS_FORM_LIST,
	RESET_FORM_DETAIL,
	SET,
	UNLOCK_FORM_SUCCESS,
	UPDATE_FORM_FAILED,
	UPDATE_FORM_SUCCESS,
} from "./mutation-types"

export default {
	[GET_FORMS_SUCCESS]: function (state, payload) {
		state.forms = payload.forms
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},
	[GET_FORMS_FAILED]: state => {
		state.typesOrder = null
		state.totalItems = null
		state.totalPages = null
	},
	[GET_REQUEST_LIST_SUCCESS]: function (state, payload) {
		state.canCreate = payload.canCreate
		state.canCancel = payload.canCancel
		state.totalRequest = payload.total
		state.requestOpen = payload.requestOpen
		state.requests = payload.requests
	},
	[GET_REQUEST_LIST_FAILED]: state => {
		state.requests = []
		state.canCreate = null
		state.canCancel = null
		state.totalRequest = null
		state.requestOpen = null
	},
	[GET_FORM_DETAIL_SUCCESS]: function (state, payload) {
		state.formDetail = payload
		state.formUpdateErr = null
	},
	[GET_FORM_DETAIL_FAILED]: state => {
		state.formDetail = null
	},
	[GET_FORM_WEB_DETAIL_SUCCESS]: function (state, payload) {
		state.formDetail = payload
	},
	[GET_FORM_WEB_DETAIL_FAILED]: state => {
		state.formDetail = null
	},
	[UPDATE_FORM_SUCCESS]: function (state) {
		state.isUpdateSuccess = true
	},
	[UPDATE_FORM_FAILED]: state => {
		state.isUpdateSuccess = false
	},
	[CANCEL_FORM_SUCCESS]: function (state) {
		state.formDetail = null
	},
	[CANCEL_FORM_FAILED]: state => {
		state.formDetail = null
	},
	[RESET_FILTERS_FORM_LIST]: function (state) {
		state.forms = []
		state.totalItems = null
		state.totalPages = null
		state.formDetail = null
		state.recentFilters = {
			from: null,
			to: null,
			source: null,
			status: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.originalId = null
		state.lockError = null
		state.requests = []
		state.canCreate = null
		state.canCancel = null
		state.totalRequest = null
		state.requestOpen = null
	},
	[RESET_FORM_DETAIL]: function (state) {
		state.formDetail = null
	},
	[SET]: (state, [variable, value]) => {
		state[variable] = value
	},
	[UNLOCK_FORM_SUCCESS]: state => {
		state.lockError = null
	},
	[LOCK_FORM_SUCCESS]: state => {
		state.lockError = null
	},
	[LOCK_FORM_FAILED]: (state, payload) => {
		state.lockError = payload
	},
}
