import {
	CAN_LOGIN,
	CHANGE_PASSWORD_FAILED,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_STATUS_CHANGE_PASSWORD_MODAL,
	CHANGE_STATUS_LOGOUT_MODAL,
	FORGOT_PASSWORD_FAILED,
	FORGOT_PASSWORD_SUCCESS,
	GET_USER_LOGIN_PROFILE_SUCCESS,
	LOGIN_FAILED,
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	REFRESH_TOKEN_ERROR,
	REFRESH_TOKEN_SUCCESS,
	RESET_PASSWORD_FAILED,
	RESET_PASSWORD_SUCCESS,
	SEND_OTP_FAILED,
	SEND_OTP_SUCCESS,
	UPDATE_USER_LOGIN_PROFILE_FAILED,
	UPDATE_USER_LOGIN_PROFILE_SUCCESS,
	VERIFY_OTP_SUCCESS,
} from "./mutation-types"

export default {
	[LOGIN_SUCCESS]: function (state, payload = {}) {
		state.accessToken = payload.accessToken
		state.refreshToken = payload.refreshToken
		state.loginErr = null
	},

	[LOGIN_FAILED]: function (state, payload = {}) {
		state.loginErr = payload
		state.accessToken = null
		state.refreshToken = null
		state.canLogin = false
	},

	[LOGOUT_SUCCESS]: function (state) {
		state.currentUser = null
		state.accessToken = null
		state.refreshToken = null
		state.canLogin = false
		state.firebaseToken = false
		localStorage.clear()
	},

	[FORGOT_PASSWORD_SUCCESS]: function (state) {
		state.forgotErr = null
	},

	[FORGOT_PASSWORD_FAILED]: function (state, payload = {}) {
		state.forgotErr = payload
	},

	[RESET_PASSWORD_SUCCESS]: function (state) {
		state.email = null
		state.verifyKey = null
	},

	[RESET_PASSWORD_FAILED]: function (state, payload = {}) {
		state.resetPwdErr = payload
		state.email = null
		state.verifyKey = null
	},

	[CHANGE_PASSWORD_SUCCESS]: function (state, payload = {}) {
		state.currentUser = payload
		state.changePwdErr = null
	},

	[CHANGE_PASSWORD_FAILED]: function (state, payload = {}) {
		state.changePwdErr = payload
	},

	[SEND_OTP_SUCCESS]: function (state, payload = {}) {
		state.email = payload.email
	},

	[SEND_OTP_FAILED]: function (state) {
		state.email = null
	},

	[VERIFY_OTP_SUCCESS]: function (state, payload = {}) {
		state.verifyKey = payload
	},

	[SEND_OTP_FAILED]: function (state) {
		state.verifyKey = null
	},

	[REFRESH_TOKEN_SUCCESS]: function (state, payload = {}) {
		state.accessToken = payload.accessToken
		state.refreshToken = payload.refreshToken
	},

	[REFRESH_TOKEN_ERROR]: function (state) {
		state.currentUser = null
		state.accessToken = null
		state.refreshToken = null
	},

	[GET_USER_LOGIN_PROFILE_SUCCESS]: function (state, payload = {}) {
		state.currentUser = payload
	},

	[UPDATE_USER_LOGIN_PROFILE_SUCCESS]: function (state, payload = {}) {
		state.currentUser = payload
		state.profileErr = null
	},

	[UPDATE_USER_LOGIN_PROFILE_FAILED]: function (state, payload = {}) {
		state.profileErr = payload
	},

	[CHANGE_STATUS_LOGOUT_MODAL]: function (state, payload = {}) {
		state.showLogoutModal = payload
	},

	[CHANGE_STATUS_CHANGE_PASSWORD_MODAL]: function (state, payload = {}) {
		state.showChangePwd = payload
	},
	[CAN_LOGIN]: function (state) {
		state.canLogin = true
	},
}
