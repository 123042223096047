import { callAPI } from "@/shared/services"
export default {
	changeStatus: params => {
		return callAPI("PUT", "users/change-status", params).then(res => res.data)
	},
	updateUser: params => {
		return callAPI("PUT", "users/members", params).then(res => res.data)
	},
	getUsers: params => {
		return callAPI("GET", "users/members", { params }).then(res => res.data)
	},
	getUser: params => {
		return callAPI("GET", `users/members/detail`, { params }).then(res => res.data)
	},
}
