export const REGEX = {
	IS_NUMBER: /^[0-9]*$/,
	IS_EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
	IS_PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{1,6})$/,
	IS_CURRENCY: /^[0-9,.]*$/,
}

export const USER_ROLE = {
	SUPER_ADMIN: "SUPER_ADMIN",
	QA: "QA",
	ADMIN: "ADMIN",
	USER_MEMBER: "USER_MEMBER",
}

// export const USER_STATUS = {
// 	ACTIVE: true,
// 	INACTIVE: false,
// }

export const PRODUCT_STATUS = {
	ACTIVE: {
		TEXT: "Active",
		VALUE: 1,
	},
	INACTIVE: {
		TEXT: "Inactive",
		VALUE: 2,
	},
	EXPIRED: {
		TEXT: "Expired",
		VALUE: 13,
	},
}

export const WARRANTY_STATUS = {
	UNWARRANTED: {
		TEXT: "Unwarranted",
		VALUE: 10,
	},
	WARRANTING: {
		TEXT: "Warranting",
		VALUE: 11,
	},
	WARRANTED: {
		TEXT: "Warranted",
		VALUE: 12,
	},
	EXPIRED: {
		TEXT: "Expired",
		VALUE: 13,
	},
}

export const ADMIN_ROLE = {
	SUPER_ADMIN: 1,
	ADMIN: 2,
}

export const GENDER = {
	MALE: 1,
	FEMALE: 2,
}

export const PREFIX_MODULES = {
	ADMIN: "admin_",
	SHOP: "shop_",
	CUSTOMER: "customer_",
}

export const WARRANTIES_STATUS = {
	NEW: 1,
	ASSIGNED: 2,
	CONFIRMED: 3,
	IN_PROGRESS: 4,
	DONE: 5,
	FEEDBACK: 6,
	CANCELED: 7,
}

export const SHOP_STATUS = {
	ACTIVE: 1,
	INACTIVE: 2,
	PENDING: 3,
}

export const TICKET_TABS = {
	NEW: 1,
	INPROGRESS: 2,
	DONE: 3,
	CANCEL: 4,
}

export const TICKET_STATUS = {
	NEW: 1,
	ASSIGNED: 2,
	CONFIRMED: 3,
	IN_PROGRESS: 4,
	DONE: 5,
	FEEDBACK: 6,
	CANCELED: 7,
}

export const IMPORT_STATUS = {
	// Status of Validation
	PENDING: "PENDING",
	VALIDATE_ON_PROGRESS: "VALIDATE_ON_PROGRESS",
	VALIDATE_ERROR: "VALIDATE_ERROR",
	VALIDATE_DONE: "VALIDATE_DONE",
	// Status of Importion
	IMPORT_ON_PROGRESS: "IMPORT_ON_PROGRESS",
	DONE: "DONE",
	// Status of cancelled
	CANCELLED: "CANCELLED",
}

export const TRUNCATED_TEXT_LENGTH = {
	DEFAULT: 50,
	EMAIL: 10,
	FULLNAME: 20,
}

export const ORDER_TICKET = {
	DESC: "desc",
	ESC: "esc",
}

export const TICKET_HISTORY_ACTIONS = {
	CREATE_TICKET: "CREATE_TICKET",
	UPDATE_TICKET: "UPDATE_TICKET",
	STATUS: "STATUS",
	ASSIGNED_TO_ID: "ASSIGNED_TO_ID",
	TECHNICIAN_ID: "TECHNICIAN_ID",
	TITLE: "TITLE",
	DESCRIPTION: "DESCRIPTION",
	PHONE: "PHONE",
	PROVINCE: "PROVINCE",
	DISTRICT: "DISTRICT",
	ADDRESS: "ADDRESS",
	EMAIL: "EMAIL",
	CUSTOMER_NAME: "CUSTOMER_NAME",
	PRODUCT_ID: "PRODUCT_ID",
}
export const NOTIFICATION_TYPE = {
	SHOP_PENDING: 1,
	TICKET_UPDATE: 2,
	TICKET_COMMENT: 3,
}
export const PARAMS = {
	LIMIT: 10,
	OFFSET: 0,
}
export const FILE_TYPES = [
	"image/jpeg",
	"image/vnd.adobe.photoshop",
	".max",
	"image/png",
	"image/jpg",
	"application/pdf",
	"application/x-zip-compressed",
	"application/msword",
	"application/vnd.ms-excel", //xls
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //xlsx
	"application/zip", //zip
	"application/x-zip-compressed", // zip-window
	"application/vnd.rar",
]

export const FILE_RESULT_TYPES = ["image/jpeg", "image/png", "image/jpg"]

export const AUTH_ROLE = {
	EMAIL: 50,
	PASSWORD: 6,
	OTP: 4,
}

export const AUTH_TYPE = {
	SIGN_IN: "SIGN_IN",
	SIGN_UP: "SIGN_UP",
	UPDATE: "UPDATE",
	RESET_PASSWORD: "RESET_PASSWORD",
}

export const USER_MANAGEMENT_ROLE = {
	NAME: 100,
	PHONE: 15,
	EMAIL: 50,
	RULE: 1000,
}

export const ORDER_TYPE_ROLE = {
	ORDER_TYPE: 35,
	ID: 5,
	DESCRIPTION: 125,
	PRICE: 5,
	PRICING: {
		AMOUNT: 3,
		TIME_DELIVERY: 2,
		PRICE: 6,
		PER_IMAGE: 6,
	},
	UNIT_AMOUNT: 50,
}

export const FILE_TYPES_DIGITAL = ["38425053", "52617221", "d0cf11e0"]

export const STATUS_FORM = {
	UNREVIEW: "UNREVIEW",
	REVIEWED: "REVIEWED",
	CANCELLED: "CANCELLED",
	RESPONDED: "RESPONDED",
	REQUESTED: "REQUESTED",
}

export const SOURCES = {
	MAIL: "MAIL",
	FORM: "FORM",
	WEB: "WEB",
}

export const FORM_RULES = {
	EMAIL: 50,
	CUSTOMER_NAME: 100,
	CUSTOMER_ID: 10,
	TITLE: 1000,
	DESCRIPTION: 5000,
}

export const DATE_FORMAT = "DD-MM-YYYY HH:mm"

export const DATE_VN = "DD-MM-YYYY"

export const FORM_TYPE_CLICK = {
	CREATE: "CREATE",
	UPDATE: "UPDATE",
	SAVE: "SAVE",
	BACK: "BACK",
}

export const ORDER_SORT = {
	DEADLINE: "DEADLINE",
	UPDATED_AT: "UPDATED_AT",
	CREATED_AT: "CREATED_AT",
	YES: "YES",
	NO: "NO",
}

export const ORDER_STATUS = {
	COMPLETED: "COMPLETED",
	NEW_JOB: "NEW_JOB",
	REVISION: "REVISION",
	MORE_NEW_JOB: "MORE_NEW_JOB",
	REVISION_MORE_NEW_JOB: "REVISION_MORE_NEW_JOB",
	CANCELLED: "CANCELLED",
}

export const ORDER_RULE = {
	EMAIL: 50,
	TITLE: 1000,
	DESCRIPTION: 5000,
	AMOUNT: 10,
	RULE: 5000,
	EDITOR: 20,
	NOTE: 500,
}
export const CUSTOMER_RULE = {
	ID: 10,
	NAME: 100,
	EMAIL: 50,
	RULE: 1000,
	AMOUNT: 10,
}

export const TEAMPALTE_RULE = {
	TITLE: 1000,
	DESCRIPTION: 5000,
}
export const CANCEL_REASON = 200

export const FORM_REQUEST = {
	EMAIL: 50,
	TITLE: 1000,
	DESCRIPTION: 5000,
}
export const EXPORT_RULE = {
	ADMIN: "ADMIN",
	QA: "QA",
	EDITOR: "EDITOR",
}

export const EXPORT_TO = {
	LOCAL: "LOCAL",
	DRIVE: "DRIVE",
}

export const USER_STATUS = {
	ACTIVE: "ACTIVE",
	DEACTIVATE: "DEACTIVATE",
}

export const COLOR_SCHEMA = {
	WHITE: "#ffffff4d",
	YELLOW: "#ffff004d",
	GREEN: "#40c1204d",
	RED: "#ff00004d",
	GRAY: "#f3f3f3",
}

export const DATE_OPTIONS = {
	TODAY: "TODAY",
	WEEK: "WEEK",
	MONTH: "MONTH",
	YEAR: "YEAR",
}

export const TEXT_INSERT = {
	ORDER_ID: "{{ORDER_ID}}",
	ORDER_TITLE: "{{ORDER_TITLE}}",
}

export const EDITOR_RULE = {
	NAME: 50,
	NICKNAME: 20,
}

export const PAYSHEET_TABLE = {
	WAGE: "WAGE",
	EDITOR_ALL: "EDITOR_ALL",
	EDITOR_SELECTED: "EDITOR_SELECTED",
}

export const PAYSHEET_RULE = {
	NAME: 50,
}

export const AMOUNT_OPTIONS = {
	YES: "YES",
	NO: "NO",
}

export const TABLE_NAME = ["customer-bill", "editor-payroll"]

export const AMOUNT_STEP = 0.5

export const PRICING_OPTIONS = {
	BASIC: "BASIC",
	STANDARD: "STANDARD",
	PREMIUM: "PREMIUM",
}

export const allowedTypes = [
	"image/png", //png
	"image/jpeg", //jpeg, jpg
]

export const FORM_REQUEST_RULES = {
	TIME: 2,
	AMOUNT: 3,
	OFFER_PRICE: 6,
	NOTE: 200,
}

export const FORM_REQUEST_STATUS = {
	OPEN: "OPEN",
	DECLINED: "DECLINED",
	ACCEPTED: "ACCEPTED",
	CANCELED: "CANCELED",
}

export const IGNORE_FORM_STATUS = [STATUS_FORM.CANCELLED, STATUS_FORM.REVIEWED]
export const IGNORE_ORDER_STATUS = [ORDER_STATUS.COMPLETED, ORDER_STATUS.CANCELLED]

export const TYPE_UPLOAD = {
	REQUEST: "REQUEST",
	RESULT: "RESULT",
}
