import { callAPI } from "@/shared/services"
export default {
	createUser: params => {
		return callAPI("POST", "users", params).then(res => res.data)
	},
	updateUser: params => {
		return callAPI("PUT", "users", params).then(res => res.data)
	},
	deleteUser: params => {
		return callAPI("PUT", `users/change-status`, params).then(res => res.data)
	},
	getUsers: params => {
		return callAPI("GET", "users", { params }).then(res => res.data)
	},
	getUser: params => {
		return callAPI("GET", "users/profile", { params }).then(res => res.data)
	},
}
