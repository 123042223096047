import { callAPI } from "@/shared/services"

export default {
	getCustomers: params => {
		return callAPI("GET", "customer", { params: params.data, notLoading: params.notLoading }).then(
			res => res.data,
		)
	},
	getCustomer: params => {
		return callAPI("GET", "customer/detail", { params }).then(res => res.data)
	},
	getCustomerPrices: params => {
		return callAPI("GET", "customer/order-types", {
			params: params.data,
			notLoading: params.notLoading,
		}).then(res => res.data)
	},
	searchEmail: params => {
		return callAPI("GET", "customer/searching", {
			params: params.data,
			notLoading: params.notLoading,
		}).then(res => res.data)
	},
	createCustomer: params => {
		return callAPI("POST", "customer", params, { skipToast: true }).then(res => res.data)
	},
	updateCustomer: params => {
		return callAPI("PUT", "customer", params).then(res => res.data)
	},
	deleteCustomer: params => {
		return callAPI("DELETE", `customer/${params.id}`).then(res => res.data)
	},
}
