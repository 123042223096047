import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	OrdersTitle: {
		OrderManagement: "Orders Management",
		OrderList: "List Orders",
		CreateOrder: "Create Order",
		NewOrder: "New Order",
		OrderDetail: "Order Detail",
		Order: "Order",
		Request: "Request",
		Result: "Result",
		UpdateOrder: "Update Order",
		Detail: "detail",
		NewUpdate: "New Update",
		Export: "Export as",
		Admin: "Admin",
		QA: "QA",
		Editor: "Editor",
		MissingActual: "Missing actual price",
	},
	OrderFieldTable: {
		ID: "ID",
		CustomerID: "Customer ID",
		Email: "Email",
		Amount: "Amount",
		DateFormat: "dd/mm/yyyy",
		Status: "Status",
		Actions: "Actions",
		Title: "Title",
		Deadline: "Deadline",
		Editor: "Editor",
		Note: "Note",
		CreatedAt: "Created at",
		CustomerName: "Customer name",
		Type: "Type",
	},
	OrderFilters: {
		searchByAdmin: "Title, email, order ID, description",
		searchByQA: "Order ID, title, description",
		deadline: "Deadline",
	},
	OrderFilterName: {
		deadline: "Deadline",
		updatedTime: "Updated time",
		complete: "Completed",
		cancelled: "Cancelled",
		new_job: "New job",
		revision: "Revision",
		more_new_job: "More new job",
		revision_more_new_job: "Revision + More new job",
		today: "Today",
		week: "This week",
		month: "This month",
		year: "This year",
		yes: "Yes",
		no: "No",
	},
	OrderButtons: {
		create: "New order",
		addUpdate: "Add an update",
	},
	OrderFields: {
		update_id: "Update ID",
		order_id: "Order ID",
		form_id: "Form ID",
		create_date: "Created date",
		received_date: "Received date",
		email: "Email",
		status: "Status",
		customer_name: "Customer name",
		customer_id: "Customer ID",
		title: "Title",
		description: "Description",
		amount: "Amount",
		type: "Type",
		time_to_do: "Time to do (h)",
		deadline: "Deadline",
		rule: "Rule",
		upload: "Upload file",
		file_result: "File result",
		email_template: "Email template",
		email_description: "Email description",
		complete_update: "Complete this update",
		email_to_customer: "Email to customer",
		systemPrice: "System price ($)",
		actualPrice: "Actual price ($)",
		linkDropbox: "Link dropbox",
		linkDrive: "Link drive",
		uploadToServer: "Upload on editor server",
		descriptionTranslate: "Description (translated)",
		phone: "Phone number",
		rate: "Rate",
		thumbnail: "Thumbnail",
		comment: "Comment",
		thumbnail_selected: "Select result thumbnail from result list above",
		link_result: "Link result",
	},
	OrderMessage: {
		delete_update: "This update may be in processing, are you sure want to delete it?",
		delete_order: "This order may be in processing, are you sure want to delete it?",
		valid_date: "Deadline must not be less than current time.",
		received_date: "Received time should not be bigger than current time",
		cancel_order:
			"This is an update is in processing at this moment. Are you sure you want to cancel and manually refund to customer?",
	},
	fields: {
		...{
			order_type: "type",
			order_customer_name: "customer name",
			order_customer_ID: "customer ID",
			order_description: "description",
			order_description_translate: "description translate",
			order_status: "status",
			order_title: "title",
			order_email: "email",
			order_amount: "amount",
			order_time: "time to do",
			order_deadline: "deadline",
			order_rule: "rule",
			order_upload: "upload file",
			order_email_template: "email template",
			order_email_description: "email description",
			order_complete_update: "complete this update",
			order_email_to_customer: "email to customer",
			order_system_price: "system price",
			order_actual_price: "actual price",
			order_dropbox: "link dropbox",
			order_drive: "link drive",
			order_upload_server: "upload on server",
			order_reason: "reason",
			order_thumbnail_selected: "thumbnail",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			requiredExport: "Required information",
			isUrl: "Please enter a valid link.",
		},
	},
})
