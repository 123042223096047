import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	CustomerBillTitle: {
		CustomerBillManagement: "Customer bill",
		CustomerBillList: "List customer bill",
	},
	CustomerBillFieldTable: {
		UpdateID: "Update ID",
		OrderID: "Order ID",
		CustomerName: "Customer name",
		Title: "Title",
		Deadline: "Deadline",
		ReceivedTime: "Received time",
		CompleteDate: "Complete date",
		Amount: "Amount",
		Price: "Price",
	},
	CustomerBillFilters: {
		search: "Update ID, order ID, title",
		created: "Complete date",
	},
	CustomerBillButtons: {},
	validation: {
		...en.messages,
		...{
			// custom message rules
		},
	},
})
