import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"
import { PARAMS } from "@/shared/plugins/constants"

const initialState = {
	selectedEditor: [],
	totalItemsSelected: null,
	totalPagesSelected: null,
	recentFiltersSelected: {
		limit: 1000,
		offset: PARAMS.OFFSET,
	},
	typeOrder: [],
	listEditorSelected: [],
	isChangePageEditorsSelected: false,
	messageErr: null,
	totalItems: null,
	totalPages: null,
	recentFilters: {
		limit: PARAMS.LIMIT,
		offset: PARAMS.OFFSET,
		search: null,
		from: null,
		to: null,
	},
	paysheets: [],
	paysheetName: null,
	paysheetId: null,
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
