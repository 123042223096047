<template>
	<div class="custom-table">
		<CCard>
			<CCardBody>
				<slot name="title" />
				<slot v-if="showFilters" name="filters">
					<CRow class="d-md-flex d-none d-sm-none form-group filter">
						<!-- search -->
						<CCol v-if="showFilterSearch" class="col-md-6 col-sm-12 col-lg pt-20">
							<ValidationObserver ref="form" v-slot="{ handleSubmit }">
								<form @submit.prevent="handleSubmit(searchFilter)">
									<label for="search" class="col-form-label">{{ $t("Label.search") }}</label>
									<div>
										<input
											id="search"
											v-model.trim="search"
											class="form-control custom-input"
											:placeholder="labelSearch"
											type="text"
											autocomplete="search"
											@input="searchDebounce"
										/>
									</div>
								</form>
							</ValidationObserver>
						</CCol>

						<!-- search price -->
						<CCol v-if="showFilterPrice" class="col-md-6 col-sm-12 col-lg pt-20">
							<ValidationObserver ref="form" v-slot="{ handleSubmit }">
								<form @submit.prevent="handleSubmit(searchFilter)">
									<label for="price" class="col-form-label">{{ $t("Label.price") }}</label>
									<div>
										<input
											id="price"
											v-model.trim="price"
											class="form-control custom-input"
											placeholder="price"
											type="text"
											autocomplete="Price"
											@paste="handleOnPaste($event)"
											@keydown="checkValidPhone($event)"
										/>
									</div>
								</form>
							</ValidationObserver>
						</CCol>
						<!-- Select Status -->
						<CCol v-if="showFilterStatus" class="col-md-6 col-sm-12 col-lg pt-20">
							<label for="status" class="col-form-label">{{ $t("Label.status") }}</label>
							<div>
								<multiselect
									id="status"
									v-model="status"
									:options="statuses"
									label="name"
									track-by="value"
									:close-on-select="true"
									:show-labels="false"
									:allow-empty="true"
									:searchable="true"
									:placeholder="$t('Label.status')"
									@select="selectStatus"
									@remove="clearFilter('status')"
								>
									<template #noOptions>
										{{ $t("DropDown.NoOptions") }}
									</template>
									<template #noResult>
										{{ $t("DropDown.NoResult") }}
									</template>
									<template slot="clear">
										<div
											v-if="status"
											class="multiselect__clear"
											@mousedown.prevent.stop="clearFilter('status')"
										>
											<font-awesome-icon :icon="['fas', 'times']" :title="$t('Action.Clear')" />
										</div>
									</template>
								</multiselect>
							</div>
						</CCol>

						<!-- Select Source -->
						<CCol v-if="showFilterSource" class="col-md-6 col-sm-12 col-lg pt-20">
							<label for="source" class="col-form-label">{{ $t("Label.source") }}</label>
							<div>
								<multiselect
									id="source"
									v-model="source"
									label="name"
									track-by="value"
									:options="sources"
									:close-on-select="true"
									:show-labels="false"
									:allow-empty="true"
									:searchable="true"
									:placeholder="$t('Label.source')"
									@select="selectSource"
									@remove="clearFilter('source')"
								>
									<template #noOptions>
										{{ $t("DropDown.NoOptions") }}
									</template>
									<template #noResult>
										{{ $t("DropDown.NoResult") }}
									</template>
									<template slot="clear">
										<div
											v-if="source"
											class="multiselect__clear"
											@mousedown.prevent.stop="clearFilter('source')"
										>
											<font-awesome-icon :icon="['fas', 'times']" :title="$t('Action.Clear')" />
										</div>
									</template>
								</multiselect>
							</div>
						</CCol>

						<!-- Select Type -->
						<CCol v-if="showFilterTypes" class="col-md-6 col-lg pt-20">
							<label for="type" class="col-form-label">{{ $t("DropDown.Type") }}</label>
							<div>
								<multiselect
									id="type"
									v-model="type"
									multiple
									:options="types"
									:close-on-select="false"
									:show-labels="false"
									:allow-empty="true"
									:searchable="false"
									:placeholder="$t('DropDown.Type')"
									label="type"
									track-by="id"
									class="customer-multiselect"
									@select="selectType"
									@remove="clearFilter('type', $event)"
									@close="closeDropDown"
								>
									<template #noOptions>
										{{ $t("DropDown.NoOptions") }}
									</template>
									<template #noResult>
										{{ $t("DropDown.NoResult") }}
									</template>
									<div slot="clear">
										<div
											v-if="type.length"
											class="multiselect__clear"
											@mousedown.prevent.stop="clearSelectMultiple('type')"
										>
											<font-awesome-icon :icon="['fas', 'times']" :title="$t('Action.Clear')" />
										</div>
									</div>
								</multiselect>
							</div>
						</CCol>
						<!-- Select customer -->
						<CCol v-if="showFilterCustomers" class="col-md-6 col-lg pt-20">
							<label for="customer" class="col-form-label">{{ $t("DropDown.Customer") }}</label>
							<div>
								<multiselect
									id="customer"
									v-model="customer"
									:multiple="true"
									:options="customers"
									:close-on-select="false"
									:show-labels="false"
									:allow-empty="true"
									:searchable="true"
									:placeholder="$t('DropDown.Customer')"
									:tag-placeholder="null"
									label="name"
									track-by="id"
									:clear-on-select="true"
									class="customer-multiselect"
									@remove="clearFilter('customer', $event)"
									@select="selectCustomers"
									@close="closeDropDown"
								>
									<template #noOptions>
										{{ $t("DropDown.NoOptions") }}
									</template>
									<template #noResult>
										{{ $t("DropDown.NoResult") }}
									</template>
									<div slot="clear">
										<div
											v-if="customer.length"
											class="multiselect__clear"
											@mousedown.prevent.stop="clearSelectMultiple('customer')"
										>
											<font-awesome-icon :icon="['fas', 'times']" :title="$t('Action.Clear')" />
										</div>
									</div>
								</multiselect>
							</div>
						</CCol>

						<!-- Select actual Price -->
						<CCol v-if="showFilterActualPrice" class="col-md-6 col-sm-12 col-lg pt-20">
							<label for="actualPrice" class="col-form-label">{{ $t("Label.actualPrice") }}</label>
							<div>
								<multiselect
									id="actualPrice"
									v-model="actualPrice"
									:options="actualPrices"
									label="name"
									track-by="value"
									:close-on-select="true"
									:show-labels="false"
									:allow-empty="true"
									:searchable="true"
									:placeholder="$t('Label.actualPrice')"
									@select="selectActualPrice"
									@remove="clearFilter('actual-price')"
								>
									<template #noOptions>
										{{ $t("DropDown.NoOptions") }}
									</template>
									<template #noResult>
										{{ $t("DropDown.NoResult") }}
									</template>
									<template slot="clear">
										<div
											v-if="actualPrice"
											class="multiselect__clear"
											@mousedown.prevent.stop="clearFilter('actual-price')"
										>
											<font-awesome-icon :icon="['fas', 'times']" :title="$t('Action.Clear')" />
										</div>
									</template>
								</multiselect>
							</div>
						</CCol>

						<!-- select editor -->
						<CCol v-if="showFilterEditor" class="col-md-6 col-lg pt-20">
							<label for="editor" class="col-form-label">{{ $t("DropDown.Editor") }}</label>
							<div>
								<multiselect
									id="editor"
									v-model="editor"
									:multiple="true"
									:options="editors"
									:close-on-select="false"
									:show-labels="false"
									:allow-empty="true"
									:searchable="true"
									:placeholder="$t('DropDown.Editor')"
									label="nickname"
									track-by="id"
									group-values="options"
									:group-select="true"
									group-label="type"
									class="customer-multiselect"
									@select="selectEditor"
									@remove="clearFilter('editor', $event)"
									@close="closeDropDown"
								>
									<template #noOptions>
										{{ $t("DropDown.NoOptions") }}
									</template>
									<template #noResult>
										{{ $t("DropDown.NoResult") }}
									</template>
									<div slot="clear">
										<div
											v-if="editor.length"
											class="multiselect__clear"
											@mousedown.prevent.stop="clearSelectMultiple('editor')"
										>
											<font-awesome-icon :icon="['fas', 'times']" :title="$t('Action.Clear')" />
										</div>
									</div>
								</multiselect>
							</div>
						</CCol>

						<!-- Select full amount -->
						<CCol v-if="showFilterAmount" class="col-md-6 col-sm-12 col-lg pt-20">
							<label for="amount" class="col-form-label">{{ $t("Label.amount") }}</label>
							<div>
								<multiselect
									id="amount"
									v-model="amount"
									label="name"
									track-by="value"
									:options="amounts"
									:close-on-select="true"
									:show-labels="false"
									:allow-empty="true"
									:searchable="true"
									:placeholder="$t('Label.amount')"
									@select="selectAmount"
									@remove="clearFilter('amount')"
								>
									<template #noOptions>
										{{ $t("DropDown.NoOptions") }}
									</template>
									<template #noResult>
										{{ $t("DropDown.NoResult") }}
									</template>
									<template slot="clear">
										<div
											v-if="amount"
											class="multiselect__clear"
											@mousedown.prevent.stop="clearFilter('amount')"
										>
											<font-awesome-icon :icon="['fas', 'times']" :title="$t('Action.Clear')" />
										</div>
									</template>
								</multiselect>
							</div>
						</CCol>

						<template v-if="!isOrderScreen">
							<CCol v-if="showFilterReceivedTime" class="col-md-6 col-sm-6 col-lg">
								<label for="search" class="col-form-label label-date">{{ labelDate }}</label>
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(searchFilter)">
										<label for="fromDate" class="col-form-label label-from-date">{{
											$t("Label.from")
										}}</label>
										<div>
											<date-picker
												id="fromDate"
												v-model="fromDate"
												value-type="format"
												class="w-100"
												:placeholder="$t('Label.DateFormat')"
												:disabled-date="disabledBeforeFromDate"
												:editable="false"
												:format="formatDate"
												@clear="clearDate('fromDate')"
												@pick="selectDate('fromDate', $event)"
											/>
										</div>
									</form>
								</ValidationObserver>
							</CCol>

							<CCol v-if="showFilterReceivedTime" class="col-md-6 col-sm-6 col-lg pt-20">
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(searchFilter)">
										<label for="toDate" class="col-form-label">{{ $t("Label.to") }}</label>
										<div>
											<date-picker
												id="toDate"
												v-model="toDate"
												value-type="format"
												class="w-100"
												:disabled-date="disabledBeforeToDate"
												:placeholder="$t('Label.DateFormat')"
												:editable="false"
												:format="formatDate"
												@clear="clearDate('toDate')"
												@pick="selectDate('toDate', $event)"
											/>
										</div>
									</form>
								</ValidationObserver>
							</CCol>
						</template>
					</CRow>
					<CRow class="form-group">
						<template v-if="isOrderScreen">
							<CCol v-if="showFilterReceivedTime" class="col-md-2 col-sm-6 col-lg">
								<label for="search" class="col-form-label label-date">{{ labelDate }}</label>
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(searchFilter)">
										<label for="fromDate" class="col-form-label label-from-date">{{
											$t("Label.from")
										}}</label>
										<div>
											<date-picker
												id="fromDate"
												v-model="fromDate"
												value-type="format"
												class="w-100"
												:placeholder="$t('Label.DateFormat')"
												:disabled-date="disabledBeforeFromDate"
												:editable="false"
												:format="formatDate"
												@clear="clearDate('fromDate')"
												@pick="selectDate('fromDate', $event)"
											/>
										</div>
									</form>
								</ValidationObserver>
							</CCol>

							<CCol v-if="showFilterReceivedTime" class="col-md-2 col-sm-6 col-lg pt-20">
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(searchFilter)">
										<label for="toDate" class="col-form-label">{{ $t("Label.to") }}</label>
										<div>
											<date-picker
												id="toDate"
												v-model="toDate"
												value-type="format"
												class="w-100"
												:disabled-date="disabledBeforeToDate"
												:placeholder="$t('Label.DateFormat')"
												:editable="false"
												:format="formatDate"
												@clear="clearDate('toDate')"
												@pick="selectDate('toDate', $event)"
											/>
										</div>
									</form>
								</ValidationObserver>
							</CCol>
						</template>

						<slot name="tabs">
							<!-- create at -->
							<template v-if="showCreatedDate">
								<CCol class="col-md-2 col-sm-12 col-lg pt-20">
									<ValidationObserver ref="form" v-slot="{ handleSubmit }">
										<form @submit.prevent="handleSubmit(searchFilter)">
											<label for="created" class="col-form-label">{{ $t("Label.created") }}</label>
											<div>
												<multiselect
													id="role"
													v-model="createdDate"
													:options="dateOptions"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="false"
													:searchable="true"
													track-by="value"
													label="name"
													:placeholder="$t('DropDown.CreatedDate')"
													@select="selectCreateDate"
													@remove="clearFilter('createdDate')"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
													<template slot="clear">
														<div
															v-if="createdDate"
															class="multiselect__clear"
															@mousedown.prevent.stop="clearFilter('createdDate')"
														>
															<font-awesome-icon
																:icon="['fas', 'times']"
																:title="$t('Action.Clear')"
															/>
														</div>
													</template>
												</multiselect>
											</div>
										</form>
									</ValidationObserver>
								</CCol>

								<!-- <span class="d-flex flex-column justify-content-end pb-2 pt-2">or</span> -->

								<CCol class="col-md-2 col-sm-6 col-lg pt-20">
									<ValidationObserver ref="form" v-slot="{ handleSubmit }">
										<form @submit.prevent="handleSubmit(searchFilter)">
											<label for="createdFrom" class="col-form-label">{{ $t("Label.from") }}</label>
											<div>
												<date-picker
													id="createdFrom"
													v-model="createdFromDate"
													value-type="format"
													class="w-100"
													:placeholder="$t('Label.DateFormat')"
													:disabled-date="disabledBeforeCreatedFrom"
													:editable="false"
													:format="formatDate"
													@clear="clearDate('createdFrom')"
													@pick="selectDate('createdFrom', $event)"
												/>
											</div>
										</form>
									</ValidationObserver>
								</CCol>

								<CCol class="col-md-2 col-sm-6 col-lg pt-20">
									<ValidationObserver ref="form" v-slot="{ handleSubmit }">
										<form @submit.prevent="handleSubmit(searchFilter)">
											<label for="createdTo" class="col-form-label">{{ $t("Label.to") }}</label>
											<div>
												<date-picker
													id="createdTo"
													v-model="createdToDate"
													value-type="format"
													class="w-100"
													:disabled-date="disabledBeforeCreatedTo"
													:placeholder="$t('Label.DateFormat')"
													:editable="false"
													:format="formatDate"
													@clear="clearDate('createdTo')"
													@pick="selectDate('createdTo', $event)"
												/>
											</div>
										</form>
									</ValidationObserver>
								</CCol>
							</template>

							<!-- sort -->
							<CCol v-if="showSortBy" class="col-md-2 col-sm-12 col-lg pt-20">
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(searchFilter)">
										<label for="sort" class="col-form-label">{{ $t("Label.sort") }}</label>
										<div>
											<multiselect
												id="sort"
												v-model="sort"
												:options="sortOptions"
												:close-on-select="true"
												:show-labels="false"
												:allow-empty="false"
												:searchable="true"
												track-by="value"
												label="name"
												:placeholder="$t('DropDown.Sort')"
												@select="selectSort"
												@remove="clearFilter('sort')"
											>
												<template #noOptions>
													{{ $t("DropDown.NoOptions") }}
												</template>
												<template #noResult>
													{{ $t("DropDown.NoResult") }}
												</template>
												<template slot="clear">
													<div
														v-if="role"
														class="multiselect__clear"
														@mousedown.prevent.stop="clearFilter('sort')"
													>
														<font-awesome-icon
															:icon="['fas', 'times']"
															:title="$t('Action.Clear')"
														/>
													</div>
												</template>
											</multiselect>
										</div>
									</form>
								</ValidationObserver>
							</CCol>
						</slot>
					</CRow>
				</slot>

				<CRow class="form-group d-md-flex">
					<slot v-if="showActions" name="actions">
						<CCol class="text-right d-flex flex-column justify-content-end pl-0">
							<div class="">
								<template v-if="showSearchButton">
									<button
										type="button"
										class="btn btn-primary-custom ml-2 mt-2"
										@click="searchFilter"
									>
										{{ $t("DropDown.Search") }}
									</button>
								</template>
								<template v-if="showHighlightMode">
									<b-dropdown
										v-if="colors && colors.length"
										id="dropdown-highlight"
										class="mt-2 ml-2 text-white"
										toggle-class="btn-primary-custom"
									>
										<template #button-content>
											{{ colorSelected.name }}
										</template>
										<b-dropdown-item
											v-for="(color, index) in colors"
											:key="index"
											:active="color.name === colorSelected.name"
											@click="selectColor(color)"
											>{{ color.name }}</b-dropdown-item
										>
									</b-dropdown>
									<button
										type="button"
										class="btn btn-outline-custom ml-2 mt-2"
										:disabled="isDisabledHighlight"
										@click="saveHighlightMode"
									>
										{{ $t("Button.Save") }}
									</button>
									<button
										type="button"
										class="btn btn-outline-danger ml-2 mt-2"
										@click="cancelHighlightMode"
									>
										{{ $t("Button.Cancel") }}
									</button>
								</template>
								<template v-if="showHighlightButton">
									<button
										type="button"
										class="btn btn-outline-success ml-2 mt-2"
										@click="highlightMode"
									>
										{{ $t("DropDown.Hight_light") }}
									</button>
								</template>
								<template v-if="showExportButton">
									<button
										type="button"
										:disabled="checkDisabled || checkDisableExport"
										class="btn btn-outline-dark ml-2 mt-2"
										@click="exportExcel"
									>
										{{ $t("DropDown.Export") }}
									</button>
								</template>
								<template v-if="showCreateButton">
									<button
										type="button"
										:disabled="checkDisabled"
										class="btn btn-outline-custom ml-2 mt-2"
										@click="create"
									>
										{{ textCreateButton }}
									</button>
								</template>
								<template v-if="showImportButton">
									<button type="button" class="btn btn-outline-dark ml-2">
										{{ $t("DropDown.Import") }}
									</button>
								</template>
							</div>
						</CCol>
					</slot>
				</CRow>
				<CRow v-if="showTotalRevenue && totalRevenue >= 0">
					<CCol class="text-right d-flex justify-content-end align-items-center mb-3">
						<span>{{ $t("Table.TotalRevenue") }}: {{ totalRevenue }}</span>
					</CCol>
				</CRow>
				<CRow v-if="showTotalEditor && totalEditor >= 0">
					<CCol class="text-right d-flex justify-content-end align-items-center mb-3">
						<span>{{ $t("Table.TotalEditorWage") }}: {{ totalEditor }}</span>
					</CCol>
				</CRow>
				<CRow v-if="showTotalAmount && totalAmount >= 0">
					<CCol class="text-right d-flex justify-content-end align-items-center mb-3">
						<span>{{ $t("Table.TotalAmount") }}: {{ totalAmount }}</span>
					</CCol>
				</CRow>

				<slot name="table">
					<div class="table-responsive-md">
						<table class="table table-bordered table-hover table-responsive table-fit">
							<thead>
								<slot name="headers" />
							</thead>
							<tbody>
								<slot name="body" />
							</tbody>
						</table>
					</div>
				</slot>

				<slot name="footer">
					<CRow>
						<!-- Select limit  -->
						<CCol class="custom-table__footer">
							<template v-if="showTotalItems">
								<p v-if="totalItems >= 2" class="custom-table__total">
									<i18n path="Table.Totals" tag="span" class="custom-table__total">
										<template #totals>
											<span>{{ totalItems }}</span>
										</template>
									</i18n>
								</p>
								<p v-else class="custom-table__total">
									<i18n path="Table.Total" tag="span" class="custom-table__total">
										<template #totals>
											<span>{{ totalItems }}</span>
										</template>
									</i18n>
								</p>
							</template>
							<CRow class="m-0 options">
								<div v-if="showItemPerPage">
									<multiselect
										id="limit"
										v-model="limit"
										:options="itemPerPageOptions"
										:close-on-select="true"
										:show-labels="false"
										:allow-empty="false"
										:searchable="false"
										placeholder=""
										@select="changeLimit"
									>
										<template #noOptions>
											{{ $t("DropDown.NoOptions") }}
										</template>
										<template #noResult>
											{{ $t("DropDown.NoResult") }}
										</template>
									</multiselect>
								</div>

								<!-- pagination -->
								<CPagination
									v-if="totalPages > 0"
									:active-page.sync="recentPage"
									:pages="totalPages"
									align="end"
								/>
							</CRow>
						</CCol>
					</CRow>
				</slot>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import moment from "moment"
import { debounce, isArray } from "lodash-es"
import { SHOP_STATUS, PARAMS, ORDER_SORT, DATE_VN } from "@/shared/plugins/constants"
// import InfiniteLoading from "vue-infinite-loading"
import { DATE_OPTIONS, TABLE_NAME } from "../plugins/constants"
export default {
	name: "CustomTable",
	// components: {
	// 	InfiniteLoading,
	// },
	props: {
		totalPages: {
			type: [Number, String],
			default: 0,
		},
		statuses: {
			type: Array,
			default: () => [],
		},
		customers: {
			type: Array,
			default: () => [],
		},
		editors: {
			type: Array,
			default: () => [],
		},
		colors: {
			type: Array,
			default: () => [],
		},
		types: {
			type: Array,
			default: () => [],
		},
		sortOptions: {
			type: Array,
			default: () => [],
		},
		actualPrices: {
			type: Array,
			default: () => [],
		},
		dateOptions: {
			type: Array,
			default: () => [],
		},
		sources: {
			type: Array,
			default: () => [],
		},
		amounts: {
			type: Array,
			default: () => [],
		},
		labelSearch: {
			type: String,
			default: null,
		},
		labelDate: {
			type: String,
			default: null,
		},
		textCreateButton: {
			type: String,
			default: null,
		},
		recentFilter: {
			type: Object,
			default: () => {},
		},
		showCreateButton: {
			type: Boolean,
			default: false,
		},
		showFilterStatus: {
			type: Boolean,
			default: false,
		},
		showFilterAmount: {
			type: Boolean,
			default: false,
		},
		showFilterCustomer: {
			type: Boolean,
			default: false,
		},
		showFilterEditor: {
			type: Boolean,
			default: false,
		},
		showFilterTypes: {
			type: Boolean,
			default: false,
		},
		showSortBy: {
			type: Boolean,
			default: false,
		},
		showFilterSource: {
			type: Boolean,
			default: false,
		},
		showFilterRoles: {
			type: Boolean,
			default: false,
		},
		showFilterSearch: {
			type: Boolean,
			default: true,
		},
		showFilterReceivedTime: {
			type: Boolean,
			default: true,
		},
		showSearchButton: {
			type: Boolean,
			default: true,
		},
		showTotalRevenue: {
			type: Boolean,
			default: false,
		},
		showTotalEditor: {
			type: Boolean,
			default: false,
		},
		showTotalAmount: {
			type: Boolean,
			default: false,
		},
		showImportButton: {
			type: Boolean,
			default: false,
		},
		showExportButton: {
			type: Boolean,
			default: false,
		},
		showHighlightButton: {
			type: Boolean,
			default: false,
		},
		showFilterActualPrice: {
			type: Boolean,
			default: false,
		},
		showItemPerPage: {
			type: Boolean,
			default: true,
		},
		showFilters: {
			type: Boolean,
			default: true,
		},
		showStatusPending: {
			type: Boolean,
			default: false,
		},
		showActions: {
			type: Boolean,
			default: true,
		},
		showCreatedDate: {
			type: Boolean,
			default: false,
		},
		showHighlightMode: {
			type: Boolean,
			default: false,
		},
		showTotalItems: {
			type: Boolean,
			default: true,
		},
		showFilterCustomers: {
			type: Boolean,
			default: false,
		},
		showFilterPrice: {
			type: Boolean,
			default: false,
		},
		isDisabledHighlight: {
			type: Boolean,
			default: false,
		},
		noQuery: {
			type: Boolean,
			default: false,
		},
		totalItems: {
			type: Number,
			default: 0,
		},
		totalRevenue: {
			type: Number,
			default: 0,
		},
		totalEditor: {
			type: Number,
			default: 0,
		},
		totalAmount: {
			type: Number,
			default: 0,
		},
		colorSelected: {
			type: Object,
			default: () => {},
		},
		tableName: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formatDate: DATE_VN,
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			amount: null,
			search: null,
			price: null,
			role: null,
			status: null,
			type: [],
			typeSelected: [],
			customer: [],
			editor: [],
			sort: ORDER_SORT.DEADLINE,
			source: null,
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			fromDate: null,
			toDate: null,
			createdDate: null,
			actualPrice: null,
			createdTo: null,
			createdFrom: null,
			createdToDate: null,
			createdFromDate: null,
			recentPage: 1,
			type_order_id: Number(new Date()),
			customer_id: Number(new Date()),
			editor_id: Number(new Date()),
			listStatus: [
				{
					name: this.$t("Status.Active"),
					value: SHOP_STATUS.ACTIVE,
				},
				{
					name: this.$t("Status.Inactive"),
					value: SHOP_STATUS.INACTIVE,
				},
			],
			listStatusShop: [
				{
					name: this.$t("Status.Active"),
					value: SHOP_STATUS.ACTIVE,
				},
				{
					name: this.$t("Status.Inactive"),
					value: SHOP_STATUS.INACTIVE,
				},
				{
					name: this.$t("Status.Pending"),
					value: SHOP_STATUS.PENDING,
				},
			],
			listRoles: [
				{
					name: this.$t("Roles.SuperAdmin"),
					value: this.$constants.ADMIN_ROLE.SUPER_ADMIN,
				},
				{
					name: this.$t("Roles.Admin"),
					value: this.$constants.ADMIN_ROLE.ADMIN,
				},
			],
			itemPerPageOptions: process.env.VUE_APP_LIMIT_SEARCH_AVAILABLE.split(","),
		}
	},

	computed: {
		// statuses() {
		// 	return (this.showStatusPending && this.listStatusShop) || this.listStatus
		// },
		checkDisabled() {
			if (!this.showHighlightButton && this.colors?.length) {
				return true
			}
			return false
		},
		checkDisableExport() {
			if (this.$route.name === "EditorPayrollManagement" && !this.editor.length) {
				return true
			}
			if (this.$route.name === "CustomerBillManagement" && !this.customer.length) {
				return true
			}
			return false
		},
		isOrderScreen() {
			if (this.$route.name === "OrderManagement") return true
			return false
		},
	},
	watch: {
		recentPage(val) {
			if (val !== Math.floor(this.skip / this.limit) + 1) {
				this.offset = (val - 1) * this.limit
				if (this.noQuery) {
					const paging = {
						offset: this.offset,
						tableName: this.tableName || null,
					}
					this.$store.commit("set", ["pageChanged", paging])
				}
				this.searchFilter()
			}
		},
		search(val) {
			if (!val) {
				this.resetPaging()
			}
		},
		customers: {
			handler(val) {
				if (val?.length) {
					this.setCustomer()
				}
			},
			deep: true,
		},
		editors: {
			handler(val) {
				if (val?.length) {
					this.setEditor()
				}
			},
			deep: true,
		},
		types: {
			handler(val) {
				if (val?.length) {
					this.setTypes()
				}
			},
			deep: true,
		},
	},

	created() {
		this.setData()
	},
	methods: {
		setCustomer() {
			if (!this.customers?.length) return

			const queryParams = this.$route.query
			const customerIds = queryParams.customerIds
			if (isArray(customerIds)) {
				this.customers.forEach(el => {
					customerIds.forEach(item => {
						if (item === el.id) {
							this.customer.push(el)
						}
					})
				})
			} else {
				this.customers.forEach(el => {
					if (customerIds === el.id) {
						this.customer.push(el)
					}
				})
			}
		},
		setEditor() {
			if (!this.editors[0].options?.length) return

			const queryParams = this.$route.query
			const editorIds = queryParams.editorIds || this.recentFilter.editorIds
			if (isArray(editorIds)) {
				this.editors[0].options.forEach(el => {
					editorIds.forEach(item => {
						if (parseInt(item) === el.id) {
							this.editor.push(el)
						}
					})
				})
			} else {
				this.editors[0].options.forEach(el => {
					if (parseInt(editorIds) === el.id) {
						this.editor.push(el)
					}
				})
			}
		},

		setTypes() {
			if (!this.types?.length) return
			const queryParams = this.$route.query
			const types = queryParams.types || this.recentFilter.types

			if (isArray(types)) {
				this.types.forEach(el => {
					types.forEach(item => {
						if (item === el.id) {
							this.type.push(el)
						}
					})
				})
			} else {
				this.types.forEach(el => {
					if (types === el.id) {
						this.type.push(el)
					}
				})
			}
		},
		async setData() {
			const queryParams = this.$route.query
			this.search = (await queryParams.search) || this.recentFilter.search || this.search
			this.limit = (await queryParams.limit) || this.recentFilter.limit || this.limit
			this.offset = (await queryParams.offset) || this.recentFilter.offset || this.offset

			if (this.$route.name !== "FormManagement") {
				this.fromDate =
					(await this.convertDateToDateVN(queryParams.from)) ||
					this.convertDateToDateVN(this.recentFilter.from) ||
					this.convertDateToDateVN(this.fromDate)
				this.toDate =
					(await this.convertDateToDateVN(queryParams.to)) ||
					this.convertDateToDateVN(this.recentFilter.to) ||
					this.convertDateToDateVN(this.toDate)
			} else {
				this.fromDate = queryParams.from || this.recentFilter.from || this.fromDate
				this.toDate = queryParams.to || this.recentFilter.to || this.toDate
			}

			if (queryParams.dateType) {
				this.createdFrom =
					(await this.convertDateToUtc(queryParams.createdFrom, "month")) ||
					this.convertDateToUtc(this.recentFilter.createdFrom, "month") ||
					this.convertDateToUtc(this.createdFrom, "month")
				this.createdTo =
					(await this.convertDateToUtc(queryParams.createdTo, "month", false)) ||
					this.convertDateToUtc(this.recentFilter.createdTo, "month", false) ||
					this.convertDateToUtc(this.createdTo, "month", false)
				this.createdDate = this.dateOptions.find(option => option.value === queryParams.dateType)
			} else {
				this.createdFromDate =
					(await this.convertDateToDateVN(queryParams.createdFrom)) ||
					this.convertDateToDateVN(this.recentFilter.createdFrom) ||
					this.convertDateToDateVN(this.createdFrom)
				this.createdToDate =
					(await this.convertDateToDateVN(queryParams.createdTo)) ||
					this.convertDateToDateVN(this.recentFilter.createdTo) ||
					this.convertDateToDateVN(this.createdTo)
			}

			this.status = await this.statuses.find(
				status => status.value == (queryParams.status || this.recentFilter.status),
			)
			this.source = await this.sources.find(
				source => source.value === (queryParams.source || this.recentFilter.source),
			)
			this.sort = await this.sortOptions.find(
				sort => sort.value == (queryParams.sort || this.recentFilter.sortBy || this.sort),
			)
			this.actualPrice = await this.actualPrices.find(
				item =>
					item.value ===
					(queryParams.missActualPrice || this.recentFilter.missActualPrice || this.actualPrice),
			)
			this.amount = await this.amounts.find(
				item =>
					item.value ===
					(queryParams.missingAmount || this.recentFilter.missingAmount || this.amount),
			)
			if (this.showFilterCustomer) {
				this.setCustomer()
			}
			if (this.showFilterEditor) {
				this.setEditor()
			}
			if (this.showFilterTypes) {
				this.setTypes()
			}

			this.recentPage = Math.floor(this.offset / this.limit) + 1

			if (Object.keys(queryParams).length) {
				this.$emit("search", queryParams)
				this.$emit("get-customers")
			} else {
				// if date options no value => set default date option
				this.createdFrom = this.convertDateToUtc(new Date(), "month")
				this.createdTo = this.convertDateToUtc(new Date(), "month", false)

				this.createdDate = {
					name: this.$t("OrderFilterName.month"),
					value: DATE_OPTIONS.MONTH,
				}

				if (TABLE_NAME.includes(this.tableName) && !this.fromDate && !this.toDate) {
					this.fromDate = this.convertDateToDateVN(this.getDayOfMonth("start"))
					this.toDate = this.convertDateToDateVN(this.getDayOfMonth("end"))
				}
				if (this.$route.name === "TransactionManagement" && !this.fromDate && !this.toDate) {
					this.fromDate = this.convertDateToDateVN(new Date())
					this.toDate = this.convertDateToDateVN(new Date())
				}

				this.searchFilter()
			}
		},
		create() {
			this.$emit("create")
		},
		exportExcel() {
			this.$emit("exportExcel")
		},
		searchDebounce: debounce(function () {
			// this.resetPaging()
			this.offset = 0
			this.recentPage = 1
		}, process.env.VUE_APP_DEBOUNCE_TIME),

		async searchFilter() {
			let params = {
				limit: this.limit,
				offset: this.offset,
			}
			if (this.showFilterStatus && this.status) {
				params["status"] = this.status.value
			}
			if (this.showFilterSource && this.source) {
				params["source"] = this.source.value
			}
			if (this.showSortBy && this.sort) {
				params["sortBy"] = this.sort.value
			}
			if (this.showFilterTypes && this.type?.length) {
				params["types"] = this.type.map(el => el.id)
			}
			if (this.showFilterPrice && this.price) {
				params["price"] = this.price
			}
			// if (this.showFilterCustomer && this.customerSelected.length) {
			// 	params["customer"] = this.customerSelected.map(el => el.id)
			// }
			// if (this.showFilterEditor && this.editorSelected.length) {
			// 	params["editor"] = this.editorSelected.map(el => el.id)
			// }
			if (this.showFilterSearch && this.search) {
				// this.offset = 0
				// this.recentPage = 1
				params["search"] = this.search
			}
			if (this.showFilterReceivedTime) {
				const routeName = this.$route.name
				if (this.fromDate) {
					params["from"] =
						routeName === "FormManagement" ? this.fromDate : this.comvertToISO(this.fromDate)
				}
				if (this.toDate) {
					params["to"] =
						routeName === "FormManagement" ? this.toDate : this.comvertToISO(this.toDate, false)
				}
			}

			if (this.showCreatedDate && this.createdDate) {
				params["dateType"] = this.createdDate.value
				params["createdFrom"] = this.createdFrom
				params["createdTo"] = this.createdTo
			}
			if (this.showCreatedDate) {
				if (this.createdFromDate) {
					params["createdFrom"] = this.comvertToISO(this.createdFromDate)
				}

				if (this.createdToDate) {
					params["createdTo"] = this.comvertToISO(this.createdToDate, false)
				}
			}

			if (this.showFilterCustomers && this.customer?.length) {
				params["customerIds"] = this.customer.map(el => el.id)
			}

			if (this.showFilterActualPrice && this.actualPrice) {
				params["missActualPrice"] = this.actualPrice.value
			}

			if (this.showFilterEditor && this.editor?.length) {
				params["editorIds"] = this.editor.map(el => el.id)
			}

			if (this.showFilterAmount && this.amount) {
				params["missingAmount"] = this.amount.value
			}

			if (!this.noQuery)
				this.$router
					.push({ query: { ...params } })
					.then(() => {
						document.querySelector("body").scrollTop = 0
					})
					.catch(() => {})

			await this.$emit("search", params)

			if (this.showFilterCustomers && !this.customer.length) {
				this.$emit("get-customers")
			}
		},
		changeLimit(selected) {
			this.limit = selected
			this.recentPage = Math.floor(this.offset / selected) + 1
			this.offset = (this.recentPage - 1) * selected
			this.searchFilter()
		},
		async clearSelectMultiple(field) {
			switch (field) {
				case "editor":
					this.editor = []
					break
				case "customer":
					this.customer = []
					break
				case "type":
					this.type = []
					break

				default:
					break
			}
			await this.delay(100)
			this.resetPaging()
		},
		async clearFilter(field) {
			switch (field) {
				case "source":
					this.source = null
					break

				case "status":
					this.status = null
					break

				case "role":
					this.role = null
					break
				case "actual-price":
					this.actualPrice = null
					break

				case "amount":
					this.amount = null
					break

				case "type":
					await this.delay(100)
					break
				case "editor":
					await this.delay(100)
					break
				case "createdDate":
					this.createdDate = null
					this.createdTo = null
					this.createdFrom = null
					break
				case "customer":
					await this.delay(100)
					break
				default:
					break
			}
			this.resetPaging()
		},
		clearDate(field) {
			switch (field) {
				case "fromDate":
					this.fromDate = null
					break

				case "todate":
					this.toDate = null
					break
				case "createdFrom":
					this.createdFrom = null
					break
				case "createdTo":
					this.createdTo = null
					break

				default:
					break
			}

			this.resetPaging()
		},
		selectDate(field, date) {
			switch (field) {
				case "fromDate":
					this.fromDate = this.convertDateToDateVN(date)
					break
				case "toDate":
					this.toDate = this.convertDateToDateVN(date)
					break
				case "createdFrom":
					this.createdDate = null
					this.createdTo = null
					this.createdFrom = null
					this.createdFromDate = this.convertDateToDateVN(date)
					break
				case "createdTo":
					this.createdDate = null
					this.createdTo = null
					this.createdFrom = null
					this.createdToDate = this.convertDateToDateVN(date)
					break

				default:
					break
			}

			this.resetPaging()
		},
		resetPaging() {
			this.offset = 0
			this.recentPage = 1
			this.searchFilter()
		},
		selectSource(selected) {
			this.source = selected
			this.resetPaging()
		},
		selectActualPrice(selected) {
			this.actualPrice = selected
			this.resetPaging()
		},
		selectStatus(selected) {
			this.status = selected
			this.resetPaging()
		},
		selectSort(selected) {
			this.sort = selected
			this.resetPaging()
		},
		async selectType() {
			await this.delay(100)
			// this.typeSelected.push(selected)
			// this.resetPaging()
		},
		selectAmount(selected) {
			this.amount = selected
			this.resetPaging()
		},
		async selectEditor() {
			await this.delay(100)
			// this.resetPaging()
		},
		async selectCustomers() {
			await this.delay(100)
			// this.resetPaging()
		},
		getTypes() {
			this.$emit("get-types")
		},
		getCustomer() {
			this.$emit("get-customer")
		},
		getEditor() {
			this.$emit("get-editor")
		},
		disabledBeforeToDate(date) {
			if (this.fromDate) {
				const newDate = this.convertToISODate(this.fromDate)
				const deadline = new Date(newDate)
				deadline.setHours(0, 0, 0, 0)

				return date < deadline
			}
		},
		disabledBeforeCreatedFrom(date) {
			if (this.createdDate) return

			if (this.createdToDate) {
				const newDate = this.convertToISODate(this.createdToDate)
				const deadline = new Date(newDate)
				deadline.setHours(0, 0, 0, 0)

				return date > deadline
			}
		},
		disabledBeforeFromDate(date) {
			if (this.toDate) {
				const newDate = this.convertToISODate(this.toDate)
				const deadline = new Date(newDate)
				deadline.setHours(0, 0, 0, 0)

				return date > deadline
			}
		},
		disabledBeforeCreatedTo(date) {
			if (this.createdDate) return

			if (this.createdFromDate) {
				const newDate = this.convertToISODate(this.createdFromDate)
				const deadline = new Date(newDate)
				deadline.setHours(0, 0, 0, 0)

				return date < deadline
			}
		},
		isAppendLoading(array) {
			return array.length >= this.limit
		},
		loadMore($state) {
			this.$emit("load-more", $state)
		},
		loadMoreCustomer($state) {
			this.$emit("load-more-customer", $state)
		},
		loadMoreEditor($state) {
			this.$emit("load-more-customer", $state)
		},
		async closeDropDown() {
			await this.delay(100)
			this.resetPaging()
		},
		selectColor(color) {
			this.$emit("selectColor", color)
		},
		highlightMode() {
			this.$emit("highlightMode")
		},
		cancelHighlightMode() {
			this.$emit("cancelHighlightMode")
		},
		saveHighlightMode() {
			this.$emit("saveHighlightMode")
		},
		selectCreateDate(option) {
			this.createdFromDate = null
			this.createdToDate = null

			this.createdDate = option

			const value = option.value
			const today = new Date()

			switch (value) {
				case DATE_OPTIONS.TODAY:
					this.createdFrom = this.convertDateToUtc(today, "date")
					this.createdTo = this.convertDateToUtc(today, "date", false)
					break
				case DATE_OPTIONS.WEEK:
					this.createdFrom = this.convertDateToUtc(today, "isoWeek")
					this.createdTo = this.convertDateToUtc(today, "isoWeek", false)
					break
				case DATE_OPTIONS.MONTH:
					this.createdFrom = this.convertDateToUtc(today, "month")
					this.createdTo = this.convertDateToUtc(today, "month", false)
					break

				default:
					this.createdFrom = this.convertDateToUtc(today, "year")
					this.createdTo = this.convertDateToUtc(today, "year", false)
					break
			}
			this.resetPaging()
		},
		delay(time) {
			return new Promise(resolve => setTimeout(resolve, time))
		},
		getDayOfMonth(position) {
			if (position === "start") {
				return moment().clone().startOf("month").utc().format()
			}
			return moment().clone().endOf("month").utc().format()
		},
	},
}
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";

.custom-table {
	.table td.fit,
	.table th.fit {
		white-space: nowrap;
		width: 5%;
	}

	.table td.fit-id,
	.table th.fit-id {
		white-space: nowrap;
		width: 1%;
	}

	.table th {
		background-color: $table-header-color;
		color: $base-color;
		font-weight: 600;
		border-bottom: 1px solid $border-input;
	}

	.table td {
		vertical-align: middle;
	}

	&__footer {
		display: grid;
		grid-template-columns: 1fr auto auto;

		.options {
			justify-self: end;
		}

		.pagination {
			margin-left: 15px;
		}

		&__total {
			grid-column: -1/1;

			@media (min-width: $xxs) {
				grid-column: initial;
			}
		}
	}

	.actions {
		span {
			&:hover {
				cursor: pointer;
			}
		}
	}
	.custom-input {
		height: 35px;
		font-size: 14px;
	}
	.col-form-label {
		font-size: 14px;
	}
	.label-from-date {
		padding-top: 0;
	}
	.label-date {
		padding-bottom: 0;
	}
	.pt-20 {
		padding-top: 20px;
	}
	.btn-primary-custom {
		background: $base-color;
		color: $white;
		border-color: $base-color;
	}
	.form_customs {
		display: grid;
		grid-template-columns: 1fr 3fr;
	}
	.btn-outline-success {
		border-color: $green-2;
	}
}
</style>
