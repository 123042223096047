import { callAPI } from "@/shared/services"

export default {
	getForms: params => {
		return callAPI("GET", "form", { params }).then(res => res.data)
	},
	getRequests: params => {
		return callAPI("GET", "form-web/requests", { params, notLoading: true }).then(res => res.data)
	},
	getFormWebDetail: params => {
		return callAPI("GET", `form-web/${params.id}`).then(res => res.data)
	},
	getFormDetail: params => {
		return callAPI("GET", `form/${params.id}`).then(res => res.data)
	},
	updateForm: params => {
		return callAPI("PUT", `form`, params, { notLoading: true }).then(res => res.data)
	},
	updateFormWeb: params => {
		return callAPI("PUT", `form-web`, params, { notLoading: true }).then(res => res.data)
	},
	cancelFormRequest: params => {
		return callAPI("PUT", `form-web/request/cancel`, params, { skipToast: true }).then(
			res => res.data,
		)
	},
	createFormRequest: params => {
		return callAPI("POST", "form-web/request", params).then(res => res.data)
	},
	cancelForm: params => {
		return callAPI("PUT", `form/cancel`, params).then(res => res.data)
	},
	cancelFormWeb: params => {
		return callAPI("PUT", `form-web/cancel`, params).then(res => res.data)
	},
	lockForm: params => {
		return callAPI(
			"PUT",
			`form/lock/${params.formId}`,
			{},
			{ notLoading: true, skipToast: true },
		).then(res => res.data)
	},
	unlockForm: params => {
		return callAPI(
			"DELETE",
			`form/lock/${params.formId}`,
			{},
			{ notLoading: true, skipToast: true },
		).then(res => res.data)
	},
}
