import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"
import { PARAMS } from "@/shared/plugins/constants"

const initialState = {
	customerBills: [],
	totalPages: null,
	totalItems: null,
	totalRevenue: null,
	recentFilters: {
		limit: PARAMS.LIMIT,
		offset: PARAMS.OFFSET,
		search: null,
		customerIds: null,
		completedFrom: null,
		completedTo: null,
	},
	fileExport: null,
	customers: [],
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
