import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const TypesOrderModule = () => import("./TypesOrderModule.vue")
const TypeOrderManagement = () => import("./pages/TypeOrderManagement.vue")
const CreateType = () => import("./pages/CreateType.vue")
const TypeOrderDetail = () => import("./pages/TypeOrderDetail.vue")

const typeOrderRoutes = [
	{
		path: "/type-order",
		name: "TypesOrderModule",
		component: TypesOrderModule,
		meta: {
			label: i18n.t("TypesOrderTitle.TypesOrderManagement"),
		},
		redirect: "/type-order/list",
		children: [
			{
				path: "list",
				name: "TypeOrderManagement",
				component: TypeOrderManagement,
				meta: {
					title: i18n.t("TypesOrderTitle.TypeOrderList"),
					label: i18n.t("TypesOrderTitle.TypeOrderList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: "create",
				name: "CreateType",
				component: CreateType,
				meta: {
					title: i18n.t("TypesOrderTitle.CreateType"),
					label: i18n.t("TypesOrderTitle.CreateType"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "TypeOrderDetail",
				component: TypeOrderDetail,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("TypesOrderTitle.TypeOrderDetail"),
					label: i18n.t("TypesOrderTitle.TypeOrderDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/type-order/list",
			},
		],
	},
]

export default typeOrderRoutes
