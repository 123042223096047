import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	AuthenTitle: {
		Login: "Login",
		OTP: "Confirm OTP",
		ForgotPassword: "Forgot Password",
		ResetPassword: "Reset Password",
		Profile: "Profile",
	},
	Login: {
		Title: "Login",
		Email: "Email",
		Password: "Password",
		Submit: "Login",
		ForgotPassword: "Forgot password?",
		Register: "Register now!",
		hidePwd: "Hide password",
		showPwd: "Show password",
	},
	OTP: {
		Title: "Send OTP",
		Field: "Verifycation code",
		Continue: "Continue",
		Resend: "Resend code",
	},
	ForgotPwd: {
		Title: "Forgot Password",
		Description:
			"Hãy điền email của bạn dưới đây, chúng tôi sẽ gửi email hướng dẫn đến địa chỉ bạn cung cấp",
		Email: "Email",
		Send: "Send",
		Continue: "Continue",
		Login: "Login",
		messSuccess: "The password reset link has been sent to your email. Please check your email",
	},
	ResetPwd: {
		Title_Reset: "Reset Password",
		Title: "Reset Password",
		Password: "New password",
		ConfirmPassword: "New password confirmation",
		Submit: "Submit",
		Finish: "Finish",
		Cancel: "Cancel",
		hidePwd: "Hide password",
		showPwd: "Show password",
		ok: "OK",
		msgErr: "The link has been used.",
		resetSuccess: "Your password has been reset successfully! Please log in to continue.",
		resetAgainSuccess: "Password updated successfully",
	},
	Logout: {
		Title: "Logout",
		Message: "Do you want to logout ?",
		Submit: "Logout",
		Cancel: "Cancel",
	},
	ChangePwd: {
		Title: "Change Password",
		CurrentPassword: "Current password",
		NewPassword: "New password",
		ConfirmPassword: "Confirm new password",
		Submit: "Save",
		Cancel: "Cancel",
		hidePwd: "Hide password",
		showPwd: "Show password",
		Success: "Change password successfully. Please log in again.",
	},
	Profile: {
		Title: "User Profile",
		Email: "Email",
		Name: "Full Name",
		PhoneNumber: "Phone Number",
		Role: "Permission",
		Edit: "Update Profile",
		UpdateTitle: "Update Profile",
		Submit: "Save",
		Cancel: "Cancel",
	},
	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			auth_username: "user name",
			auth_email: "email",
			auth_password: "Password",
			auth_old_password: "curent password",
			auth_new_password: "Password",
			auth_confirm_password: "Password confirmation",
			auth_otp: "Verifycation code",
			auth_full_name: "user name",
			auth_phone: "phone number",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			confirmedBy: "{_field_} doesn't match. Please try again",
			isEmail: "Please enter a valid email",
			password:
				"{_field_} must be in 6 characters and contain both digit and word. Please try again.",
			phone: "The {_field_} is invalid",
			requiredExport: "Required information",
		},
	},
})
