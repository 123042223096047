export const GET_TEMP_EMAILS_SUCCESS = "GET_TEMP_EMAILS_SUCCESS"
export const GET_TEMP_EMAILS_FAILED = "GET_TEMP_EMAILS_FAILED"

export const GET_TEMP_EMAIL_SUCCESS = "GET_TEMP_EMAIL_SUCCESS"
export const GET_TEMP_EMAIL_FAILED = "GET_TEMP_EMAIL_FAILED"

export const GET_EMAIL_LAST_SUCCESS = "GET_EMAIL_LAST_SUCCESS"

export const CREATE_TEMP_EMAIL_SUCCESS = "CREATE_TEMP_EMAIL_SUCCESS"

export const UPDATE_TEMP_EMAIL_SUCCESS = "UPDATE_TEMP_EMAIL_SUCCESS"

export const DELETE_TEMP_EMAIL_SUCCESS = "DELETE_TEMP_EMAIL_SUCCESS"
export const RESET_EMAIL_FILTERS = "RESET_EMAIL_FILTERS"
export const SET = "SET"
