export const SET_EDITOR_SELECTED = "SET_EDITOR_SELECTED"

export const RESET_STATE_PAYSHEET = "RESET_STATE_PAYSHEET"

export const SET_UNIT_WAGE = "SET_UNIT_WAGE"

export const CREATE_PAYSHEET_SUCCESS = "CREATE_PAYSHEET_SUCCESS"
export const CREATE_PAYSHEET_FAILED = "CREATE_PAYSHEET_FAILED"

export const UPDATE_PAYSHEET_SUCCESS = "UPDATE_PAYSHEET_SUCCESS"
export const UPDATE_PAYSHEET_FAILED = "UPDATE_PAYSHEET_FAILED"

export const GET_PAYSHEETS_SUCCESS = "GET_PAYSHEETS_SUCCESS"
export const GET_PAYSHEETS_FAILED = "GET_PAYSHEETS_FAILED"

export const GET_EDITORS_IN_PAYSHEET_SUCCESS = "GET_EDITORS_IN_PAYSHEET_SUCCESS"
export const GET_EDITORS_IN_PAYSHEET_FAILED = "GET_EDITORS_IN_PAYSHEET_FAILED"

export const SET_STATE_PAYSHEET = "SET_STATE_PAYSHEET"
