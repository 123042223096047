import {
	CHANGE_STATUS_SUCCESS,
	GET_USERS_APP_FAILED,
	GET_USERS_APP_SUCCESS,
	GET_USER_APP_FAILED,
	GET_USER_APP_SUCCESS,
	RESET_STATE,
	UPDATE_USER_APP_SUCCESS,
} from "./mutation-types"
import { PARAMS } from "@/shared/plugins/constants"

export default {
	[GET_USERS_APP_SUCCESS]: (state, payload) => {
		state.users = payload.result
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},
	[GET_USERS_APP_FAILED]: state => {
		state.users = []
		state.totalItems = null
		state.recentFilters = {
			form: null,
			to: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
	},
	[CHANGE_STATUS_SUCCESS]: () => {},
	[UPDATE_USER_APP_SUCCESS]: () => {},
	[GET_USER_APP_SUCCESS]: (state, payload) => {
		state.userDetail = payload
	},
	[GET_USER_APP_FAILED]: state => {
		state.userDetail = null
	},
	[RESET_STATE]: state => {
		state.users = []
		state.totalItems = null
		state.userDetail = null
		state.recentFilters = {
			form: null,
			to: null,
			search: null,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
		}
		state.totalPages = null
	},
}
