<template>
	<div>
		<CModal
			:show.sync="modalShow"
			:close-on-backdrop="false"
			:centered="true"
			:size="size"
			class="custom-modal"
		>
			<slot name="content" />
			<template #header>
				<h6 class="modal-title">
					{{ title }}
				</h6>
				<CButtonClose v-if="isShowClose" class="btn" @click="cancel" />
			</template>
			<template #footer>
				<CButton v-if="isShowSubmit" type="submit" color="outline-custom" @click="submit">
					{{ submitText }}
				</CButton>
				<CButton v-if="isShowSync" type="submit" color="outline-custom" @click="submit">
					{{ $t("Button.Sync") }}
				</CButton>
				<CButton v-if="isShowCancel" :color="cancelColor" @click="cancel">
					{{ cancelText }}
				</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import i18n from "@/shared/plugins/i18n"

export default {
	name: "CustomModal",
	props: {
		title: {
			type: String,
			default: i18n.t("Button.Noti"),
		},
		size: {
			type: String,
			default: "",
		},
		submitText: {
			type: String,
			default: i18n.t("Button.Submit"),
		},
		cancelText: {
			type: String,
			default: i18n.t("Button.Cancel"),
		},
		isShowSubmit: {
			type: Boolean,
			default: true,
		},
		isShowCancel: {
			type: Boolean,
			default: true,
		},
		isShowClose: {
			type: Boolean,
			default: true,
		},
		isShowSync: {
			type: Boolean,
			default: false,
		},
		cancelColor: {
			type: String,
			default: "outline-danger",
		},
	},

	computed: {
		modalShow() {
			return this.$store.state.modalShow
		},
	},

	methods: {
		submit() {
			this.$emit("submit-modal")
		},
		cancel() {
			this.$emit("cancel-modal")
			this.$store.commit("set", ["modalShow", false])
		},
	},
}
</script>
