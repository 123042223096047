import { callAPI } from "@/shared/services"
export default {
	createOrderType: params => {
		return callAPI("POST", "orders-types", params, { skipToast: true }).then(res => res.data)
	},
	updateOrderType: params => {
		return callAPI("PUT", "orders-types", params).then(res => res.data)
	},
	getOrderTypes: params => {
		return callAPI("GET", "orders-types", {
			params: params.data,
			notLoading: params.notLoading,
		}).then(res => res.data)
	},
	getOrderTypesAmount: params => {
		return callAPI("GET", "orders-types", {
			params,
		}).then(res => res.data)
	},
	getOrderTypeDetail: params => {
		return callAPI("GET", `orders-types/${params.id}`).then(res => res.data)
	},
	deleteOrderType: params => {
		return callAPI("DELETE", "orders-types", { params }).then(res => res.data)
	},
	getOrderTypesPrice: params => {
		return callAPI("GET", "customer/order-types", {
			params: params.data,
			notLoading: params.notLoading,
		}).then(res => res.data)
	},
	getTypesOfPaysheet: params => {
		return callAPI("GET", "pay-sheet/order-types", {
			params: params.data,
			notLoading: params.notLoading,
		}).then(res => res.data)
	},
}
