import { REFRESH_TOKEN } from "@/modules/authentication/store/action-types"
import { REFRESH_TOKEN_ERROR } from "@/modules/authentication/store/mutation-types"
import router from "@/router"
import CustomToastr from "@/shared/plugins/toastr"
import { mappingResponseConvention } from "@/shared/utils/mixins"
import store from "@/store"
import axios from "axios"
import SecureLS from "secure-ls"
import Vue from "vue"
import { LOGOUT_SUCCESS } from "../../modules/authentication/store/mutation-types"

const ls = new SecureLS({ isCompression: false })
let loader = null
let isAlreadyFetchingAccessToken = false
let refreshToken = null

const httpClient = axios.create({
	baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
	withCredentials: false,
	timeout: `${process.env.VUE_APP_TIMEOUT}`,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
})

httpClient.interceptors.request.use(
	config => {
		if (!loader && !config.notLoading) {
			loader = Vue.$loading.show()
		}
		const vuex = ls.get("vuex") ? JSON.parse(ls.get("vuex")) : null
		const token = vuex?.authentication?.accessToken || null
		const locale = localStorage.getItem("locale") || process.env.VUE_APP_I18N_LOCALE || null

		if (token) config.headers.common["Authorization"] = `Bearer ${token}`
		if (locale) config.headers.common["Content-Language"] = locale

		return config
	},
	error => {
		return Promise.reject(error)
	},
)

httpClient.interceptors.response.use(
	response => {
		if (loader) {
			loader.hide()
			loader = null
		}
		response.data = mappingResponseConvention(response.data)
		return response
	},
	async error => {
		if (loader) {
			loader.hide()
			loader = null
		}
		throw error
	},
)

export const callAPI = (method, path, body, config = {}) => {
	let res = null
	switch (method.toLowerCase()) {
		case "get":
			// in case GET method: body is config
			res = httpClient[method.toLowerCase()](path, body || config)
			break
		default:
			res = httpClient[method.toLowerCase()](path, body, config)
	}

	return res
		.then(res => {
			isAlreadyFetchingAccessToken = false
			return res
		})
		.catch(async error => {
			if (!error.config?.skipErrorHandle) {
				switch (error.response?.status) {
					case 400: // Wrong url or params
						if (path === "auth/refresh") {
							store.commit(`authentication/${REFRESH_TOKEN_ERROR}`)
							// CustomToastr.error(error.response?.data?.message || error.message)
							const params = {
								show: true,
								message: error.response?.data?.message,
							}
							store.dispatch("showMessage", params)
							router.push("/login")
							throw error.response?.data?.detail || error
						}
						if (error.response?.data?.message && !error.config?.skipToast) {
							// CustomToastr.error(error.response?.data?.message || error.message)
							const params = {
								show: true,
								message: error.response?.data?.message,
							}
							store.dispatch("showMessage", params)
							break
						} else throw error.response?.data || error

					case 404: // Missing parameters | Missing upload file
					case 409: // Conflict
					case 500: // Server error
						// Show toastr if error code global, likes: 500 Unknow Error
						// Other: handled in vue component catch
						if (!error.response?.data?.message && !error.config?.skipToast) {
							// CustomToastr.error(error.response?.data?.message || error.message)
							const params = {
								show: true,
								message: error.response?.data?.message,
							}
							store.dispatch("showMessage", params)
							break
						} else throw error.response?.data?.message || error
					case 403: // Permission
						await store.commit(`authentication/${LOGOUT_SUCCESS}`)
						router.push("/login")
						break
					case 401: // Signature verification failed | Token has been revoked
						// check url # refresh token
						// true: try to refresh access token. then call queue apis
						// else: logout
						if (path !== "auth/refresh") {
							if (!isAlreadyFetchingAccessToken) {
								isAlreadyFetchingAccessToken = true
								refreshToken = new Promise(resolve => {
									resolve(store.dispatch(`authentication/${REFRESH_TOKEN}`))
								})
							}
							await refreshToken
							return callAPI(method, path, body, config)
						} else {
							store.commit(`authentication/${REFRESH_TOKEN_ERROR}`)
							CustomToastr.error(error.response?.data?.message || error.message)
							router.push("/login")
							throw error.response?.data?.detail || error
						}
					default:
						throw error.response?.data?.detail || error
				}
			}
		})
}
