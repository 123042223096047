import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	PaysheetTitle: {
		PaysheetManagement: "Paysheets",
		PaysheetList: "List paysheet",
		CreatePaysheet: "Create Paysheet",
		NewPaysheet: "New Paysheet",
		PaysheetDetail: "Paysheet Detail",
	},
	PaysheetFieldTable: {
		Name: "Name",
		Type: "Type",
		UnitWage: "Unit wage",
		CreatedDate: "Created date",
		Actions: "Actions",
	},
	PaysheetFormFields: {
		Name: "Name",
		Detail: "Detail",
		Editor: "Editor",
	},
	PaysheetFilter: {
		search: "Name",
		createdDate: "Created date",
	},
	PaysheetButtons: {
		create: "New paysheet",
		delete: "Confirm",
	},
	fields: {
		...{
			paysheet_name: "Name",
			paysheet_editor: "Editor",
			paysheet_detail: "Detail",
		},
	},
	PaysheetMessages: {
		delete: {
			body: "Are you sure want to delete this paysheet?",
		},
	},

	validation: {
		...en.messages,
		...{
			// custom message rules
			requiredExport: "Required information",
		},
	},
})
