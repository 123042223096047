import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const UsersModule = () => import("./UsersModule.vue")
const UserManagement = () => import("./pages/UserManagement.vue")
const CreateUser = () => import("./pages/CreateUser.vue")
const UpdateUser = () => import("./pages/UpdateUser.vue")

const userRoutes = [
	{
		path: "/users",
		name: "UsersModule",
		component: UsersModule,
		meta: {
			label: i18n.t("UsersTitle.UsersManagement"),
		},
		redirect: "/users/list",
		children: [
			{
				path: "list",
				name: "UserManagement",
				component: UserManagement,
				meta: {
					title: i18n.t("UsersTitle.UserList"),
					label: i18n.t("UsersTitle.UserList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: "create",
				name: "CreateUser",
				component: CreateUser,
				meta: {
					title: i18n.t("UsersTitle.CreateUser"),
					label: i18n.t("UsersTitle.CreateUser"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "UpdateUser",
				component: UpdateUser,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("UsersTitle.UpdateUser"),
					label: i18n.t("UsersTitle.UpdateUser"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/users/list",
			},
		],
	},
]

export default userRoutes
