<template>
	<b-modal
		v-if="message"
		v-model="notifyShow"
		modal-class="notify"
		content-class="content-notify"
		hide-footer
		:hide-backdrop="true"
		size="sm"
	>
		<template #modal-header>
			<div class="d-flex justify-content-end align-items-center w-100" @click="redirect">
				<CIcon name="cilX" class="icon--close" />
			</div>
		</template>
		<template #default>
			<div class="notify__body" @click="redirect">{{ message.notification.body }}</div>
		</template>
	</b-modal>
</template>
<script>
import { mapActions, mapMutations } from "vuex"
import { PARAMS, STATUS_FORM } from "../plugins/constants"
export default {
	name: "Notify",
	props: {
		message: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			statusFormUpdate: [STATUS_FORM.UNREVIEW, STATUS_FORM.REQUESTED, STATUS_FORM.RESPONDED],
		}
	},
	computed: {
		notifyShow: {
			get() {
				return this.$store.state.notifyShow
			},
			set() {
				this.handleNotify(false)
			},
		},
	},
	watch: {
		notifyShow(val) {
			if (val) {
				setTimeout(() => {
					this.close()
				}, 10000)
			} else {
				clearTimeout()
			}
		},
	},
	methods: {
		...mapMutations(["handleNotify"]),
		...mapActions(["readNotiSync"]),
		close() {
			this.handleNotify(false)
		},
		async redirect() {
			const { formId, notificationId, originalOrderId, formStatus } = this.message.data

			const res = await this.readNotiSync({ id: notificationId })

			if (!res) return

			this.close()

			if (originalOrderId) {
				return this.$router
					.push(`/orders/web/${originalOrderId}/detail`)
					.catch(() => this.$router.go())
			}

			if (this.statusFormUpdate.includes(formStatus)) {
				return this.$router
					.push({
						path: `/forms/web/${formId}/update`,
						query: {
							limit: PARAMS.LIMIT,
							offset: PARAMS.OFFSET,
						},
					})
					.catch(() => this.$router.go())
			}

			return this.$router.push(`/forms/web/${formId}`).catch(() => this.$router.go())
		},
	},
}
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

.notify {
	.modal-dialog {
		position: fixed;
		left: 0;
		bottom: 0;
		margin: 1.75rem;
		cursor: pointer;
	}

	.content-notify {
		.modal-header {
			border-bottom: unset;
			padding-bottom: 0;

			.icon--logo {
				height: 30px;
				width: 125px;
			}
		}
	}
}
</style>
