import { callAPI } from "@/shared/services"

export default {
	createEditor: params => {
		return callAPI("POST", "editor", params, { skipToast: true }).then(res => res.data)
	},
	updateEditor: params => {
		return callAPI("PUT", "editor", params, { skipToast: true }).then(res => res.data)
	},
	getEditors: params => {
		return callAPI("GET", "editor", {
			params: params.data,
			notLoading: params.notLoading,
			skipToast: true,
		}).then(res => res.data)
	},
	getEditorsNotInPaysheet: params => {
		return callAPI("GET", "editor/not-in-pay-sheet", { params }).then(res => res.data)
	},

	getEditor: params => {
		return callAPI("GET", "editor/detail", { params }).then(res => res.data)
	},
	deleteEditor: params => {
		return callAPI("DELETE", `editor/${params.id}`).then(res => res.data)
	},
}
