export const GET_CUSTOMER_BILL_SUCCESS = "GET_CUSTOMER_BILL_SUCCESS"
export const GET_CUSTOMER_BILL_FAILED = "GET_CUSTOMER_BILL_FAILED"

export const EXPORT_CUSTOMER_BILL_SUCCESS = "EXPORT_CUSTOMER_BILL_SUCCESS"
export const EXPORT_CUSTOMER_BILL_FAILED = "EXPORT_CUSTOMER_BILL_FAILED"

export const GET_CUSTOMER_COMPLETED_SUCCESS = "GET_CUSTOMER_COMPLETED_SUCCESS"
export const GET_CUSTOMER_COMPLETED_FAILED = "GET_CUSTOMER_COMPLETED_FAILED"

export const RESET_FILTERS_CUSTOMER_BILL = "RESET_FILTERS_CUSTOMER_BILL"
