import {
	CANCEL_FORM,
	CANCEL_FORM_REQUEST,
	CANCEL_FORM_WEB,
	CREATE_FORM_REQUEST,
	GET_FORMS,
	GET_FORM_DETAIL,
	GET_FORM_WEB_DETAIL,
	GET_REQUEST_LIST,
	LOCK_FORM,
	UNLOCK_FORM,
	UPDATE_FORM,
	UPDATE_FORM_WEB,
} from "./action-types"
import API from "../services"
import {
	GET_FORMS_FAILED,
	GET_FORMS_SUCCESS,
	GET_FORM_DETAIL_SUCCESS,
	GET_FORM_DETAIL_FAILED,
	UPDATE_FORM_SUCCESS,
	UPDATE_FORM_FAILED,
	CANCEL_FORM_SUCCESS,
	CANCEL_FORM_FAILED,
	LOCK_FORM_SUCCESS,
	LOCK_FORM_FAILED,
	UNLOCK_FORM_SUCCESS,
	GET_REQUEST_LIST_SUCCESS,
	GET_REQUEST_LIST_FAILED,
	GET_FORM_WEB_DETAIL_SUCCESS,
	GET_FORM_WEB_DETAIL_FAILED,
} from "./mutation-types"
import { FORM_REQUEST_STATUS } from "@/shared/plugins/constants"
import { formatPricePackage, convertDateWithTimezone } from "@/shared/utils/mixins"
import { capitalize } from "lodash-es"

export default {
	[GET_FORMS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getForms({ ...params })
			commit(GET_FORMS_SUCCESS, { ...res, filters: params })
		} catch (error) {
			commit(GET_FORMS_FAILED)
		}
	},
	[GET_FORM_DETAIL]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getFormDetail({ ...params })
			commit(GET_FORM_DETAIL_SUCCESS, res)
		} catch (error) {
			commit(GET_FORM_DETAIL_FAILED)
		}
	},
	[GET_FORM_WEB_DETAIL]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getFormWebDetail({ ...params })
			commit(GET_FORM_WEB_DETAIL_SUCCESS, res)
		} catch (error) {
			commit(GET_FORM_WEB_DETAIL_FAILED)
		}
	},
	[UPDATE_FORM]: async ({ commit }, params = {}) => {
		try {
			await API.updateForm({ ...params })
			commit(UPDATE_FORM_SUCCESS)
			return {
				ok: true,
			}
		} catch (error) {
			commit(UPDATE_FORM_FAILED)
			return {
				ok: false,
			}
		}
	},
	[UPDATE_FORM_WEB]: async ({ commit }, params = {}) => {
		try {
			await API.updateFormWeb(params)
			commit(UPDATE_FORM_SUCCESS)
			return {
				ok: true,
			}
		} catch (error) {
			commit(UPDATE_FORM_FAILED)
			return {
				ok: false,
			}
		}
	},
	[CANCEL_FORM]: async ({ commit }, params = {}) => {
		try {
			await API.cancelForm({ ...params })
			commit(CANCEL_FORM_SUCCESS)
			return {
				ok: true,
			}
		} catch (error) {
			commit(CANCEL_FORM_FAILED)
		}
	},
	[CANCEL_FORM_WEB]: async ({ commit }, params = {}) => {
		try {
			await API.cancelFormWeb({ ...params })
			commit(CANCEL_FORM_SUCCESS)
			return {
				ok: true,
			}
		} catch (error) {
			commit(CANCEL_FORM_FAILED)
			return {
				ok: false,
			}
		}
	},
	[LOCK_FORM]: async ({ commit }, params) => {
		try {
			await API.lockForm(params)
			commit(LOCK_FORM_SUCCESS)
		} catch (error) {
			commit(LOCK_FORM_FAILED, error.message)
		}
	},
	[UNLOCK_FORM]: async ({ commit }, params) => {
		try {
			await API.unlockForm(params)
			commit(UNLOCK_FORM_SUCCESS)
		} catch (error) {}
	},
	[GET_REQUEST_LIST]: async ({ commit }, params = {}) => {
		try {
			let res = await API.getRequests({ ...params })
			const requestOpen = res.requests.find(el => el.status === FORM_REQUEST_STATUS.OPEN)
			const requests = res.requests
				.filter(el => el.status !== FORM_REQUEST_STATUS.OPEN)
				.map(el => {
					return {
						...el,
						date: convertDateWithTimezone(el.received),
						delivery_time: el.timeOfDelivery || "",
						offer_price: formatPricePackage(el.offerPrice) || "",
						order_type: el.orderType || "",
						status: capitalize(el.status),
						amount: el.amount || "",
					}
				})
			res = {
				...res,
				requests,
				requestOpen,
			}
			commit(GET_REQUEST_LIST_SUCCESS, res)
		} catch (error) {
			commit(GET_REQUEST_LIST_FAILED)
		}
	},
	[CANCEL_FORM_REQUEST]: async ({ dispatch }, params) => {
		try {
			const data = {
				id: params.form_id,
			}
			delete params.form_id

			await API.cancelFormRequest(params)

			dispatch(GET_REQUEST_LIST, data)
			return {
				ok: true,
			}
		} catch (error) {
			return {
				ok: false,
				error,
			}
		}
	},
	[CREATE_FORM_REQUEST]: async ({ dispatch }, params = {}) => {
		try {
			await API.createFormRequest(params)

			const data = {
				id: params.formId,
			}
			dispatch(GET_REQUEST_LIST, data)
		} catch (error) {}
	},
}
