import router from "@/router"
import API from "../services"
import { checkErrorFieldResponse } from "@/shared/utils/mixins"
import * as firebase from "@/shared/plugins/firebase"

import {
	LOGIN,
	LOGOUT,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	CHANGE_PASSWORD,
	SEND_OTP,
	GET_OTP_AGAIN,
	REFRESH_TOKEN,
	GET_USER_LOGIN_PROFILE,
	UPDATE_USER_LOGIN_PROFILE,
	VERIFY_OTP,
	SEND_CODE_LOGIN,
	SEND_FIREBASE_TOKEN,
	DELETE_FIREBASE_TOKEN,
	SEND_DEVICE_TOKEN,
} from "./action-types"

import {
	LOGIN_SUCCESS,
	LOGIN_FAILED,
	LOGOUT_SUCCESS,
	SEND_OTP_SUCCESS,
	SEND_OTP_FAILED,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAILED,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAILED,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILED,
	REFRESH_TOKEN_SUCCESS,
	GET_USER_LOGIN_PROFILE_SUCCESS,
	UPDATE_USER_LOGIN_PROFILE_SUCCESS,
	UPDATE_USER_LOGIN_PROFILE_FAILED,
	CHANGE_STATUS_LOGOUT_MODAL,
	VERIFY_OTP_SUCCESS,
	VERIFY_OTP_FAILED,
	CAN_LOGIN,
} from "./mutation-types"
import { hideLoading, showLoading } from "../../../shared/utils/mixins"
import { USER_ROLE } from "../../../shared/plugins/constants"

const prefix = "auth_"

export default {
	[LOGIN]: async ({ commit, dispatch }, params = {}) => {
		try {
			showLoading()
			const res = await API.login(params)
			await commit(LOGIN_SUCCESS, res)
			const user = await dispatch(GET_USER_LOGIN_PROFILE, { notLoading: true })
			firebase.isSupported && (await dispatch(SEND_FIREBASE_TOKEN))
			if (user.role === USER_ROLE.SUPER_ADMIN) {
				try {
					await API.canSyncDrive()
				} catch (error) {
					const url = await API.getUrlGoogle()
					return window.location.replace(url)
				}
			}
			commit(CAN_LOGIN)
			router.push({
				path: "/",
				query: router.currentRoute.query || {},
			})
		} catch (error) {
			const message = checkErrorFieldResponse(prefix, Object.keys(params), error.message)
			commit(LOGIN_FAILED, message)
		} finally {
			hideLoading()
		}
	},

	[SEND_CODE_LOGIN]: async ({ commit }, params) => {
		try {
			commit(CAN_LOGIN)
			await API.sendCode(params)
		} catch (error) {
		} finally {
			hideLoading()
		}
	},

	[SEND_OTP]: async ({ commit }, params = {}) => {
		try {
			await API.sendOTP(params)
			commit(SEND_OTP_SUCCESS, params)
			router.push({ path: "/forgot-password-otp" })
		} catch (error) {
			const message = checkErrorFieldResponse(prefix, Object.keys(params), error.message)
			commit(SEND_OTP_FAILED, message)
		}
	},

	[VERIFY_OTP]: async ({ commit }, params = {}) => {
		try {
			const res = await API.verifyOTP({ ...params })
			commit(VERIFY_OTP_SUCCESS, res)
			router.push({ path: "/reset-password" })
		} catch (error) {
			commit(VERIFY_OTP_FAILED)
		}
	},

	[GET_OTP_AGAIN]: async ({ commit }, params = {}) => {
		try {
			const formData = new FormData()
			formData.append("username", params.username)
			formData.append("password", params.password)
			await API.login(formData)
			commit(LOGIN_SUCCESS, params)
		} catch (error) {
			const message = checkErrorFieldResponse(prefix, Object.keys(params), error.message)
			commit(LOGIN_FAILED, message)
		}
	},

	[LOGOUT]: async ({ commit, dispatch }) => {
		firebase.isSupported && (await dispatch(DELETE_FIREBASE_TOKEN))
		commit(LOGOUT_SUCCESS)
		commit("set", ["modalShow", false], { root: true })
		commit(CHANGE_STATUS_LOGOUT_MODAL, false)
		router.push("/login")
	},

	[FORGOT_PASSWORD]: async ({ commit }, params = {}) => {
		try {
			await API.recoverPwd(params)
			commit(FORGOT_PASSWORD_SUCCESS, params)
			commit("set", ["modalShow", true], { root: true })
		} catch (error) {
			const message = checkErrorFieldResponse(prefix, Object.keys(params), error.message)
			commit(FORGOT_PASSWORD_FAILED, message)
		}
	},

	[RESET_PASSWORD]: async ({ commit }, params = {}) => {
		try {
			const res = await API.resetPwd(params)
			commit(RESET_PASSWORD_SUCCESS, res)
			commit("set", ["modalShow", true], { root: true })
			return res
		} catch (error) {
			const message = checkErrorFieldResponse(prefix, Object.keys(params), error.message)
			commit(RESET_PASSWORD_FAILED, message)
		}
	},

	[CHANGE_PASSWORD]: async ({ commit }, params = {}) => {
		try {
			const res = await API.changePwd(params)
			commit(CHANGE_PASSWORD_SUCCESS, res)
			commit(LOGOUT_SUCCESS)
			return true
		} catch (error) {
			const message = checkErrorFieldResponse(prefix, Object.keys(params), error.message)
			commit(CHANGE_PASSWORD_FAILED, message)
			return false
		}
	},

	[REFRESH_TOKEN]: async ({ commit, state }) => {
		// const config = {
		// 	headers: {
		// 		Authorization: `Bearer ${state.refreshToken}`,
		// 	},
		// }
		const params = {
			token: state.refreshToken,
		}
		const res = await API.refreshToken(params)
		commit(REFRESH_TOKEN_SUCCESS, res)
		return res
	},

	[GET_USER_LOGIN_PROFILE]: async ({ commit }, param = {}) => {
		try {
			const res = await API.getUserProfile(param)
			commit(GET_USER_LOGIN_PROFILE_SUCCESS, res)
			return res
		} catch (error) {
			router.push({ path: "/" })
		}
	},

	[UPDATE_USER_LOGIN_PROFILE]: async ({ commit }, params = {}) => {
		try {
			const res = await API.updateUserProfile(params)
			commit(UPDATE_USER_LOGIN_PROFILE_SUCCESS, res)
			commit("set", ["modalShow", false], { root: true })
		} catch (error) {
			const message = checkErrorFieldResponse(prefix, Object.keys(params), error.message)
			commit(UPDATE_USER_LOGIN_PROFILE_FAILED, message)
		}
	},
	[SEND_FIREBASE_TOKEN]: async () => {
		try {
			const sw = await navigator.serviceWorker.getRegistration("/sw.js")
			if (!sw) return
			const token = await firebase.messaging.getToken({ serviceWorkerRegistration: sw })
			const params = {
				token,
				platform: "BROWSER",
			}
			await API.sendTokenFirebase(params)
		} catch (error) {}
	},
	[DELETE_FIREBASE_TOKEN]: async () => {
		try {
			const sw = await navigator.serviceWorker.getRegistration("/sw.js")
			if (!sw) return
			const token = await firebase.messaging.getToken({ serviceWorkerRegistration: sw })
			const params = {
				token,
				platform: "BROWSER",
			}
			await API.deleteTokenFirebase(params)
			await firebase.messaging.deleteToken()
		} catch (error) {}
	},
}
