import { callAPI } from "@/shared/services"

export default {
	getTempEmails: params => {
		return callAPI("GET", "template", { params: params, notLoading: params.notLoading }).then(
			res => res.data,
		)
	},
	getTemplateDetail: params => {
		return callAPI("GET", "template/detail", { params }).then(res => res.data)
	},
	getLastTemplate: () => {
		return callAPI("GET", "template/last", { notLoading: true }).then(res => res.data)
	},
	createTemplate: params => {
		return callAPI("POST", "template", params).then(res => res.data)
	},
	updateTemplate: params => {
		return callAPI("PUT", "template", params).then(res => res.data)
	},
	deleteTemplate: params => {
		return callAPI("DELETE", `template/${params.id}`).then(res => res.data)
	},
}
