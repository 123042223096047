import { library, dom } from "@fortawesome/fontawesome-svg-core"
import * as icons from "@/assets/icons/fontawesome-icons.js"
import * as iconsRegular from "@/assets/icons/fontawesome-icons-regular.js"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import Vue from "vue"

library.add(icons.default)
library.add(iconsRegular.default)
dom.watch()
Vue.component("FontAwesomeIcon", FontAwesomeIcon)
