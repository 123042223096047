import Vue from "vue"
import { isArray, map } from "lodash-es"
import { allowedTypes, REGEX } from "./constants"

import {
	ValidationProvider,
	ValidationObserver,
	extend,
	localize,
	configure,
	setInteractionMode,
} from "vee-validate"
import en from "vee-validate/dist/locale/en.json"
import vi from "vee-validate/dist/locale/vi.json"
import * as rules from "vee-validate/dist/rules"
import i18n from "@/shared/plugins/i18n"
import { email } from "vee-validate/dist/rules"
import { isURL } from "../utils/mixins"

// Add a rule.
extend("confirmedBy", {
	params: ["target"],
	validate(value, { target }) {
		return value === target
	},
})
extend("password", {
	validate(value) {
		const valid = REGEX.IS_PASSWORD
		return valid.test(value)
	},
})

extend("phone", {
	validate(value) {
		const valid = /^[0-9]*$/g
		return valid.test(value)
	},
})

extend("isUrl", {
	validate(value) {
		return isURL(value)
	},
})

extend("month", {
	validate(value) {
		const valid = /^(0|0[0-9]|1[0-2])$/g
		return valid.test(value)
	},
})

extend("phoneNumber", {
	validate(value) {
		return REGEX.IS_NUMBER.test(value)
	},
})

extend("isNumber", {
	validate(value) {
		return REGEX.IS_NUMBER.test(value)
	},
})
extend("isCurrency", {
	validate(value) {
		return REGEX.IS_CURRENCY.test(value)
	},
})

extend("isEmail", {
	// validate(value) {
	// 	return REGEX.IS_EMAIL.test(value)
	// },
	...email,
})

extend("minmax_value", {
	validate(value, { min, max }) {
		return +value >= min && +value <= max
	},
	params: ["min", "max"],
})

extend("max_value", {
	validate(value, { max }) {
		return +value <= max
	},
	params: ["max"],
})

extend("valueEqualZero", {
	validate(value) {
		if (!value.toString().includes(",")) {
			return parseFloat(value) === 0 ? false : true
		}
		return parseFloat(value.split(",").join("")) === 0 ? false : true
	},
})

extend("fileUpload", {
	validate: files =>
		new Promise(resolve => {
			const queue = map(files, file => {
				return new Promise((valid, invalid) => {
					const img = new Image()
					img.onload = () => {
						const { naturalWidth: width, naturalHeight: height } = img
						if (width === 370 && height === 280) {
							return valid()
						}
						return invalid()
					}
					img.onerror = invalid
					img.src = URL.createObjectURL(file)
				})
			})

			Promise.all(queue)
				.then(() => resolve({ valid: true }))
				.catch(() => resolve({ valid: false }))
		}),
})
extend("fileType", {
	validate: files =>
		new Promise(resolve => {
			const queue = map(files, file => {
				return new Promise((valid, invalid) => {
					const fileType = file.type
					if (!allowedTypes.includes(fileType)) {
						return invalid()
					}
					return valid()
				})
			})

			Promise.all(queue)
				.then(() => resolve({ valid: true }))
				.catch(() => resolve({ valid: false }))
		}),
})

extend("isValidFormRequest", {
	validate(value) {
		return {
			required: true,
			valid: ["", null, undefined].indexOf(isArray(value) ? value[0] : value) === -1,
		}
	},
	computesRequired: true,
})

extend("requiredExport", {
	validate(value) {
		return {
			required: true,
			valid: ["", null, undefined].indexOf(isArray(value) ? value[0] : value) === -1,
		}
	},
	computesRequired: true,
})

// Import all rules
Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule])
})

// Register it globally
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

// config interaction mode
setInteractionMode("aggressive")

// Install English and Vietnamese locales.
localize({
	en,
	vi,
})

// Activate locale
const locale = window.localStorage.getItem("locale") || process.env.VUE_APP_I18N_LOCALE || "vi"

function setLocale() {
	switch (locale) {
		case "vi":
			return vi

		default:
			return en
	}
}

localize(locale, setLocale())

// Config options
const config = {
	classes: {
		valid: "is-valid",
		invalid: "is-invalid",
	},
	defaultMessage: (field, values) => {
		// get all fields were overridden
		const fields = { ...i18n.t(`fields`) }

		// check field name exsists in fields
		if (Object.prototype.hasOwnProperty.call(fields, field)) {
			// override the field name.
			values._field_ = i18n.t(`fields.${field}`)

			// overide the custom field name
			if (values.target) {
				values.target = i18n.t(`fields.${values.target}`)
			}
		}

		return i18n.t(`validation.${values._rule_}`, values)
	},
}

// Sets the options.
configure(config)
