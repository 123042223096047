export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED"

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED"

export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"
export const SEND_OTP_FAILED = "SEND_OTP_FAILED"

export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED"

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS"
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR"

export const GET_USER_LOGIN_PROFILE_SUCCESS = "GET_USER_LOGIN_PROFILE_SUCCESS"

export const UPDATE_USER_LOGIN_PROFILE_SUCCESS = "UPDATE_USER_LOGIN_PROFILE_SUCCESS"
export const UPDATE_USER_LOGIN_PROFILE_FAILED = "UPDATE_USER_LOGIN_PROFILE_FAILED"

export const CHECK_TOKEN_VALID_SUCCESS = "CHECK_TOKEN_VALID_SUCCESS"
export const CHECK_TOKEN_VALID_FAILED = "CHECK_TOKEN_VALID_FAILED"

export const CHANGE_STATUS_LOGOUT_MODAL = "CHANGE_STATUS_LOGOUT_MODAL"
export const CHANGE_STATUS_CHANGE_PASSWORD_MODAL = "CHANGE_STATUS_CHANGE_PASSWORD_MODAL"

export const CAN_LOGIN = "CAN_LOGIN"
