export const GET_ORDERS = "GET_ORDERS"
export const GET_RELATED_ORDERS = "GET_RELATED_ORDERS"
export const CREATE_ORDER = "CREATE_ORDER"
export const UPDATE_EDITOR_NOTE = "UPDATE_EDITOR_NOTE"
export const GET_ORDER = "GET_ORDER"
export const DELETE_ORDER = "DELETE_ORDER"
export const UPDATE_ORDER = "UPDATE_ORDER"
export const EXPORT_ORDER = "EXPORT_ORDER"
export const HIGH_LIGHT_ORDER = "HIGH_LIGHT_ORDER"

export const LOCK_USER = "LOCK_USER"
export const UNLOCK_USER = "UNLOCK_USER"

export const TRANSLATE_TEXT = "TRANSLATE_TEXT"

export const CHECK_VALID_LINK = "CHECK_VALID_LINK"
export const CREATE_ORDER_WEB = "CREATE_ORDER_WEB"
export const UPDATE_OEDER_WEB = "UPDATE_OEDER_WEB"
export const CANCEL_ORDER_WEB = "CANCEL_ORDER_WEB"
