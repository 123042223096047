import Vue from "vue"
import Router from "vue-router"
import i18n from "@/shared/plugins/i18n"
import "@/shared/lang"
import SecureLS from "secure-ls"
import store from "@/store"
import { LOGOUT_SUCCESS } from "@/modules/authentication/store/mutation-types"

const ls = new SecureLS({ isCompression: false })

// Views - All Modules's routes
import modulesRoutes from "./others"
import { SEND_CODE_LOGIN } from "../modules/authentication/store/action-types"

// Containers
const TheContainer = () => import("@/shared/containers/TheContainer")

// Views
// Views - Pages
const Page404 = () => import("@/shared/pages/Page404")
const Page500 = () => import("@/shared/pages/Page500")
const ReceivingForm = () => import("@/shared/pages/ReceivingForm")
const Notifications = () => import("@/shared/pages/Notifications")

Vue.use(Router)

const appRoutes = [
	{
		path: "/",
		redirect: "/forms",
		name: "Home",
		component: TheContainer,
		children: [
			{
				path: "notifications",
				name: "Notifications",
				component: Notifications,
				meta: {
					title: i18n.t("AppTitle.Notice"),
				},
				children: [
					{
						path: ":id",
						beforeEnter: async (to, from, next) => {
							await store.dispatch("redirectNoti", { id: to.params.id })
						},
					},
				],
			},
		],
	},
	{
		path: "/receiving-form",
		name: "ReceivingForm",
		component: ReceivingForm,
		meta: {
			title: i18n.t("AppTitle.FormRequest"),
		},
	},
	{
		path: "/pages",
		redirect: "/pages/404",
		name: "Pages",
		component: {
			render(c) {
				return c("router-view")
			},
		},
		children: [
			{
				path: "404",
				name: "Page404",
				component: Page404,
				meta: {
					title: i18n.t("AppTitle.Page404"),
				},
			},
			{
				path: "500",
				name: "Page500",
				component: Page500,
				meta: {
					title: i18n.t("AppTitle.Page500"),
				},
			},
		],
	},
	{
		path: "*",
		redirect: "/",
	},
]
let isSendCode = false

function configRoutes() {
	if (modulesRoutes && Object.values(modulesRoutes).length) {
		let allRoutes = new Set()
		Object.values(modulesRoutes).forEach(routes => {
			routes.forEach(route => {
				allRoutes.add(route)
			})
		})

		return [...Array.from(allRoutes), ...appRoutes]
	}

	return [...appRoutes]
}

const router = new Router({
	mode: "history",
	linkActiveClass: "active",
	scrollBehavior: () => ({ y: 0 }),
	routes: configRoutes(),
})

router.beforeEach(async (to, from, next) => {
	document.title = "Admin - " + to.meta.title
	const vuex = ls.get("vuex") ? JSON.parse(ls.get("vuex")) : null
	const authUser = vuex?.authentication?.currentUser || null
	const canLogin = vuex?.authentication?.canLogin || false
	const homePath = "/forms/list"
	let sendCode = null

	const urlParams = new URLSearchParams(window.location.search)
	const code = urlParams.get("code") ? decodeURIComponent(urlParams.get("code")) : null

	if (code && !isSendCode) {
		isSendCode = true
		sendCode = new Promise(resolve => {
			store.dispatch(`authentication/${SEND_CODE_LOGIN}`, { code })
			resolve(true)
		})
		await sendCode
		next(to.path)
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!authUser && !canLogin) {
			next({
				path: "/login",
				query: !to.fullPath.includes(homePath) && { redirect: to.fullPath },
			})
		}
		//  else next()
		else if (to.meta.role && to.meta.role.includes(authUser.role)) {
			next()
		} else {
			next("/")
		}
	} else {
		const ignorePath = ["/login", "/forgot-password", "/reset-password", "/forgot-password-otp"]
		if (authUser && ignorePath.includes(to.path)) {
			next("/")
		} else {
			if (to.path === "/receiving-form") {
				store.commit(`authentication/${LOGOUT_SUCCESS}`)
			}
			next()
		}
	}
})

export default router
