import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	TransactionTitle: {
		TransactionManagement: "Transactions Management",
		TransactionList: "List Transaction",
	},
	TransactionFieldTable: {
		systemName: "System name",
		email: "Email",
		orderId: "Order ID",
		amount: "Amount",
		time: "Time",
	},
	TransactionFilters: {
		search: "System name, email, order ID",
		created: "Date",
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
		},
	},
})
