import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "../../shared/plugins/constants"

const EmailsModule = () => import("./EmailsModule.vue")
const EmailManagement = () => import("./pages/EmailManagement.vue")
const CreateTempEmail = () => import("./pages/CreateTempEmail.vue")
const UpdateTempEmail = () => import("./pages/UpdateTempEmail.vue")

const emailRoutes = [
	{
		path: "/emails",
		name: "EmailsModule",
		component: EmailsModule,
		meta: {
			label: i18n.t("EmailsTitle.EmailManagement"),
		},
		redirect: "/emails/list",
		children: [
			{
				path: "list",
				name: "EmailManagement",
				component: EmailManagement,
				meta: {
					title: i18n.t("EmailsTitle.EmailList"),
					label: i18n.t("EmailsTitle.EmailList"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: "create",
				name: "CreateTempEmail",
				component: CreateTempEmail,
				meta: {
					title: i18n.t("EmailsTitle.CreateEmail"),
					label: i18n.t("EmailsTitle.CreateEmail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "UpdateTempEmail",
				component: UpdateTempEmail,
				props: route => ({
					default: true,
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("EmailsTitle.UpdateEmail"),
					label: i18n.t("EmailsTitle.UpdateEmail"),
					requiresAuth: true,
					role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/emails/list",
			},
		],
	},
]

export default emailRoutes
