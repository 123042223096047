export const GET_USERS_APP_SUCCESS = "GET_USERS_APP_SUCCESS"
export const GET_USERS_APP_FAILED = "GET_USERS_APP_FAILED"

export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS"
export const CHANGE_STATUS_FAILED = "CHANGE_STATUS_FAILED"

export const GET_USER_APP_SUCCESS = "GET_USER_APP_SUCCESS"
export const GET_USER_APP_FAILED = "GET_USER_APP_FAILED"

export const UPDATE_USER_APP_SUCCESS = "UPDATE_USER_APP_SUCCESS"
export const UPDATE_USER_APP_FAILED = "UPDATE_USER_APP_FAILED"

export const RESET_STATE = "RESET_STATE"
